import BookmarkRoundedIcon from "@mui/icons-material/BookmarkRounded";
import CalendarMonthRoundedIcon from "@mui/icons-material/CalendarMonthRounded";
import ChatBubbleRoundedIcon from "@mui/icons-material/ChatBubbleRounded";
import FitnessCenterRoundedIcon from "@mui/icons-material/FitnessCenterRounded";
import PriorityHighRoundedIcon from "@mui/icons-material/PriorityHighRounded";
import VideocamRoundedIcon from "@mui/icons-material/VideocamRounded";
import {
  Box,
  Button,
  FormControlLabel,
  FormGroup,
  Popover,
  Switch,
  TextField,
  ToggleButton,
  ToggleButtonGroup,
  Typography,
} from "@mui/material";
import { DatePicker } from "@mui/x-date-pickers";
import { type SaveForLaterType } from "@trainwell/features/legacy";
import { startOfDay } from "date-fns";
import { useState } from "react";
import { useAppDispatch } from "src/hooks/stateHooks";
import {
  createCustomActionItem,
  dismissActionItem,
} from "src/slices/actionItemSlice";

type Props = {
  userId: string;
  actionItemId?: string;
  defaultMessage?: string;
  messageId?: string;
  anchorEl: HTMLButtonElement | null;
  onClose: () => void;
};

export default function SaveForLaterPopover({
  userId,
  actionItemId,
  defaultMessage,
  messageId,
  anchorEl,
  onClose,
}: Props) {
  const dispatch = useAppDispatch();
  const [message, setMessage] = useState(defaultMessage ?? "");
  const [date, setDate] = useState<Date | null>(new Date());
  const [type, setType] = useState<SaveForLaterType | null>(null);
  const [withDate, setWithDate] = useState(false);

  const open = Boolean(anchorEl);

  function handleClose() {
    setType(null);
    setMessage("");
    setDate(new Date());
    setWithDate(false);

    onClose();
  }

  function handleSave() {
    if (!userId) {
      return;
    }

    if (!actionItemId) {
      dispatch(
        createCustomActionItem({
          userId: userId,
          message: message,
          sendDate: date?.toISOString(),
          customType: type ?? "other",
          messageId: messageId,
        }),
      );
    }

    handleClose();
  }

  function handleClear() {
    if (actionItemId) {
      dispatch(
        dismissActionItem({
          actionItemId: actionItemId,
          usedClearButton: true,
        }),
      );
    }

    handleClose();
  }

  return (
    <Popover
      anchorOrigin={{
        vertical: "top",
        horizontal: "left",
      }}
      transformOrigin={{
        vertical: "bottom",
        horizontal: "left",
      }}
      open={open}
      anchorEl={anchorEl}
      onClose={handleClose}
      slotProps={{ paper: { sx: { p: 1.5, width: 300 } } }}
    >
      <Typography variant="h2" sx={{ mb: 2 }}>
        Save for later
      </Typography>
      <Box sx={{ mb: 2 }}>
        <ToggleButtonGroup
          value={type ?? "other"}
          exclusive
          onChange={(event, newValue) => {
            setType(newValue);
          }}
        >
          <ToggleButton value="priority">
            <PriorityHighRoundedIcon />
          </ToggleButton>
          <ToggleButton value="workout">
            <FitnessCenterRoundedIcon />
          </ToggleButton>
          <ToggleButton value="video">
            <VideocamRoundedIcon />
          </ToggleButton>
          <ToggleButton value="message">
            <ChatBubbleRoundedIcon />
          </ToggleButton>
          <ToggleButton value="calendar">
            <CalendarMonthRoundedIcon />
          </ToggleButton>
          <ToggleButton value="other">
            <BookmarkRoundedIcon />
          </ToggleButton>
        </ToggleButtonGroup>
      </Box>
      <TextField
        sx={{ mb: 2 }}
        fullWidth
        autoFocus
        label="Note (optional)"
        multiline
        value={message ?? ""}
        onChange={(event) => {
          setMessage(event.target.value);
        }}
        // onKeyDown={(event) => {
        //   if (event.key === "Enter") {
        //     event.preventDefault();
        //     event.stopPropagation();

        //     handleSave();
        //   }
        // }}
      />
      <FormGroup sx={{ mb: 1 }}>
        <FormControlLabel
          control={
            <Switch
              checked={withDate}
              onChange={(event) => {
                setWithDate(event.target.checked);
              }}
            />
          }
          label="Scheduled"
        />
      </FormGroup>
      {withDate && (
        <DatePicker
          value={date}
          onChange={(newValue) => {
            setDate(startOfDay(newValue ?? new Date()));
          }}
          slotProps={{
            field: {
              sx: {
                width: "100%",
                mb: 2,
              },
            },
          }}
        />
      )}
      <Box
        sx={{
          display: "flex",
          justifyContent: "flex-end",
          alignItems: "center",
        }}
      >
        <Button variant="outlined" onClick={handleClear}>
          Clear
        </Button>
        <Button onClick={handleSave} sx={{ ml: 2 }}>
          Save
        </Button>
      </Box>
    </Popover>
  );
}

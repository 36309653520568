import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";
import type { ProgressMetricLog } from "@trainwell/features/legacy";
import { getAuth } from "firebase/auth";
import { TRAINWELL_API_DOMAIN, TRAINWELL_API_KEY } from "src/config/config";

export const progressMetricLogsApi = createApi({
  reducerPath: "progressMetricLogsApi",
  tagTypes: ["ProgressMetricLogs"],
  baseQuery: fetchBaseQuery({
    baseUrl: TRAINWELL_API_DOMAIN,
    prepareHeaders: async (headers) => {
      const auth = getAuth();

      const tokenString = await auth.currentUser?.getIdToken();

      if (tokenString) {
        headers.set("authorization", `Bearer ${tokenString}`);
      }

      headers.set("api-key", TRAINWELL_API_KEY);

      return headers;
    },
  }),
  endpoints: (build) => ({
    getProgressMetricLogs: build.query<
      ProgressMetricLog[],
      { userId: string; progressMetricId: string }
    >({
      query: (options) =>
        `progressMetricLogs?user_id=${encodeURIComponent(options.userId)}&progress_metric_id=${options.progressMetricId}`,
      transformResponse: (response: {
        progress_metric_logs: ProgressMetricLog[];
      }) =>
        response.progress_metric_logs.sort((a, b) => {
          return b.date < a.date ? -1 : b.date > a.date ? 1 : 0;
        }),
      providesTags: (result) =>
        // is result available?
        result
          ? // successful query
            [
              ...result.map(
                ({ id }) => ({ type: "ProgressMetricLogs", id }) as const,
              ),
              { type: "ProgressMetricLogs", id: "LIST" },
            ]
          : // an error occurred, but we still want to refetch this query when `{ type: 'Posts', id: 'LIST' }` is invalidated
            [{ type: "ProgressMetricLogs", id: "LIST" }],
    }),
    deleteProgressMetricLog: build.mutation<
      undefined,
      { progressMetricLogId: string }
    >({
      query: (options) => ({
        url: `progressMetricLogs/${options.progressMetricLogId}`,
        method: "DELETE",
      }),
      invalidatesTags: (result, error, d) => [
        { type: "ProgressMetricLogs", id: d.progressMetricLogId },
      ],
    }),
  }),
});

export const {
  useGetProgressMetricLogsQuery,
  useDeleteProgressMetricLogMutation,
} = progressMetricLogsApi;

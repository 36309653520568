import EditRoundedIcon from "@mui/icons-material/EditRounded";
import SaveRoundedIcon from "@mui/icons-material/SaveRounded";
import {
  Card,
  IconButton,
  Skeleton,
  Stack,
  TextField,
  Typography,
} from "@mui/material";
import {
  useCreateNutritionGoal,
  useCurrentNutritionGoal,
} from "@trainwell/features/nutrition";
import { useSnackbar } from "notistack";
import { useCallback, useEffect, useState } from "react";
import { useAppSelector } from "src/hooks/stateHooks";

function CardBody({
  value,
  onValueChange,
  isEditing,
  state,
}: {
  value: string;
  isEditing: boolean;
  state: ReturnType<typeof useCurrentNutritionGoal>;
  onValueChange: (
    e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>,
  ) => void;
}) {
  if (state.isPending) {
    return (
      <Skeleton
        variant="rectangular"
        sx={{ minHeight: "32px", borderRadius: "4px" }}
      />
    );
  }
  if (state.error) {
    return (
      <Typography variant="h6" color="error">
        Error while fetching goal
      </Typography>
    );
  }
  if (isEditing) {
    return (
      <TextField
        variant="outlined"
        size="small"
        placeholder="Eat less chips!"
        fullWidth
        multiline={true}
        value={value}
        onChange={onValueChange}
      />
    );
  }
  return <Typography variant="h6">{state.data.goal.description}</Typography>;
}

export default function NutritionGoalCard() {
  const clientUserId = useAppSelector((state) => state.client.client?.user_id);

  const [isEditing, setIsEditing] = useState(false);
  const [goalDescription, setGoalDescription] = useState("");

  const currentGoal = useCurrentNutritionGoal({
    userId: clientUserId ?? "",
    queryConfig: {
      enabled: !!clientUserId,
    },
  });

  const createGoal = useCreateNutritionGoal();

  const { enqueueSnackbar } = useSnackbar();

  useEffect(() => {
    if (!currentGoal.data?.goal.description) return;
    setGoalDescription(currentGoal.data?.goal.description);
  }, [currentGoal.data]);

  const handleCreateNutritionGoal = useCallback(async () => {
    try {
      if (!clientUserId)
        throw new Error("Client user_id is required to submit.");
      await createGoal.mutateAsync({
        data: {
          create: { user_id: clientUserId, description: goalDescription },
        },
      });
    } catch (err: any) {
      enqueueSnackbar({
        variant: "error",
        message: err.message ?? "Something went wrong",
      });
    } finally {
      setIsEditing(false);
    }
  }, [createGoal, goalDescription]);

  return (
    <Card variant="outlined" sx={{ position: "relative", p: 1, m: 1 }}>
      <IconButton
        loading={isEditing ? createGoal.isPending : false}
        disabled={isEditing ? goalDescription === "" : false}
        size="small"
        sx={{ position: "absolute", top: "4px", right: "4px" }}
        onClick={
          isEditing
            ? handleCreateNutritionGoal
            : () => setIsEditing((prev) => !prev)
        }
      >
        {isEditing ? (
          <SaveRoundedIcon fontSize="small" />
        ) : (
          <EditRoundedIcon fontSize="small" />
        )}
      </IconButton>
      <Stack spacing={isEditing ? 2 : 1}>
        <>
          <Typography variant="overline">Nutrition Goal</Typography>
          <CardBody
            value={goalDescription}
            isEditing={isEditing}
            state={currentGoal}
            onValueChange={(e) => setGoalDescription(e.target.value)}
          />
        </>
      </Stack>
    </Card>
  );
}

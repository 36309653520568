import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";
import type { SwapSuggestion } from "@trainwell/features/legacy";
import { getAuth } from "firebase/auth";
import { TRAINWELL_API_DOMAIN, TRAINWELL_API_KEY } from "src/config/config";

export const exercisesApi = createApi({
  reducerPath: "exercisesApi",
  baseQuery: fetchBaseQuery({
    baseUrl: TRAINWELL_API_DOMAIN,
    prepareHeaders: async (headers) => {
      const auth = getAuth();

      const tokenString = await auth.currentUser?.getIdToken();

      if (tokenString) {
        headers.set("authorization", `Bearer ${tokenString}`);
      }

      headers.set("api-key", TRAINWELL_API_KEY);

      return headers;
    },
  }),
  endpoints: (build) => ({
    getSwapSuggestions: build.query<
      Record<string, SwapSuggestion>,
      { trainerId: string }
    >({
      query: (options) =>
        `exercises/swapSuggestions?trainer_id=${options.trainerId}`,
      transformResponse: (response: { swap_suggestions: SwapSuggestion[] }) =>
        response.swap_suggestions.reduce(
          (acc, swapSuggestion) => ({
            ...acc,
            [swapSuggestion.exercise_id]: swapSuggestion,
          }),
          {},
        ),
    }),
  }),
});

export const { useGetSwapSuggestionsQuery } = exercisesApi;

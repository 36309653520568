import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  Divider,
  Grid2,
  Stack,
  TextField,
  Typography,
} from "@mui/material";
import {
  useSubmitNutritionReview,
  type ClientMealLog,
  type NutritionReview,
} from "@trainwell/features/nutrition";
import { useSnackbar } from "notistack";
import {
  useCallback,
  useEffect,
  useMemo,
  useState,
  type FormEvent,
} from "react";
import { DialogTitleWithClose } from "src/components/misc/DialogTitleWithClose";
import { useAppSelector } from "src/hooks/stateHooks";
import MealLogCard from "./MealLogCard";
import MealLogModal from "./MealLogModal";
import { TextItem } from "./shared/TextItem";
import { TitledSection } from "./shared/TitledSection";

type Props = {
  review: NutritionReview;
  open: boolean;
  onClose: () => void;
  onReviewUpdated: (review: NutritionReview) => void;
  onLogUpdated: (log: ClientMealLog) => void;
};

export default function NutritionReviewModal({
  review,
  open,
  onClose,
  onReviewUpdated,
  onLogUpdated,
}: Props) {
  const currentClient = useAppSelector((state) => state.client.client);
  const [didMeetGoal, setDidMeetGoal] = useState<string | null>(null);
  const [potentialNextGoals, setPotentialNextGoals] = useState<string | null>(
    null,
  );
  const [selectedLogIndex, setSelectedLogIndex] = useState<number | null>(null);

  const submitNutritionReview = useSubmitNutritionReview();

  const { enqueueSnackbar } = useSnackbar();

  const canEdit = useMemo(() => !review.date_submitted, [review]);
  const canSubmit = useMemo(
    () =>
      canEdit &&
      !submitNutritionReview.isPending &&
      ((didMeetGoal !== null && didMeetGoal !== "") ||
        (potentialNextGoals !== null && potentialNextGoals !== "")),
    [review, submitNutritionReview.isPending, didMeetGoal, potentialNextGoals],
  );

  useEffect(() => {
    setDidMeetGoal(review.did_meet_goal ?? null);
    setPotentialNextGoals(review.potential_next_goals ?? null);
  }, [review, setDidMeetGoal, setPotentialNextGoals]);

  const handleSubmit = useCallback(
    async (e: FormEvent) => {
      e.preventDefault();
      try {
        if (!didMeetGoal && !potentialNextGoals)
          throw new Error("Review cannot be empty.");

        const { review: updatedReview } =
          await submitNutritionReview.mutateAsync({
            reviewId: review._id,
            data: {
              updates: {
                did_meet_goal: didMeetGoal ?? undefined,
                potential_next_goals: potentialNextGoals ?? undefined,
              },
            },
          });
        onReviewUpdated(updatedReview);
        enqueueSnackbar({
          variant: "success",
          message: "Submitted successfully.",
          autoHideDuration: 1_000,
        });
      } catch (err: any) {
        enqueueSnackbar({
          variant: "error",
          message: err.message ?? "Something went wrong",
          autoHideDuration: 1_000,
        });
      }
    },
    [didMeetGoal, potentialNextGoals, onReviewUpdated],
  );

  const handleLogClicked = useCallback(
    (index: number) => setSelectedLogIndex(index),
    [selectedLogIndex],
  );

  if (!review) return null;

  return (
    <>
      <Dialog open={open} onClose={onClose} maxWidth={"md"} fullWidth={true}>
        <form onSubmit={handleSubmit} style={{ display: "contents" }}>
          <DialogTitleWithClose onClose={onClose}>
            Nutrition Review
          </DialogTitleWithClose>
          <Divider />
          <DialogContent>
            <Stack spacing="32px">
              <TitledSection
                title={`${currentClient?.first_name ?? "Client"}'s goal this week`}
              >
                <TextItem text={review.goal.description} />
              </TitledSection>

              <TitledSection
                title={`${currentClient?.first_name ?? "Client"}'s logs this week`}
              >
                <Grid2
                  container
                  flexWrap="wrap"
                  spacing={1}
                  columns={5}
                  sx={{
                    borderRadius: 1.5,
                    padding: 1,
                    bgcolor: (theme) => theme.palette.backgroundDark.main,
                  }}
                >
                  {review.meal_logs.map((log, i) => (
                    <Grid2 key={`log.${log._id}`} size={1}>
                      <MealLogCard
                        log={log}
                        onClick={() => handleLogClicked(i)}
                      />
                    </Grid2>
                  ))}
                </Grid2>
              </TitledSection>

              <TitledSection
                title={`Did ${currentClient?.first_name ?? "Client"} meet the goal?`}
              >
                {canEdit ? (
                  <TextField
                    disabled={!canEdit}
                    variant="outlined"
                    placeholder="You met your goal this week, great work!"
                    fullWidth
                    multiline={true}
                    value={didMeetGoal ?? ""}
                    onChange={(e) => setDidMeetGoal(e.target.value)}
                    sx={{ color: "#000" }}
                  />
                ) : (
                  <TextItem text={didMeetGoal ?? ""} />
                )}
              </TitledSection>

              <TitledSection title="Potential next goals">
                {canEdit ? (
                  <TextField
                    disabled={!canEdit}
                    variant="outlined"
                    placeholder="Moving forward, I'd recommend either cutting back on fast food or increasing the proportion of vegetables in your meals."
                    fullWidth
                    multiline={true}
                    value={potentialNextGoals ?? ""}
                    onChange={(e) => setPotentialNextGoals(e.target.value)}
                  />
                ) : (
                  <TextItem text={potentialNextGoals ?? ""} />
                )}
              </TitledSection>
            </Stack>
          </DialogContent>
          <Divider />
          <DialogActions>
            {review.date_submitted ? (
              <Typography sx={{ width: "100%", textAlign: "center" }}>
                Submitted on {review.date_submitted?.toLocaleString()}
              </Typography>
            ) : (
              <Button
                type="submit"
                loading={submitNutritionReview.isPending}
                disabled={!canSubmit}
              >
                Submit
              </Button>
            )}
          </DialogActions>
        </form>

        <MealLogModal
          log={
            selectedLogIndex !== null
              ? review.meal_logs[selectedLogIndex]
              : null
          }
          onClose={() => setSelectedLogIndex(null)}
          onLogUpdated={onLogUpdated}
        />
      </Dialog>
    </>
  );
}

import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";
import type { GoogleForm } from "@trainwell/features/legacy";
import { getAuth } from "firebase/auth";
import { TRAINWELL_API_DOMAIN, TRAINWELL_API_KEY } from "src/config/config";

export const googleFormsApi = createApi({
  reducerPath: "googleFormsApi",
  baseQuery: fetchBaseQuery({
    baseUrl: TRAINWELL_API_DOMAIN,
    prepareHeaders: async (headers) => {
      const auth = getAuth();

      const tokenString = await auth.currentUser?.getIdToken();

      if (tokenString) {
        headers.set("authorization", `Bearer ${tokenString}`);
      }

      headers.set("api-key", TRAINWELL_API_KEY);

      return headers;
    },
  }),
  endpoints: (build) => ({
    getGoogleForms: build.query<GoogleForm[], { userId: string }>({
      query: (options) =>
        `googleForms?user_id=${encodeURIComponent(options.userId)}`,
      transformResponse: (response: { google_forms: GoogleForm[] }) =>
        response.google_forms.sort((a, b) => {
          return b.date_sent < a.date_sent
            ? -1
            : b.date_sent > a.date_sent
              ? 1
              : 0;
        }),
    }),
  }),
});

export const { useGetGoogleFormsQuery } = googleFormsApi;

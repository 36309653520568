import AssignmentRoundedIcon from "@mui/icons-material/AssignmentRounded";
import ChatBubbleRoundedIcon from "@mui/icons-material/ChatBubbleRounded";
import DataUsageRoundedIcon from "@mui/icons-material/DataUsageRounded";
import SkipNextIcon from "@mui/icons-material/SkipNext";
import SpeedRoundedIcon from "@mui/icons-material/SpeedRounded";
import TaskAltRoundedIcon from "@mui/icons-material/TaskAltRounded";
import ThumbUpAltRoundedIcon from "@mui/icons-material/ThumbUpAltRounded";
import TimelineRoundedIcon from "@mui/icons-material/TimelineRounded";
import TimerRoundedIcon from "@mui/icons-material/TimerRounded";
import VisibilityRoundedIcon from "@mui/icons-material/VisibilityRounded";
import WarningRoundedIcon from "@mui/icons-material/WarningRounded";
import {
  Box,
  Button,
  Chip,
  Grid,
  IconButton,
  Popover,
  SvgIcon,
  Tooltip,
  Typography,
  alpha,
  styled,
  useTheme,
} from "@mui/material";
import type { LogSection, LogSet } from "@trainwell/features/legacy";
import { getConvertedWeight } from "@trainwell/workout-lib";
import { useSnackbar } from "notistack";
import { useState } from "react";
import { useAppDispatch, useAppSelector } from "src/hooks/stateHooks";
import { getExerciseById } from "src/lib/exercises";
import { getFormattedTime } from "src/lib/misc";
import { workoutLib } from "src/lib/trainwellWorkoutLib";
import { useGetLogQuery, useUpdateLogMutation } from "src/slices/api/logApi";
import { selectClientById } from "src/slices/clientsSlice";
import { highlightSet } from "src/slices/workoutSlice";
import { PacingChart } from "../workout-builder/PacingChart";
import { RepVelocityChart } from "../workout-builder/RepVelocityChart";

const ListItem = styled("li")(({ theme }) => ({
  margin: theme.spacing(0.5),
}));

type Props = {
  logId: string;
  firstExerciseId?: string;
  firstSetId?: string;
  setLog: LogSet;
  setIndex: number;
  exerciseMasterID: string;
  enablePreview: boolean;
};

export default function LogSetCell({
  logId,
  firstExerciseId,
  firstSetId,
  setLog,
  setIndex,
  exerciseMasterID,
  enablePreview,
}: Props) {
  const exerciseExists = useAppSelector(
    (state) =>
      firstExerciseId &&
      Boolean(
        state.workout.workoutNormalized?.entities.exercises[firstExerciseId],
      ),
  );
  const isHighlighted = useAppSelector(
    (state) => state.workout.highlightedSet?.setId === setLog.set_id,
  );
  const [updateLog, { isLoading: isUpdating }] = useUpdateLogMutation();
  const theme = useTheme();
  const dispatch = useAppDispatch();
  const exerciseSource = getExerciseById(exerciseMasterID);
  const { data: log } = useGetLogQuery(logId);
  const backupClient = useAppSelector((state) =>
    selectClientById(state, log?.user_id ?? ""),
  );
  let client = useAppSelector((state) => state.client.client);

  if (client?.user_id !== log?.user_id) {
    client = backupClient;
  }

  const { enqueueSnackbar } = useSnackbar();
  const [kineticGraphsAnchor, setKineticGraphsAnchor] =
    useState<HTMLButtonElement | null>(null);
  const [romGraphsAnchor, setRomGraphsAnchor] =
    useState<HTMLButtonElement | null>(null);
  const [summaryGraphAnchor, setSummaryGraphAnchor] =
    useState<HTMLButtonElement | null>(null);
  const [feedbackAnchor, setFeedbackAnchor] =
    useState<HTMLButtonElement | null>(null);
  const [paceGraphAnchor, setPaceGraphAnchor] =
    useState<HTMLButtonElement | null>(null);

  const kineticGraphsOpen = Boolean(kineticGraphsAnchor);
  const romGraphsOpen = Boolean(romGraphsAnchor);
  const summaryGraphOpen = Boolean(summaryGraphAnchor);
  const feedbackOpen = Boolean(feedbackAnchor);
  const paceGraphOpen = Boolean(paceGraphAnchor);

  if (!exerciseSource) {
    return <Typography>Loading...</Typography>;
  }

  const values = workoutLib.exercises.getValuesForExercise(exerciseSource);

  const isFalseNegative =
    exerciseSource.is_currently_tracked === true &&
    (!("repcognition_response" in setLog) ||
      setLog.repcognition_response === null) &&
    log?.tech_summary?.watch_used &&
    setLog.performance_index !== null &&
    log.tech_summary.device_platform === "ios";

  const timeSeconds = Math.round(
    (new Date(setLog.set_end_date!).getTime() -
      new Date(setLog.set_start_date!).getTime()) /
      1000,
  );

  let hasROM = false;
  let goodRom = true;
  let romGraphURL: any = undefined;

  let pace: any = undefined;
  let paceIdeal: any = undefined;
  let isPaceBad = false;

  let speedMeans: any = undefined;

  let feedback: any = undefined;
  let isFeedbackBad = false;

  let summaryGraphURL: any = undefined;

  let lengths: any = undefined;

  let dataID: any = undefined;

  if (
    "repcognition_response" in setLog &&
    setLog.repcognition_response !== null
  ) {
    if (
      "rep_analysis" in (setLog as any).repcognition_response &&
      (setLog as any).repcognition_response.rep_analysis !== null
    ) {
      if (
        "reps_estimated" in
          (setLog as any).repcognition_response.rep_analysis &&
        (setLog as any).repcognition_response.rep_analysis.reps_estimated !==
          null
      ) {
        (setLog as any).reps_performed = (
          setLog as any
        ).repcognition_response.rep_analysis.reps_estimated;
      }
      if (
        "good_rom" in (setLog as any).repcognition_response.rep_analysis &&
        (setLog as any).repcognition_response.rep_analysis.good_rom !== null
      ) {
        hasROM = true;
        goodRom = (setLog as any).repcognition_response.rep_analysis.good_rom;
      }
      if (
        "pace" in (setLog as any).repcognition_response.rep_analysis &&
        (setLog as any).repcognition_response.rep_analysis.pace !== null
      ) {
        pace = (setLog as any).repcognition_response.rep_analysis.pace;
      }

      //First try getting the newer lower bound pace - jk this is confusing to trainers
      // if ("pace_lower_bound" in setLog.repcognition_response.rep_analysis && setLog.repcognition_response.rep_analysis.pace_lower_bound !== null) {
      //     paceIdeal = setLog.repcognition_response.rep_analysis.pace_lower_bound / (info.notes_reps_per_side ? 2 : 1)
      // }

      if (
        "pace_ideal" in (setLog as any).repcognition_response.rep_analysis &&
        (setLog as any).repcognition_response.rep_analysis.pace_ideal !== null
      ) {
        paceIdeal = (setLog as any).repcognition_response.rep_analysis
          .pace_ideal;
      }

      if (
        "pace_too_fast" in (setLog as any).repcognition_response.rep_analysis &&
        (setLog as any).repcognition_response.rep_analysis.pace_too_fast !==
          null
      ) {
        isPaceBad = (setLog as any).repcognition_response.rep_analysis
          .pace_too_fast;
      }
      if (
        "speed_means" in (setLog as any).repcognition_response.rep_analysis &&
        (setLog as any).repcognition_response.rep_analysis.speed_means !==
          null &&
        (setLog as any).repcognition_response.rep_analysis.speed_means.length >
          0
      ) {
        speedMeans = (setLog as any).repcognition_response.rep_analysis
          .speed_means;
      }
      if (
        "lengths" in (setLog as any).repcognition_response.rep_analysis &&
        (setLog as any).repcognition_response.rep_analysis.lengths !== null &&
        (setLog as any).repcognition_response.rep_analysis.lengths.length > 0
      ) {
        lengths = (setLog as any).repcognition_response.rep_analysis.lengths;
      }
    }

    if (
      "feedback_description" in (setLog as any).repcognition_response &&
      (setLog as any).repcognition_response.feedback_description !== null
    ) {
      feedback = (setLog as any).repcognition_response.feedback_description
        .text;
      isFeedbackBad =
        (setLog as any).repcognition_response.feedback_description.is_warning ||
        (setLog as any).repcognition_response.feedback_description.key ===
          "fixed";
    }

    // if (
    //   "response_data_id" in setLog.repcognition_response &&
    //   setLog.repcognition_response.response_data_id !== null
    // ) {
    //   if (setLog.set_log_id !== null) {
    //     dataID = setLog.set_log_id;
    //     summaryGraphURL =
    //       "https://dt-repcognition-figures.s3.us-east-2.amazonaws.com/figures/" +
    //       dataID +
    //       "_summary.png";
    //     romGraphURL =
    //       "https://dt-repcognition-figures.s3.us-east-2.amazonaws.com/figures/" +
    //       dataID +
    //       "_rom.png";
    //   }
    // }

    if (log?.metadata?.from_auto_conversion) {
      if (
        "response_data_id" in (setLog as any).repcognition_response &&
        (setLog as any).repcognition_response.response_data_id !== null
      ) {
        dataID = (setLog as any).repcognition_response.response_data_id;
        summaryGraphURL =
          "https://dt-repcognition-figures.s3.us-east-2.amazonaws.com/figures/" +
          dataID +
          "_summary.png";
        romGraphURL =
          "https://dt-repcognition-figures.s3.us-east-2.amazonaws.com/figures/" +
          dataID +
          "_rom.png";
      }
    } else {
      if (setLog.set_log_id !== null) {
        dataID = setLog.set_log_id;
        summaryGraphURL =
          "https://dt-repcognition-figures.s3.us-east-2.amazonaws.com/figures/" +
          dataID +
          "_summary.png";
        romGraphURL =
          "https://dt-repcognition-figures.s3.us-east-2.amazonaws.com/figures/" +
          dataID +
          "_rom.png";
      }
    }
  }

  if (!log?.metadata?.from_auto_conversion) {
    if (log?.tech_summary?.watch_used) {
      if (setLog.set_log_id !== null) {
        dataID = setLog.set_log_id;
        summaryGraphURL =
          "https://dt-repcognition-figures.s3.us-east-2.amazonaws.com/figures/" +
          dataID +
          "_summary.png";
        romGraphURL =
          "https://dt-repcognition-figures.s3.us-east-2.amazonaws.com/figures/" +
          dataID +
          "_rom.png";
      }
    }
  }

  if (!client) {
    return null;
  }

  function ignoreLogFeedback() {
    if (!log) {
      return;
    }

    const newSections = JSON.parse(
      JSON.stringify(log.sections),
    ) as LogSection[];

    newSections.forEach((section) => {
      section.cycles.forEach((cycle) => {
        cycle.exercises.forEach((exercise) => {
          exercise.sets.forEach((set) => {
            if (set.set_id === setLog.set_id) {
              set.feedback_addressed = {
                type: "ignored",
                date: new Date().toISOString(),
              };
            }
          });
        });
      });
    });

    updateLog({ log_id: log.log_id, sections: newSections });
  }

  function focusSet(attributeToFocus?: "reps" | "weight" | "time" | "rest") {
    if (!firstExerciseId || !firstSetId) {
      return;
    }

    dispatch(
      highlightSet({
        firstExerciseId: firstExerciseId,
        firstSetId: firstSetId,
        setId: setLog.set_id,
        attributeToFocus: attributeToFocus,
      }),
    );

    if (!exerciseExists) {
      enqueueSnackbar("Set not found. Maybe the workout changed");
    }
  }

  const disableIcons =
    !lengths && !hasROM && !speedMeans && !feedback && dataID === undefined;

  return (
    <Box
      sx={{
        border: 2,
        borderRadius: 1,
        borderColor: (theme) =>
          isHighlighted
            ? theme.palette.primary.main
            : setLog.is_pr_new
              ? "#9D7F10"
              : alpha(theme.palette.primary.main, 0),
        backgroundColor: (theme) =>
          isHighlighted
            ? alpha(theme.palette.primary.main, 0.05)
            : setLog.is_pr_new
              ? alpha("#9D7F10", 0.05)
              : undefined,
        transitionProperty: "background-color border-color",
        transitionDuration: "0.3s",
        transitionTimingFunction: "ease-in-out",
        opacity: setLog.performance_index === null ? 0.5 : undefined,
      }}
    >
      <Box
        sx={{
          display: "flex",
          alignItems: "center",
          justifyContent: "space-between",
        }}
      >
        <Box sx={{ display: "flex", alignItems: "center" }}>
          {setLog.is_pr_new && (
            <Tooltip title="This set was a PR">
              <SvgIcon fontSize="medium" sx={{ color: "#9D7F10", mr: 1 }}>
                <path d="M12 8L15 13.2L18 10.5L17.3 14H6.7L6 10.5L9 13.2L12 8M12 4L8.5 10L3 5L5 16H19L21 5L15.5 10L12 4M19 18H5V19C5 19.6 5.4 20 6 20H18C18.6 20 19 19.6 19 19V18Z" />
              </SvgIcon>
            </Tooltip>
          )}
          {enablePreview ? (
            <Button
              variant="text"
              startIcon={<VisibilityRoundedIcon />}
              onClick={() => {
                focusSet();
              }}
            >
              Set {setIndex + 1}
            </Button>
          ) : (
            <Typography
              sx={{
                fontWeight: "bold",
                color: (theme) =>
                  setLog.performance_index === null
                    ? theme.palette.text.disabled
                    : undefined,
              }}
            >
              Set {setIndex + 1}
            </Typography>
          )}
        </Box>
        {setLog.performance_index === null && (
          <Chip
            color="error"
            size="small"
            icon={<SkipNextIcon />}
            label={"Skipped"}
            variant="outlined"
          />
        )}
        {setLog.rir === "0_to_2" ? (
          <Chip
            size="small"
            icon={<ThumbUpAltRoundedIcon />}
            label={"0-2 RiR"}
            onClick={
              enablePreview
                ? () => {
                    focusSet();
                  }
                : undefined
            }
          />
        ) : setLog.rir === "not_finished" ? (
          <Chip
            color={setLog.feedback_addressed ? "default" : "error"}
            size="small"
            icon={<WarningRoundedIcon />}
            deleteIcon={
              setLog.feedback_addressed ? undefined : <TaskAltRoundedIcon />
            }
            onDelete={setLog.feedback_addressed ? undefined : ignoreLogFeedback}
            onClick={
              enablePreview
                ? () => {
                    focusSet();
                  }
                : undefined
            }
            label={"Didn't finish"}
            disabled={isUpdating}
          />
        ) : setLog.rir === "3+" ? (
          <Chip
            color={setLog.feedback_addressed ? "default" : "error"}
            size="small"
            icon={<WarningRoundedIcon />}
            deleteIcon={
              setLog.feedback_addressed ? undefined : <TaskAltRoundedIcon />
            }
            onDelete={setLog.feedback_addressed ? undefined : ignoreLogFeedback}
            onClick={
              enablePreview
                ? () => {
                    focusSet();
                  }
                : undefined
            }
            label={"3+ RiR"}
            disabled={isUpdating}
          />
        ) : null}
      </Box>

      {setLog.performance_index !== null && (
        <>
          <Box
            sx={{
              display: "flex",
              flexWrap: "wrap",
              listStyle: "none",
              p: 0,
              mx: -1,
              my: 0,
            }}
            component="ul"
          >
            {values.weight && (
              <ListItem>
                <Chip
                  size="small"
                  color={
                    !workoutLib.workoutLogs.isAcceptableWeight(setLog)
                      ? "error"
                      : undefined
                  }
                  label={`${getConvertedWeight({
                    weight: setLog.weight_performed!,
                    fromSystem: "imperial",
                    toSystem: client.preferred_weight_system,
                    round: true,
                  })} 
              / 
              ${getConvertedWeight({
                weight: setLog.weight_target!,
                fromSystem: "imperial",
                toSystem: client.preferred_weight_system,
                round: true,
              })} ${client.preferred_weight_system === "metric" ? "kg" : "lbs"}`}
                  sx={{ fontSize: 12 }}
                  variant="outlined"
                  onClick={
                    !enablePreview
                      ? undefined
                      : () => {
                          focusSet("weight");
                        }
                  }
                />
              </ListItem>
            )}
            {values.reps && (
              <ListItem>
                <Chip
                  size="small"
                  color={
                    !workoutLib.workoutLogs.isAcceptableReps(setLog)
                      ? "error"
                      : undefined
                  }
                  label={`${setLog.reps_performed} / ${setLog.reps_target} reps`}
                  sx={{ fontSize: 12 }}
                  variant="outlined"
                  onClick={
                    !enablePreview
                      ? undefined
                      : () => {
                          focusSet("reps");
                        }
                  }
                />
              </ListItem>
            )}
            {!values.time && (
              <ListItem>
                <Chip
                  size="small"
                  label={`Time: ${getFormattedTime(timeSeconds)}`}
                  sx={{ fontSize: 12 }}
                  variant="outlined"
                  onClick={
                    !enablePreview
                      ? undefined
                      : () => {
                          focusSet();
                        }
                  }
                />
              </ListItem>
            )}
            {values.time && (
              <ListItem>
                <Chip
                  size="small"
                  color={
                    !workoutLib.workoutLogs.isAcceptableTime(setLog)
                      ? "error"
                      : undefined
                  }
                  label={`Time: ${getFormattedTime(
                    setLog.time_performed!,
                  )} / ${getFormattedTime(setLog.time_target!)}`}
                  sx={{ fontSize: 12 }}
                  variant="outlined"
                  onClick={
                    !enablePreview
                      ? undefined
                      : () => {
                          focusSet("time");
                        }
                  }
                />
              </ListItem>
            )}
            {setLog.rest_performed !== undefined &&
              setLog.rest_performed !== null &&
              setLog.rest_performed !== 0 && (
                <ListItem>
                  <Chip
                    size="small"
                    color={
                      !workoutLib.workoutLogs.isAcceptableRest(setLog)
                        ? "error"
                        : undefined
                    }
                    label={`Rest: ${getFormattedTime(
                      setLog.rest_performed!,
                    )} / ${getFormattedTime(setLog.rest_target!)}`}
                    sx={{ fontSize: 12 }}
                    variant="outlined"
                    onClick={
                      !enablePreview
                        ? undefined
                        : () => {
                            focusSet("rest");
                          }
                    }
                  />
                </ListItem>
              )}
          </Box>
          {setLog.notes_client && (
            <Box
              sx={{
                backgroundColor: (theme) =>
                  theme.palette.backgroundSecondary.main,
                borderRadius: `10px 10px 10px 2px`,
                display: "flex",
                alignItems: "center",
                justifyContent: "space-between",
                border: 2,
                borderColor: (theme) =>
                  setLog.feedback_addressed
                    ? "divider"
                    : theme.palette.error.main,
                p: 1,
                my: 1,
              }}
            >
              <Box sx={{ display: "flex", alignItems: "center" }}>
                <ChatBubbleRoundedIcon
                  fontSize="inherit"
                  color={setLog.feedback_addressed ? undefined : "error"}
                  sx={{ mr: 1 }}
                />
                <Typography>{setLog.notes_client}</Typography>
              </Box>
              {!setLog.feedback_addressed && (
                <IconButton size="small" onClick={ignoreLogFeedback}>
                  <TaskAltRoundedIcon />
                </IconButton>
              )}
            </Box>
          )}

          {isFalseNegative ? (
            <Box
              sx={{
                padding: 1,
                borderStyle: "dashed",
                borderColor: (theme) => theme.palette.divider,
                borderRadius: 1,
                borderWidth: "2px",
                alignItems: "center",
              }}
            >
              <Grid container>
                <Grid item xs>
                  <Typography>False negative</Typography>
                  <Typography
                    variant="body2"
                    sx={{
                      color: (theme) => theme.palette.text.secondary,
                    }}
                  >
                    Tracking did not work for this set
                  </Typography>
                </Grid>
                <Grid item xs="auto">
                  <IconButton
                    color="primary"
                    disabled={dataID === undefined}
                    onMouseEnter={(event) => {
                      setSummaryGraphAnchor(event.currentTarget);
                    }}
                    onMouseLeave={() => {
                      setSummaryGraphAnchor(null);
                    }}
                    onClick={() => {
                      if (dataID) {
                        navigator.clipboard.writeText(dataID).then(() => {
                          enqueueSnackbar("Copied to clipboard", {
                            variant: "success",
                          });
                        });
                      }
                    }}
                    size="small"
                  >
                    <TimelineRoundedIcon fontSize="inherit" />
                  </IconButton>
                </Grid>
              </Grid>
            </Box>
          ) : !disableIcons ? (
            <Grid container spacing={0.5} columns={5}>
              <Grid item xs={1}>
                <IconButton
                  disabled={!lengths}
                  color={
                    workoutLib.workoutLogs.isAcceptablePacing(setLog)
                      ? "primary"
                      : "error"
                  }
                  onMouseEnter={(event) => {
                    setPaceGraphAnchor(event.currentTarget);
                  }}
                  onMouseLeave={() => {
                    setPaceGraphAnchor(null);
                  }}
                  size="small"
                >
                  <TimerRoundedIcon fontSize="inherit" />
                </IconButton>
              </Grid>
              <Grid item xs={1}>
                <IconButton
                  disabled={!hasROM}
                  onMouseEnter={(event) => {
                    setRomGraphsAnchor(event.currentTarget);
                  }}
                  onMouseLeave={() => {
                    setRomGraphsAnchor(null);
                  }}
                  color={
                    workoutLib.workoutLogs.isAcceptableRom(setLog)
                      ? "primary"
                      : "error"
                  }
                  onClick={async () => {
                    const image = await fetch(romGraphURL);
                    const blob = await image.blob();
                    const clipboardItem = new ClipboardItem({
                      "image/png": blob,
                    });
                    await navigator.clipboard
                      .write([clipboardItem])
                      .then(() => {
                        enqueueSnackbar("Copied to clipboard", {
                          variant: "success",
                        });
                      });
                  }}
                  size="small"
                >
                  <DataUsageRoundedIcon fontSize="inherit" />
                </IconButton>
              </Grid>
              <Grid item xs={1}>
                <IconButton
                  disabled={!speedMeans}
                  onMouseEnter={(event) => {
                    setKineticGraphsAnchor(event.currentTarget);
                  }}
                  color={"primary"}
                  onMouseLeave={() => {
                    setKineticGraphsAnchor(null);
                  }}
                  size="small"
                >
                  <SpeedRoundedIcon fontSize="inherit" />
                </IconButton>
              </Grid>
              <Grid item xs={1}>
                <IconButton
                  disabled={!feedback}
                  color={isFeedbackBad ? "error" : "primary"}
                  onMouseEnter={(event) => {
                    setFeedbackAnchor(event.currentTarget);
                  }}
                  onMouseLeave={() => {
                    setFeedbackAnchor(null);
                  }}
                  size="small"
                >
                  <AssignmentRoundedIcon fontSize="inherit" />
                </IconButton>
              </Grid>
              <Grid item xs={1}>
                <IconButton
                  disabled={dataID === undefined}
                  color={"primary"}
                  onMouseEnter={(event) => {
                    setSummaryGraphAnchor(event.currentTarget);
                  }}
                  onMouseLeave={() => {
                    setSummaryGraphAnchor(null);
                  }}
                  onClick={() => {
                    if (dataID) {
                      navigator.clipboard.writeText(dataID).then(() => {
                        enqueueSnackbar("Copied to clipboard", {
                          variant: "success",
                        });
                      });
                    }
                  }}
                  size="small"
                >
                  <TimelineRoundedIcon fontSize="inherit" />
                </IconButton>
              </Grid>
            </Grid>
          ) : null}
        </>
      )}

      <Popover
        style={{ pointerEvents: "none", borderRadius: 350 }}
        open={romGraphsOpen}
        anchorEl={romGraphsAnchor}
        anchorOrigin={{
          vertical: "center",
          horizontal: "right",
        }}
        transformOrigin={{
          vertical: "center",
          horizontal: "left",
        }}
        onClose={() => {
          setRomGraphsAnchor(null);
        }}
        disableRestoreFocus
      >
        <Typography style={{ textAlign: "center" }} variant="h5">
          Range of Motion
        </Typography>
        <img
          crossOrigin={"anonymous"}
          src={romGraphURL ?? ""}
          width={500}
          height={500}
          alt="rom graph"
        />
      </Popover>

      <Popover
        sx={{ pointerEvents: "none" }}
        slotProps={{
          paper: { sx: { p: 2 } },
        }}
        open={summaryGraphOpen}
        anchorEl={summaryGraphAnchor}
        anchorOrigin={{
          vertical: "center",
          horizontal: "right",
        }}
        transformOrigin={{
          vertical: "center",
          horizontal: "left",
        }}
        onClose={() => {
          setSummaryGraphAnchor(null);
        }}
        disableRestoreFocus
      >
        <Typography sx={{ textAlign: "center", mb: 2 }} variant="h2">
          Raw Output
        </Typography>
        <img
          crossOrigin={"anonymous"}
          src={summaryGraphURL}
          style={{ width: 750 }}
        />
      </Popover>

      <Popover
        sx={{ pointerEvents: "none" }}
        slotProps={{
          paper: { sx: { p: 2 } },
        }}
        open={feedbackOpen}
        anchorEl={feedbackAnchor}
        anchorOrigin={{
          vertical: "center",
          horizontal: "right",
        }}
        transformOrigin={{
          vertical: "center",
          horizontal: "left",
        }}
        onClose={() => {
          setFeedbackAnchor(null);
        }}
        disableRestoreFocus
      >
        <Typography variant="overline">Feedback</Typography>
        <Typography>{feedback}</Typography>
      </Popover>

      {lengths && (
        <Popover
          sx={{ pointerEvents: "none" }}
          slotProps={{
            paper: { sx: { p: 2 } },
          }}
          open={paceGraphOpen}
          anchorEl={paceGraphAnchor}
          anchorOrigin={{
            vertical: "center",
            horizontal: "right",
          }}
          transformOrigin={{
            vertical: "center",
            horizontal: "left",
          }}
          onClose={() => {
            setPaceGraphAnchor(null);
          }}
          disableRestoreFocus
        >
          <Typography variant="h2" sx={{ textAlign: "center" }}>
            Pacing
          </Typography>
          {pace && paceIdeal && (
            <Typography sx={{ textAlign: "center", mb: 2 }}>
              {pace + "s / " + paceIdeal + "s"}
            </Typography>
          )}
          <PacingChart
            lengths={
              (setLog as any).repcognition_response?.rep_analysis?.lengths ?? []
            }
            idealPacing={paceIdeal}
            isBadPacing={isPaceBad}
          />
        </Popover>
      )}

      {speedMeans && (
        <Popover
          sx={{ pointerEvents: "none" }}
          slotProps={{
            paper: { sx: { p: 2 } },
          }}
          open={kineticGraphsOpen}
          anchorEl={kineticGraphsAnchor}
          anchorOrigin={{
            vertical: "center",
            horizontal: "right",
          }}
          transformOrigin={{
            vertical: "center",
            horizontal: "left",
          }}
          onClose={() => {
            setKineticGraphsAnchor(null);
          }}
          disableRestoreFocus
        >
          <Typography variant="h2" sx={{ textAlign: "center" }}>
            Rep velocity
          </Typography>
          <RepVelocityChart
            speedMeans={
              (setLog as any).repcognition_response?.rep_analysis
                ?.speed_means ?? []
            }
          />
        </Popover>
      )}
    </Box>
  );
}

import { draggable } from "@atlaskit/pragmatic-drag-and-drop/element/adapter";
import { pointerOutsideOfPreview } from "@atlaskit/pragmatic-drag-and-drop/element/pointer-outside-of-preview";
import { setCustomNativeDragPreview } from "@atlaskit/pragmatic-drag-and-drop/element/set-custom-native-drag-preview";
import CalendarViewWeekRoundedIcon from "@mui/icons-material/CalendarViewWeekRounded";
import ContentCopyRoundedIcon from "@mui/icons-material/ContentCopyRounded";
import DeleteRoundedIcon from "@mui/icons-material/DeleteRounded";
import ExtensionRoundedIcon from "@mui/icons-material/ExtensionRounded";
import IosShareRoundedIcon from "@mui/icons-material/IosShareRounded";
import MoreVertRoundedIcon from "@mui/icons-material/MoreVertRounded";
import RestoreFromTrashRoundedIcon from "@mui/icons-material/RestoreFromTrashRounded";
import {
  Avatar,
  Box,
  CardActionArea,
  Divider,
  IconButton,
  ListItemIcon,
  ListItemText,
  Menu,
  MenuItem,
  Tooltip,
  Typography,
} from "@mui/material";
import { formatDistanceToNow } from "date-fns";
import { useConfirm } from "material-ui-confirm";
import { memo, useEffect, useRef, useState } from "react";
import { createPortal } from "react-dom";
import { DragPreview } from "src/components/common/DragPreview";
import { useAppDispatch, useAppSelector } from "src/hooks/stateHooks";
import { api } from "src/lib/trainwellApi";
import {
  selectPreferredWorkoutDays,
  setIsDraggingPhase,
} from "src/slices/clientSlice";
import { selectAllVisibleClients } from "src/slices/clientsSlice";
import {
  deletePhase,
  duplicatePhase,
  selectPhaseById,
  setPhaseEditing,
  updatePhase,
} from "src/slices/phasesSlice";
import { SaveAsTemplatePopover } from "./SaveAsTemplatePopover";
import { ShareToClientPopover } from "./ShareToClientPopover";
import { ShareToCoachPopover } from "./ShareToCoachPopover";

type Props = {
  phaseId: string;
};

export default function PhaseCellDraggable({ phaseId }: Props) {
  const phase = useAppSelector((state) => selectPhaseById(state, phaseId));
  const ref = useRef(null);
  const [previewContainer, setPreviewContainer] = useState<HTMLElement | null>(
    null,
  );
  const dispatch = useAppDispatch();
  const hasNoPreferredWorkoutDay = useAppSelector(
    (state) => !selectPreferredWorkoutDays(state).some((d) => d),
  );

  useEffect(() => {
    const element = ref.current;

    if (!element) {
      return;
    }

    const data = {
      type: "phase",
      phaseId: phaseId,
      isSouce: true,
    };

    return draggable({
      element: element,
      getInitialData: () => data,
      onGenerateDragPreview({ nativeSetDragImage }) {
        setCustomNativeDragPreview({
          nativeSetDragImage,
          getOffset: pointerOutsideOfPreview({
            x: "16px",
            y: "8px",
          }),
          render({ container }) {
            setPreviewContainer(container);
          },
        });
      },
      onDragStart: () => {
        dispatch(setIsDraggingPhase(true));
      },
      onDrop: () => {
        dispatch(setIsDraggingPhase(false));
      },
    });
  }, [phaseId, dispatch]);

  return (
    <>
      <div
        style={{
          position: "relative",
        }}
      >
        <div ref={ref}>
          <PhaseCell phaseId={phaseId} />
        </div>
      </div>
      {previewContainer
        ? createPortal(
            <DragPreview
              text={phase?.name ?? "Workout"}
              errorText={
                hasNoPreferredWorkoutDay
                  ? "Cannot drop, client has no workout schedule"
                  : undefined
              }
              icon={
                <Avatar
                  sx={{
                    backgroundColor: (theme) => theme.palette.success.main,
                    width: 20,
                    height: 20,
                    borderRadius: "6px",
                  }}
                >
                  <CalendarViewWeekRoundedIcon
                    sx={{
                      fontSize: 16,
                    }}
                  />
                </Avatar>
              }
            />,
            previewContainer,
          )
        : null}
    </>
  );
}

const PhaseCell = memo(function PhaseCell({ phaseId }: Props) {
  const dispatch = useAppDispatch();
  const phase = useAppSelector((state) => selectPhaseById(state, phaseId));
  const [moreAnchorEl, setMoreAnchorEl] = useState<null | HTMLButtonElement>(
    null,
  );
  const [saveAsTemplateAnchorEl, setSaveAsTemplateAnchorEl] =
    useState<null | HTMLButtonElement>(null);
  const [shareToClientAnchorEl, setShareToClientAnchorEl] =
    useState<null | HTMLButtonElement>(null);
  const [shareToCoachAnchorEl, setShareToCoachAnchorEl] =
    useState<null | HTMLButtonElement>(null);
  const clients = useAppSelector(selectAllVisibleClients);
  const confirm = useConfirm();

  const clientNames = clients.map((client) => {
    return {
      user_id: client.user_id,
      name: client.full_name,
    };
  });

  clientNames.sort((a, b) => (a.name > b.name ? 1 : b.name > a.name ? -1 : 0));

  if (!phase) {
    return <>Loading</>;
  }

  return (
    <>
      <CardActionArea
        onClick={() => {
          dispatch(
            setPhaseEditing({
              ...phase,
              days_draggable: phase.workout_ids.map((day) => {
                return {
                  draggable_id: crypto.randomUUID(),
                  workouts: day.map((template) => {
                    return {
                      draggable_id: crypto.randomUUID(),
                      workout_id: template,
                    };
                  }),
                };
              }),
            }),
          );
        }}
        sx={{
          pl: 1,
          py: 1,
          pr: 0.25,
          borderBottom: 1,
          borderColor: "divider",
        }}
      >
        <Box
          sx={{
            display: "flex",
            alignItems: "flex-start",
            justifyContent: "space-between",
          }}
        >
          <Box sx={{ display: "flex", alignItems: "flex-start" }}>
            <Avatar
              sx={{
                backgroundColor: (theme) => theme.palette.success.main,
                mr: 0.5,
                mt: 0.5,
                width: 15,
                height: 15,
                borderRadius: "4px",
              }}
            >
              <CalendarViewWeekRoundedIcon
                sx={{
                  fontSize: 12,
                }}
              />
            </Avatar>
            <Typography>{phase?.name}</Typography>
          </Box>
          {phase.date_deleted ? (
            <Tooltip title="Restore" placement="right">
              <IconButton
                size="small"
                onClick={(event) => {
                  event.stopPropagation();

                  dispatch(
                    updatePhase({
                      id: phaseId,
                      dateDeleted: null,
                    }),
                  );
                }}
                onMouseDown={(event) => {
                  event.stopPropagation();
                }}
              >
                <RestoreFromTrashRoundedIcon sx={{ fontSize: 18 }} />
              </IconButton>
            </Tooltip>
          ) : (
            <IconButton
              size="small"
              onClick={(event) => {
                event.stopPropagation();

                setMoreAnchorEl(event.currentTarget);
              }}
              onMouseDown={(event) => {
                event.stopPropagation();
              }}
            >
              <MoreVertRoundedIcon sx={{ fontSize: 18 }} />
            </IconButton>
          )}
        </Box>
        <Typography
          variant="body2"
          sx={{
            color: (theme) => theme.palette.text.secondary,
            whiteSpace: "nowrap",
            overflow: "hidden",
            textOverflow: "ellipsis",
          }}
        >
          {phase.description}
        </Typography>
        <Typography variant="overline">
          Created {formatDistanceToNow(new Date(phase.date_created))} ago
        </Typography>
      </CardActionArea>
      {moreAnchorEl && (
        <Menu
          open={Boolean(moreAnchorEl)}
          anchorEl={moreAnchorEl}
          anchorOrigin={{
            vertical: "bottom",
            horizontal: "left",
          }}
          transformOrigin={{
            vertical: "top",
            horizontal: "left",
          }}
          onClose={() => {
            setMoreAnchorEl(null);
          }}
        >
          <MenuItem
            onClick={() => {
              dispatch(
                duplicatePhase({
                  phaseId,
                }),
              );

              setMoreAnchorEl(null);
            }}
          >
            <ListItemIcon>
              <ContentCopyRoundedIcon fontSize="small" />
            </ListItemIcon>
            <ListItemText primary="Make a copy" />
          </MenuItem>
          <MenuItem
            onClick={() => {
              setShareToClientAnchorEl(moreAnchorEl);

              setMoreAnchorEl(null);
            }}
          >
            <ListItemIcon>
              <IosShareRoundedIcon fontSize="small" />
            </ListItemIcon>
            <ListItemText primary="Share to client" />
          </MenuItem>
          <MenuItem
            onClick={() => {
              setShareToCoachAnchorEl(moreAnchorEl);

              setMoreAnchorEl(null);
            }}
          >
            <ListItemIcon>
              <IosShareRoundedIcon fontSize="small" />
            </ListItemIcon>
            <ListItemText primary="Share to trainer" />
          </MenuItem>
          <MenuItem
            onClick={() => {
              setSaveAsTemplateAnchorEl(moreAnchorEl);

              setMoreAnchorEl(null);
            }}
          >
            <ListItemIcon>
              <ExtensionRoundedIcon fontSize="small" />
            </ListItemIcon>
            <ListItemText primary="Save as template" />
          </MenuItem>
          <Box>
            <Divider sx={{ my: 1 }} />
          </Box>
          <MenuItem
            onClick={() => {
              setMoreAnchorEl(null);

              confirm({
                title: "Delete phase",
                description:
                  "This will delete all the workouts inside this phase",
                confirmationText: "Delete phase",
                confirmationButtonProps: {
                  color: "error",
                },
              }).then(({ confirmed }) => {
                if (!confirmed) {
                  return;
                }

                dispatch(deletePhase({ phaseId }));
              });
            }}
          >
            <ListItemIcon>
              <DeleteRoundedIcon fontSize="small" color="error" />
            </ListItemIcon>
            <ListItemText
              primary="Delete"
              sx={{ color: (theme) => theme.palette.error.main }}
            />
          </MenuItem>
        </Menu>
      )}
      {shareToClientAnchorEl && (
        <ShareToClientPopover
          phaseId={phaseId}
          anchorEl={shareToClientAnchorEl}
          onClose={() => {
            setShareToClientAnchorEl(null);
          }}
        />
      )}
      {shareToCoachAnchorEl && (
        <ShareToCoachPopover
          onShare={(targetTrainerId) => {
            return api.phases.saveAsTemplate({
              phaseId,
              trainerId: targetTrainerId,
            });
          }}
          anchorEl={shareToCoachAnchorEl}
          onClose={() => {
            setShareToCoachAnchorEl(null);
          }}
        />
      )}
      {saveAsTemplateAnchorEl && (
        <SaveAsTemplatePopover
          phaseId={phaseId}
          anchorEl={saveAsTemplateAnchorEl}
          onClose={() => {
            setSaveAsTemplateAnchorEl(null);
          }}
        />
      )}
    </>
  );
});

import {
  Box,
  Card,
  FormControl,
  InputLabel,
  MenuItem,
  Select,
  Typography,
} from "@mui/material";
import { DataGrid, type GridColDef, type GridRowsProp } from "@mui/x-data-grid";
import { useVacationBudget } from "@trainwell/features/vacations";
import { addDays, endOfYear, format, setYear, startOfYear } from "date-fns";
import { useState } from "react";
import RestrictAccess from "src/components/misc/RestrictAccess";
import LoadingPage from "src/components/miscPages/LoadingPage";
import { getDateFromDatabase } from "src/lib/date";

const years = [2022, 2023, 2024, 2025, 2026];

export function VacationBudgetPage() {
  const [windowYear, setWindowYear] = useState<number>(
    new Date().getFullYear(),
  );
  const {
    data: vacationBudgets,
    isPending: isPending,
    isError: isError,
  } = useVacationBudget({
    filter: {
      start_date: startOfYear(setYear(new Date(), windowYear)).toISOString(),
      end_date: endOfYear(setYear(new Date(), windowYear)).toISOString(),
    },
  });

  const rows: GridRowsProp =
    vacationBudgets?.map((week) => {
      const totalPto = week.trainers.reduce(
        (acc, trainer) => acc + trainer.vacation_units.pto,
        0,
      );
      const totalPtoBuffer = week.trainers.reduce(
        (acc, trainer) => acc + trainer.vacation_units.pto_buffer,
        0,
      );
      const totalWto = week.trainers.reduce(
        (acc, trainer) => acc + trainer.vacation_units.wto,
        0,
      );
      const totalSick = week.trainers.reduce(
        (acc, trainer) => acc + trainer.vacation_units.sick,
        0,
      );
      const totalReviewPeriod = week.trainers.reduce(
        (acc, trainer) => acc + trainer.vacation_units.review_period,
        0,
      );
      const totalHolidays = week.trainers.reduce(
        (acc, trainer) => acc + trainer.vacation_units.holidays,
        0,
      );

      const totalVacations =
        totalPto +
        totalPtoBuffer +
        totalWto +
        totalSick +
        totalReviewPeriod +
        totalHolidays;

      return {
        id: week.starting_sunday,
        pto: totalPto,
        wto: totalWto,
        sick: totalSick,
        review_period: totalReviewPeriod,
        holidays: totalHolidays,
        pto_buffer: totalPtoBuffer,
        total: totalVacations,
      };
    }) ?? [];

  const columns: GridColDef[] = [
    {
      field: "id",
      headerName: "Week",
      width: 150,
      valueFormatter: (value: string) =>
        `${format(getDateFromDatabase(value), "MMM d")} - ${format(getDateFromDatabase(addDays(value, 6)), "MMM d")}`,
    },
    { field: "pto", headerName: "PTO", flex: 1 },
    { field: "pto_buffer", headerName: "PTO buffer", flex: 1 },
    { field: "wto", headerName: "WTO", flex: 1 },
    { field: "sick", headerName: "Sick", flex: 1 },
    { field: "review_period", headerName: "Review period", flex: 1 },
    { field: "holidays", headerName: "Holidays", flex: 1 },
    { field: "total", headerName: "Total", flex: 1 },
  ];

  if (isError) {
    return <Typography>Error loading budget</Typography>;
  }

  return (
    <RestrictAccess location={"vacations"} showNoAccessMessage>
      <Box
        sx={{
          height: "calc(100vh - 38px)",
          overflowY: "auto",
          display: "flex",
          flexDirection: "column",
          pb: 3,
        }}
      >
        <Box
          sx={{
            px: 3,
            pt: 3,
            display: "flex",
            alignItems: "center",
            justifyContent: "space-between",
            mb: 2,
          }}
        >
          <Typography variant="h1">Vacation budget</Typography>
          <FormControl size="small">
            <InputLabel>Year</InputLabel>
            <Select
              value={windowYear}
              label="Year"
              onChange={(event) => {
                setWindowYear(Number(event.target.value));
              }}
            >
              {years.map((year) => (
                <MenuItem key={year} value={year}>
                  {year}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
        </Box>
        {isPending ? (
          <LoadingPage message="Loading budget" />
        ) : (
          <Card variant="outlined" sx={{ mx: 3, flex: 1 }}>
            <DataGrid rows={rows} columns={columns} />
          </Card>
        )}
      </Box>
    </RestrictAccess>
  );
}

import { Box, Typography } from "@mui/material";
import type { CoachNotification } from "@trainwell/features/legacy";
import { getCoachNotificationDetails } from "src/lib/coachNotification";
import { useGetClientQuery } from "src/slices/api/clientApi";

type Props = {
  notification: CoachNotification;
};

export default function CoachNotificationCell({ notification }: Props) {
  const { data: client } = useGetClientQuery(notification.user_id);

  const details = getCoachNotificationDetails(notification);

  return (
    <Box sx={{ display: "flex", alignItems: "flex-start" }}>
      {details.icon}
      <Box sx={{ ml: 1 }}>
        <Typography sx={{ lineHeight: 1 }}>
          {client?.full_name || "Loading client name..."}
        </Typography>
        <Typography sx={{ fontWeight: "bold" }}>{details.name}</Typography>
        {details.notes && (
          <Typography variant="body2">{details.notes}</Typography>
        )}
      </Box>
    </Box>
  );
}

import { Box, CardActionArea, Typography } from "@mui/material";
import React from "react";
import { useAppDispatch, useAppSelector } from "src/hooks/stateHooks";
import { openChat } from "src/slices/chatSlice";
import { selectClientById } from "src/slices/clientsSlice";
import ActionItemRow from "./ActionItemRow";

type Props = {
  userId: string;
};

const ActionItemClientCell = React.memo(function ActionItemClientCell({
  userId,
}: Props) {
  const dispatch = useAppDispatch();
  const clientHeadshot = useAppSelector(
    (state) => selectClientById(state, userId)?.headshot_url,
  );
  const clientName = useAppSelector(
    (state) => selectClientById(state, userId)?.full_name ?? "Client not found",
  );

  return (
    <CardActionArea
      sx={{
        pl: 2,
        py: 1,
        borderBottom: 1,
        borderColor: "divider",
      }}
      onClick={() => {
        dispatch(openChat({ chatId: userId }));
      }}
    >
      <Box
        sx={{
          display: "flex",
          alignItems: "center",
        }}
      >
        <img
          style={{
            borderRadius: "19px",
          }}
          width={38}
          height={38}
          src={clientHeadshot || "/assets/profile.png"}
          alt="Headshot"
        />
        <Typography sx={{ fontWeight: "bold", ml: 1 }}>{clientName}</Typography>
      </Box>
      <Box>
        <ActionItemRow userId={userId} />
      </Box>
    </CardActionArea>
  );
});

export default ActionItemClientCell;

import RestaurantRoundedIcon from "@mui/icons-material/RestaurantRounded";
import { Box, CardActionArea, Stack, Typography } from "@mui/material";
import type {
  MessageNutritionReview,
  MessageNutritionReviewRequest,
} from "@trainwell/features/legacy";
import type {
  ClientMealLog,
  NutritionReview,
} from "@trainwell/features/nutrition";
import { useCallback, useMemo, useState } from "react";
import NutritionReviewModal from "src/features/nutrition/NutritionReviewModal";
import { useAppDispatch } from "src/hooks/stateHooks";
import { updateMessageLocal } from "src/slices/chatSlice";

type Props = {
  message: MessageNutritionReviewRequest | MessageNutritionReview;
};

export default function NutritionReviewMessage({ message }: Props) {
  const [modalOpen, setModalOpen] = useState(false);
  const dispatch = useAppDispatch();

  const title = useMemo(
    () =>
      message.type === "nutrition_review"
        ? "Nutrition Review"
        : "Nutrition Review Request",
    [message],
  );

  const subtitle = useMemo(
    () =>
      message.type === "nutrition_review"
        ? null
        : !message.content.nutrition_review.date_submitted
          ? "Incomplete"
          : `Completed`,
    [message],
  );

  const review = useMemo(() => message.content.nutrition_review, [message]);

  const handleOpenDialog = useCallback(
    () => setModalOpen(true),
    [setModalOpen],
  );

  const handleCloseDialog = useCallback(
    () => setModalOpen(false),
    [setModalOpen],
  );

  const handleReviewUpdated = useCallback(
    (review: NutritionReview) => {
      dispatch(
        updateMessageLocal({
          ...message,
          content: { ...message.content, nutrition_review: review },
        } as MessageNutritionReview),
      );
    },
    [dispatch, updateMessageLocal],
  );

  const handleLogUpdated = useCallback(
    (log: ClientMealLog) => {
      const updatedMealLogs = message.content.nutrition_review.meal_logs.map(
        (l) => (l._id === log._id ? log : l),
      );
      dispatch(
        updateMessageLocal({
          ...message,
          content: {
            ...message.content,
            nutrition_review: {
              ...message.content.nutrition_review,
              meal_logs: updatedMealLogs,
            },
          },
        } as MessageNutritionReview),
      );
    },
    [dispatch, updateMessageLocal],
  );

  return (
    <>
      <CardActionArea
        onClick={handleOpenDialog}
        sx={{
          maxWidth: "300px",
          borderRadius: `12px`,
          overflow: "hidden",
          backgroundColor: (theme) => theme.palette.backgroundSecondary.main,
          p: 1,
          display: "flex",
          alignItems: "center",
          width: "fit-content",
        }}
      >
        <Stack
          direction={"column"}
          spacing={1}
          useFlexGap
          sx={{
            whiteSpace: "pre-line",
            wordWrap: "break-word",
          }}
        >
          <Box sx={{ display: "flex", alignItems: "center" }}>
            <RestaurantRoundedIcon />
            <Box>
              <Typography
                variant="h6"
                sx={{
                  color: (theme) => theme.palette.text.primary,
                  marginLeft: "8px",
                }}
              >
                {title}
              </Typography>
            </Box>
          </Box>
          {subtitle && (
            <Typography
              variant="body1"
              sx={{
                color: (theme) => theme.palette.text.primary,
              }}
            >
              {subtitle}
            </Typography>
          )}
        </Stack>
      </CardActionArea>
      <NutritionReviewModal
        open={modalOpen}
        review={review}
        onClose={handleCloseDialog}
        onReviewUpdated={handleReviewUpdated}
        onLogUpdated={handleLogUpdated}
      />
    </>
  );
}

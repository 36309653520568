import ClearRoundedIcon from "@mui/icons-material/ClearRounded";
import FilterListRoundedIcon from "@mui/icons-material/FilterListRounded";
import {
  Badge,
  Box,
  Button,
  IconButton,
  Popover,
  ToggleButton,
  ToggleButtonGroup,
  Tooltip,
  Typography,
} from "@mui/material";
import { useEffect, useRef, useState } from "react";
import ChatListFilter from "src/features/chat/ChatListFilter";

type Props = {
  filters: string[];
  onChange: (newFilters: string[]) => void;
  mode: "and" | "or";
  onModeChange: (mode: "and" | "or") => void;
  disabled?: boolean;
};

export default function ClientFilterButton({
  filters,
  onChange,
  mode,
  onModeChange,
  disabled,
}: Props) {
  const [anchorEl, setAnchorEl] = useState<HTMLButtonElement | null>(null);
  const open = Boolean(anchorEl);
  const inputRef = useRef<HTMLInputElement>(null);

  useEffect(() => {
    if (open) {
      setTimeout(() => {
        inputRef.current?.focus();
      }, 150);
    }
  }, [open]);

  return (
    <>
      <Box sx={{ display: "flex", alignItems: "center" }}>
        <Tooltip title="Filter" enterDelay={1000}>
          <Badge
            badgeContent={filters.length}
            color="primary"
            overlap="circular"
            sx={{
              "& .MuiBadge-badge": {
                px: "3px",
                height: "16px",
                minWidth: "16px",
              },
            }}
          >
            <IconButton
              size="small"
              onClick={(event) => {
                setAnchorEl(event.currentTarget);
              }}
            >
              <FilterListRoundedIcon />
            </IconButton>
          </Badge>
        </Tooltip>
      </Box>
      <Popover
        open={open}
        anchorEl={anchorEl}
        onClose={() => {
          setAnchorEl(null);
        }}
        anchorOrigin={{
          vertical: "center",
          horizontal: "center",
        }}
        transformOrigin={{
          vertical: "top",
          horizontal: "left",
        }}
        slotProps={{
          paper: {
            sx: { width: 400, p: 2 },
          },
        }}
      >
        <Box
          sx={{
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
            mb: 1,
          }}
        >
          <Typography variant="h2">Filter</Typography>
          <Button
            onClick={() => {
              onChange([]);
            }}
            size="small"
            variant="text"
            startIcon={<ClearRoundedIcon />}
          >
            Clear
          </Button>
        </Box>
        <ChatListFilter
          selectedFilters={filters}
          onToggle={(newFilter) => {
            const newFilters = [...filters];

            const index = newFilters.findIndex((f) => f === newFilter);

            if (index === -1) {
              newFilters.push(newFilter);
            } else {
              newFilters.splice(index, 1);
            }

            onChange(newFilters);
          }}
        />
        <ToggleButtonGroup
          color="primary"
          value={mode}
          exclusive
          sx={{ mt: 1 }}
          size="small"
          onChange={(event, newValue) => {
            if (newValue === null) {
              return;
            }

            onModeChange(newValue);
          }}
        >
          <ToggleButton value="and" sx={{ py: 0.25 }}>
            And
          </ToggleButton>
          <ToggleButton value="or" sx={{ py: 0.25 }}>
            Or
          </ToggleButton>
        </ToggleButtonGroup>
      </Popover>
    </>
  );
}

import AddRoundedIcon from "@mui/icons-material/AddRounded";
import { Box, Chip, Popover, Stack, Typography } from "@mui/material";
import type { SxProps, Theme } from "@mui/material/styles";
import React, { useMemo, useState, type JSX } from "react";
import SearchField from "./SearchField";

type Item = { id: string; label: string; icon?: JSX.Element };

type Props = {
  value: Item[];
  options?: Item[];
  onAdd: (itemId: string) => void;
  onRemove: (itemId: string) => void;
  size?: "small" | "medium";
  sx?: SxProps<Theme>;
  disabled?: boolean;
};

export function TagList({
  value,
  options,
  onAdd,
  onRemove,
  size = "small",
  sx = [],
  disabled,
}: Props) {
  const [anchorEl, setAnchorEl] = React.useState<HTMLDivElement | null>(null);
  const open = Boolean(anchorEl);
  const [search, setSearch] = useState("");

  const displayedOptions = useMemo(() => {
    const currentIds = value.map((t) => t.id);

    let newOptions: Item[] = [...(options ?? [])].filter(
      (tag) => !currentIds.includes(tag.id),
    );

    if (search !== "") {
      const sanitizedSearch = search.toLowerCase();

      newOptions = newOptions.filter((tag) =>
        tag.label.toLowerCase().includes(sanitizedSearch),
      );
    }

    return newOptions;
  }, [search, options, value]);

  return (
    <>
      <Box
        sx={{
          display: "flex",
          flexWrap: "wrap",
          gap: "8px",
          ...sx,
        }}
      >
        {value.map((tag) => (
          <Chip
            key={tag.id}
            label={tag.label}
            size={size}
            icon={tag.icon}
            disabled={disabled}
            onDelete={(event) => {
              event.stopPropagation();
              onRemove(tag.id);
            }}
            onMouseDown={(event) => {
              event.stopPropagation();
            }}
          />
        ))}
        {!disabled && (
          <Chip
            variant="outlined"
            color="primary"
            label={"Tag"}
            size={size}
            icon={<AddRoundedIcon />}
            onClick={(event) => {
              event.stopPropagation();
              event.preventDefault();

              setAnchorEl(event.currentTarget);
            }}
            onMouseDown={(event) => {
              event.stopPropagation();
              event.preventDefault();
            }}
          />
        )}
      </Box>
      <div
        onClick={(e) => e.stopPropagation()}
        onMouseDown={(e) => e.stopPropagation()}
        onMouseUp={(e) => e.stopPropagation()}
        onFocus={(e) => e.stopPropagation()}
      >
        <Popover
          open={open}
          onClose={() => {
            setAnchorEl(null);
            setSearch("");
          }}
          anchorEl={anchorEl}
          anchorOrigin={{
            vertical: "bottom",
            horizontal: "left",
          }}
          sx={{ maxHeight: "400px" }}
          slotProps={{
            paper: {
              sx: {
                p: 2,
                width: "300px",
              },
            },
          }}
        >
          <Typography variant="h2" sx={{ mb: 1 }}>
            Add tag
          </Typography>
          <SearchField
            value={search}
            onChange={(value) => {
              setSearch(value);
            }}
            onClear={() => {
              setSearch("");
            }}
            autoFocus
            placeholder="Search or add"
            onKeyDown={(event) => {
              if (event.key === "Enter") {
                event.preventDefault();

                onAdd(search);
                setSearch("");
                setAnchorEl(null);
              }
            }}
            sx={{
              mb: displayedOptions.length > 0 || search !== "" ? 2 : undefined,
            }}
          />
          <Stack direction="column" alignItems={"flex-start"} spacing={1}>
            {displayedOptions.map((tag, i) => (
              <Chip
                key={i}
                label={tag.label}
                size={size}
                deleteIcon={<AddRoundedIcon />}
                onDelete={() => {}}
                icon={tag.icon}
                onClick={(event) => {
                  event.stopPropagation();

                  onAdd(tag.id);
                  setSearch("");
                  setAnchorEl(null);
                }}
              />
            ))}
            {search !== "" && (
              <Chip
                label={search.toLowerCase()}
                size={size}
                icon={<AddRoundedIcon />}
                onClick={(event) => {
                  event.stopPropagation();

                  onAdd(search.toLowerCase());

                  setSearch("");

                  setAnchorEl(null);
                }}
              />
            )}
          </Stack>
        </Popover>
      </div>
    </>
  );
}

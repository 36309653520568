import HistoryRoundedIcon from "@mui/icons-material/HistoryRounded";
import StarRoundedIcon from "@mui/icons-material/StarRounded";
import {
  Box,
  Card,
  CardActionArea,
  Divider,
  Popover,
  ToggleButton,
  ToggleButtonGroup,
  Typography,
  alpha,
  useTheme,
} from "@mui/material";
import { ChartContainer, LineChart, LinePlot } from "@mui/x-charts";
import type { ExerciseSource } from "@trainwell/features/legacy";
import { getConvertedWeight } from "@trainwell/workout-lib";
import { format, formatDistanceToNow, isBefore, subMonths } from "date-fns";
import { Fragment, useState } from "react";
import { useAppSelector } from "src/hooks/stateHooks";
import { getFormattedTime } from "src/lib/misc";
import { getWeightUnit } from "src/lib/miscUtility";
import { workoutLib } from "src/lib/trainwellWorkoutLib";
import { useGetExerciseProgressQuery } from "src/slices/api/clientApi";
import { useGetSetHistoryQuery } from "src/slices/api/setHistoryApi";
import { selectBestSet } from "src/slices/clientSlice";
import SetHistoryCell from "./SetHistoryCell";

type Props = {
  exerciseName: string;
  exerciseId: string;
  userId: string;
  weightSystem: "imperial" | "metric";
  exerciseSource: ExerciseSource;
};

export default function SetHistoryButton({
  exerciseName,
  exerciseId,
  userId,
  weightSystem,
  exerciseSource,
}: Props) {
  const { data: exerciseProgressData } = useGetExerciseProgressQuery(
    {
      userId: userId,
      exerciseId: exerciseSource?.id ?? "",
    },
    { skip: !exerciseSource?.id },
  );
  const [anchorEl, setAnchorEl] = useState<HTMLButtonElement | null>(null);
  const { data: recentSets } = useGetSetHistoryQuery({
    userId: userId,
    exerciseId: exerciseId,
  });
  const theme = useTheme();
  const [timeFrame, setTimeFrame] = useState("2_mo");
  const bestSet = useAppSelector((state) =>
    selectBestSet(state, userId, exerciseId),
  );
  const estimatedOrm = useAppSelector((state) =>
    state.client.client?.orms_estimated
      ? state.client.client?.orms_estimated[exerciseId]
      : undefined,
  );
  const open = Boolean(anchorEl);

  function capitalizeFirstLetter(string: string) {
    return string.charAt(0).toUpperCase() + string.slice(1);
  }

  function round(number: number) {
    return Math.round(number * 10) / 10;
  }

  const filteredHistory =
    exerciseProgressData?.history
      .filter((record) => record.estimated_orm)
      .map((h) => {
        return {
          ...h,
          estimated_orm: round(h.estimated_orm ?? 0),
        };
      }) ?? [];
  let historyWithinRange = [...filteredHistory];

  const monthsAgo2 = subMonths(new Date(), 2);
  const history2months = filteredHistory.filter(
    (record) => !isBefore(new Date(record.date), monthsAgo2),
  );

  const monthsAgo6 = subMonths(new Date(), 6);
  const history6months = filteredHistory.filter(
    (record) => !isBefore(new Date(record.date), monthsAgo6),
  );

  const monthsAgo12 = subMonths(new Date(), 12);
  const history12months = filteredHistory.filter(
    (record) => !isBefore(new Date(record.date), monthsAgo12),
  );

  if (timeFrame === "12_mo") {
    historyWithinRange = [...history12months];
  } else if (timeFrame === "6_mo") {
    historyWithinRange = [...history6months];
  } else if (timeFrame === "2_mo") {
    historyWithinRange = [...history2months];
  }

  if (
    historyWithinRange.length === 0 &&
    filteredHistory.length !== 0 &&
    timeFrame !== "all"
  ) {
    historyWithinRange = [...filteredHistory];
    setTimeFrame("all");
  }

  const data = historyWithinRange.map((record) => ({
    time: new Date(record.date).getTime(),
    orm: record.estimated_orm,
  }));

  let color = theme.palette.text.secondary;

  if (data.length > 1) {
    if (data.at(-1)!.orm > data.at(0)!.orm + 2) {
      color = theme.palette.success.main;
    }

    if (data.at(-1)!.orm < data.at(0)!.orm - 2) {
      color = theme.palette.error.main;
    }
  }

  const smallData = history2months.map((record) => ({
    time: new Date(record.date).getTime(),
    orm: record.estimated_orm,
  }));

  let smallColor = theme.palette.text.secondary;

  if (smallData.length > 1) {
    if (smallData.at(-1)!.orm > smallData.at(0)!.orm + 2) {
      smallColor = theme.palette.success.main;
    }

    if (smallData.at(-1)!.orm < smallData.at(0)!.orm - 2) {
      smallColor = theme.palette.error.main;
    }
  }

  const noHistory = !bestSet && (!recentSets || recentSets.length === 0);

  return (
    <>
      <CardActionArea
        sx={{ p: 0.25, borderRadius: 1, width: "auto", mr: 0.5 }}
        onClick={(event) => {
          event.stopPropagation();
          setAnchorEl(event.currentTarget);
        }}
        onMouseDown={(event) => {
          event.stopPropagation();
        }}
        autoFocus={false}
        onFocus={(event) => {
          event.stopPropagation();
        }}
      >
        {smallData.length > 1 ? (
          <ChartContainer
            dataset={smallData}
            height={30}
            width={25}
            colors={[smallColor]}
            xAxis={[
              {
                dataKey: "time",
                valueFormatter: (value) =>
                  format(value as number, "MMM d, yyy"),
                label: "Time",
                hideTooltip: true,
                min: Math.min(...smallData.map((d) => d.time)),
                max: Math.max(...smallData.map((d) => d.time)),
              },
            ]}
            yAxis={[
              {
                min: Math.max(Math.min(...smallData.map((d) => d.orm)) - 2, 0),
                max: Math.max(...smallData.map((d) => d.orm)) + 2,
              },
            ]}
            series={[
              {
                type: "line",
                dataKey: "orm",
                label: "orm",
                showMark: false,
                disableHighlight: true,
              },
            ]}
            margin={{ top: 0, right: 0, bottom: 0, left: 0 }}
            disableAxisListener
          >
            <LinePlot skipAnimation />
          </ChartContainer>
        ) : (
          <HistoryRoundedIcon
            sx={{
              minWidth: "25px",
              color: (theme) =>
                noHistory ? theme.palette.text.disabled : undefined,
            }}
          />
        )}
      </CardActionArea>
      <Popover
        open={open}
        anchorEl={anchorEl}
        onClose={(event) => {
          if ("stopPropagation" in event) {
            (event as any).stopPropagation();
          }

          setAnchorEl(null);
        }}
        disableAutoFocus
        disableRestoreFocus
        anchorOrigin={{
          vertical: "center",
          horizontal: "left",
        }}
        transformOrigin={{
          vertical: "center",
          horizontal: "right",
        }}
        slotProps={{
          paper: {
            sx: {
              p: 2,
              minWidth: "300px",
            },
          },
        }}
      >
        <Typography variant="h2">{exerciseName}</Typography>
        {bestSet && (
          <Typography sx={{ mb: 2 }}>
            Est max:{" "}
            <b>
              {workoutLib.sets.getEstimatedMaxString(
                exerciseSource,
                bestSet.estimated_orm,
                weightSystem,
              )}
            </b>
          </Typography>
        )}
        {bestSet ? (
          <Box
            sx={{
              backgroundColor: (theme) =>
                alpha(theme.palette.success.main, 0.05),
              border: 1,
              borderColor: (theme) => theme.palette.success.main,
              borderRadius: 1,
              mx: -1,
              p: 1,
            }}
          >
            <Box sx={{ display: "flex", alignItems: "center" }}>
              <StarRoundedIcon fontSize="inherit" />
              <Typography sx={{ fontWeight: "bold", ml: 1 }}>
                Best set{" "}
                <Typography
                  component={"span"}
                  variant="body2"
                  sx={{
                    display: "inline",
                    color: (theme) => theme.palette.text.secondary,
                  }}
                >
                  ({formatDistanceToNow(new Date(bestSet.set_end_date))} ago)
                </Typography>
              </Typography>
            </Box>
            <SetHistoryCell set={bestSet} weightSystem={weightSystem} />
          </Box>
        ) : (
          <Box>
            <Typography sx={{ textAlign: "center", mb: 2 }}>
              Est max:{" "}
              {estimatedOrm
                ? workoutLib.sets.getEstimatedMaxString(
                    exerciseSource,
                    estimatedOrm,
                    weightSystem,
                  )
                : "none"}
            </Typography>
            <Typography>No set history</Typography>
          </Box>
        )}
        {recentSets &&
          recentSets.map((setGroup) => (
            <Fragment key={setGroup.date}>
              <Typography
                variant="body2"
                sx={{
                  mt: 2,
                  color: (theme) => theme.palette.text.secondary,
                }}
              >
                {capitalizeFirstLetter(
                  formatDistanceToNow(new Date(setGroup.date)),
                )}{" "}
                ago
              </Typography>
              {setGroup.sets.map((set, i) => (
                <SetHistoryCell set={set} key={i} weightSystem={weightSystem} />
              ))}
            </Fragment>
          ))}
        {data.length > 1 && (
          <Box sx={{ mt: 2 }}>
            <Box sx={{ display: "flex", justifyContent: "center" }}>
              <ToggleButtonGroup
                color="primary"
                value={timeFrame}
                exclusive
                onChange={(e, newTimeFrame) => {
                  setTimeFrame(newTimeFrame);
                }}
                onClick={(event) => {
                  event.stopPropagation();
                }}
                aria-label="Time frame"
                sx={{ mb: 1 }}
                size="small"
              >
                <ToggleButton value="all">All</ToggleButton>
                <ToggleButton
                  value="2_mo"
                  disabled={history2months.length === 0}
                >
                  2 mo
                </ToggleButton>
                <ToggleButton
                  value="6_mo"
                  disabled={history6months.length === 0}
                >
                  6 mo
                </ToggleButton>
                <ToggleButton
                  value="12_mo"
                  disabled={history12months.length === 0}
                >
                  12 mo
                </ToggleButton>
              </ToggleButtonGroup>
            </Box>
            <LineChart
              colors={[color]}
              xAxis={[
                {
                  dataKey: "time",
                  valueFormatter: (value) =>
                    format(value as number, "MMM d, yyy"),
                  hideTooltip: true,
                  min: Math.min(...data.map((d) => d.time)),
                },
              ]}
              yAxis={[
                {
                  min: Math.max(Math.min(...data.map((d) => d.orm)) - 2, 0),
                  max: Math.max(...data.map((d) => d.orm)) + 2,
                },
              ]}
              series={[
                {
                  dataKey: "orm",
                  label: "orm",
                  showMark: false,
                },
              ]}
              dataset={data}
              height={200}
              margin={{ top: 45, right: 10, left: 35, bottom: 20 }}
              skipAnimation
              slots={{
                axisContent: ({ dataIndex }) => {
                  const point = data[dataIndex as number];

                  const set = historyWithinRange[dataIndex as number];

                  return (
                    <Card variant="outlined" sx={{ py: 1, ml: 2 }}>
                      <Box
                        sx={{ display: "flex", alignItems: "center", px: 2 }}
                      >
                        <Box
                          sx={{
                            width: theme.spacing(1),
                            height: theme.spacing(1),
                            borderRadius: "50%",
                            backgroundColor: color,
                            borderColor: theme.palette.background.paper,
                            border: `solid ${theme.palette.background.paper} ${theme.spacing(0.25)}`,
                            boxSizing: "content-box",
                          }}
                        />
                        <Box sx={{ ml: 1 }}>
                          <Typography sx={{ fontWeight: "bold" }}>
                            orm:{" "}
                            {workoutLib.sets.getEstimatedMaxString(
                              exerciseSource,
                              point.orm as number,
                              weightSystem,
                            )}
                          </Typography>
                          <Typography variant="overline">
                            {format(new Date(set.date), "MMM d, yyyy")}
                          </Typography>
                        </Box>
                      </Box>
                      <Divider sx={{ my: 1 }} />
                      <Box sx={{ px: 2 }}>
                        {(set.weight || set.weight === 0) && (
                          <Typography>
                            {`Weight: ${getConvertedWeight({
                              weight: set.weight,
                              fromSystem: "imperial",
                              toSystem: weightSystem,
                              round: true,
                            })} ${getWeightUnit(weightSystem)}`}
                          </Typography>
                        )}
                        {(set.reps || set.reps === 0) && (
                          <Typography>{`Reps: ${set.reps}`}</Typography>
                        )}
                        {(set.time || set.time === 0) && (
                          <Typography>{`Time: ${getFormattedTime(set.time)}`}</Typography>
                        )}
                      </Box>
                    </Card>
                  );
                },
              }}
            />
          </Box>
        )}
      </Popover>
    </>
  );
}

import {
  attachClosestEdge,
  extractClosestEdge,
  type Edge,
} from "@atlaskit/pragmatic-drag-and-drop-hitbox/closest-edge";
import { combine } from "@atlaskit/pragmatic-drag-and-drop/combine";
import {
  draggable,
  dropTargetForElements,
} from "@atlaskit/pragmatic-drag-and-drop/element/adapter";
import { pointerOutsideOfPreview } from "@atlaskit/pragmatic-drag-and-drop/element/pointer-outside-of-preview";
import { setCustomNativeDragPreview } from "@atlaskit/pragmatic-drag-and-drop/element/set-custom-native-drag-preview";
import AccessTimeRoundedIcon from "@mui/icons-material/AccessTimeRounded";
import BlockRoundedIcon from "@mui/icons-material/BlockRounded";
import ChatBubbleRoundedIcon from "@mui/icons-material/ChatBubbleRounded";
import CheckRoundedIcon from "@mui/icons-material/CheckRounded";
import ContentCopyRoundedIcon from "@mui/icons-material/ContentCopyRounded";
import DeleteRoundedIcon from "@mui/icons-material/DeleteRounded";
import EmojiEventsRoundedIcon from "@mui/icons-material/EmojiEventsRounded";
import MoreVertRoundedIcon from "@mui/icons-material/MoreVertRounded";
import TouchAppRoundedIcon from "@mui/icons-material/TouchAppRounded";
import UndoRoundedIcon from "@mui/icons-material/UndoRounded";
import WatchRoundedIcon from "@mui/icons-material/WatchRounded";
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Box,
  Checkbox,
  Chip,
  IconButton,
  ListItemIcon,
  ListItemText,
  Menu,
  MenuItem,
  Stack,
  ToggleButton,
  ToggleButtonGroup,
  Tooltip,
  Typography,
  alpha,
} from "@mui/material";
import type { EquipmentObject } from "@trainwell/features/legacy";
import { EquipmentType } from "@trainwell/features/legacy";
import { Fragment, memo, useEffect, useMemo, useRef, useState } from "react";
import { createPortal } from "react-dom";
import { shallowEqual } from "react-redux";
import { useParams } from "react-router";
import { DragPreview } from "src/components/common/DragPreview";
import { DropIndicator } from "src/components/common/DropIndicator";
import ExerciseIcon from "src/components/misc/ExerciseIcon";
import TrackingStatus from "src/components/misc/TrackingStatus";
import MilestonePopover from "src/features/client/name-tag/MilestonePopover";
import { useAppDispatch, useAppSelector } from "src/hooks/stateHooks";
import { getExerciseById, getExercisesByGroupId } from "src/lib/exercises";
import {
  getExerciseDisplayName,
  getExerciseIconURL,
} from "src/lib/mediaUtility";
import { logApi } from "src/slices/api/logApi";
import {
  addToExerciseBlacklist,
  removeFromExerciseBlacklist,
} from "src/slices/clientSlice";
import {
  copyExercise,
  deleteExercise,
  deselectExercise,
  selectDenormalizedWorkout,
  selectEveryExerciseWithID,
  selectExercise,
  selectExerciseById,
  selectExerciseHasNotes,
  setEquipmentWarningDialog,
  setExerciseExpanded,
  toggleEquipment,
  toggleManualAndAuto,
  toggleTimedAndReps,
} from "src/slices/workoutSlice";
import ExerciseName from "./ExerciseName";
import ExerciseStats from "./ExerciseStats";
import { ExerciseSwapPopover } from "./ExerciseSwapPopover";
import SetCell from "./SetCell";
import SetHistoryButton from "./SetHistoryButton";
import { WorkoutAlertChip } from "./WorkoutAlertChip";

const emptyArray = [];

type Props = PropsDraggable & {
  isDragging: boolean;
  dragHandleRef: React.RefObject<HTMLDivElement | null>;
};

const ExerciseCell = memo(function ExerciseCell({
  exerciseId,
  isDragging,
  sectionId,
  exerciseIndex,
  dragHandleRef,
}: Props) {
  const { logId } = useParams<{ logId: string | undefined }>();
  const dispatch = useAppDispatch();
  const client = useAppSelector((state) => state.client.client);
  const exercise = useAppSelector((state) =>
    selectExerciseById(state, exerciseId),
  );
  const selectedExercises = useAppSelector(
    (state) => state.workout.selectedExercises,
  );
  const isTemplate = useAppSelector((state) => state.workout.isTemplate);
  const hasNotes = useAppSelector((state) =>
    selectExerciseHasNotes(state, exerciseId),
  );
  // const milestonesQuery = useMilestones({ userId: client?.user_id ?? "" });
  // const hasMilestoneForThisExercise = milestonesQuery.data?.some(
  //   (milestone) =>
  //     !milestone.date_achieved &&
  //     milestone.type === "exercise" &&
  //     milestone.content.exercise_master_id === exerciseId,
  // );
  // const showMilestones = false && !hasMilestoneForThisExercise;
  const showMilestones = false;
  const [milestonePopoverOpen, setMilestonePopoverOpen] = useState(false);
  const [moreAnchorEl, setMoreAnchorEl] = useState<null | HTMLButtonElement>(
    null,
  );
  const [contextMenu, setContextMenu] = useState<{
    mouseX: number;
    mouseY: number;
  } | null>(null);

  const exerciseAlerts = useAppSelector((state) => {
    if (!logId) {
      return null;
    }

    const updatesSelector = logApi.endpoints.getWorkoutLogAlerts.select(logId);
    const updates = updatesSelector(state).data?.workout_log_alerts;

    if (!updates) {
      return null;
    }

    const filteredUpdates = updates.filter(
      (u) => u.trigger.scope === "exercise",
    );

    const workout = selectDenormalizedWorkout(state);

    const setIds =
      workout.sections
        .find((section) => section.section_id === sectionId)
        ?.cycles.map((cycle) => {
          return cycle.exercises[exerciseIndex].sets.map((set) => set.set_id);
        })
        .flat() ?? emptyArray;

    const relevantUpdates = filteredUpdates.filter((update) => {
      return update.trigger.triggered_sets.some((triggeredSet) =>
        setIds.includes(triggeredSet.set_id),
      );
    });

    return relevantUpdates;
  }, shallowEqual);

  const hasSomethingNotable = useAppSelector((state) => {
    if (!logId) {
      return false;
    }

    const logSelector = logApi.endpoints.getLog.select(logId);
    const log = logSelector(state).data;

    if (!log) {
      return false;
    }

    const hasSomethingNotable = log.sections
      .find((section) => section.section_id === sectionId)
      ?.cycles.some((cycle) =>
        cycle.exercises
          .find((exercise) => exercise.exercise_id === exerciseId)
          ?.sets.some(
            (set) => set.is_pr_new || set.user_feedback?.chips?.length,
          ),
      );

    return hasSomethingNotable;
  });

  const expanded = useMemo(
    () =>
      exercise?.expanded ??
      (logId ? (exerciseAlerts ?? []).length > 0 || hasSomethingNotable : true),
    [exercise?.expanded, Boolean(exerciseAlerts === null), hasSomethingNotable],
  );

  const exerciseSource = getExerciseById(exercise?.exercise_master_id ?? "");
  const exerciseVariants = getExercisesByGroupId(
    exerciseSource?.picker_group_id ?? "",
  );

  const equipmentPickerIds = useMemo(() => {
    const ids = [
      ...new Set(
        exerciseVariants?.map(
          (variant) => variant.picker_equipment_selection_id,
        ),
      ),
    ];

    if (!isTemplate) {
      ids.sort((a, b) => {
        const hasA = !(
          !client?.equipment_detailed[a as keyof EquipmentObject] &&
          a !== "plate" &&
          !(a === "ezbar" && client?.equipment_detailed.ez_curl_bar) &&
          a !== "bodyweight"
        );

        const hasB = !(
          !client?.equipment_detailed[b as keyof EquipmentObject] &&
          b !== "plate" &&
          !(b === "ezbar" && client?.equipment_detailed.ez_curl_bar) &&
          b !== "bodyweight"
        );

        return hasA === hasB ? a.localeCompare(b) : hasA ? -1 : 1;
      });
    } else {
      ids.sort();
    }

    return ids;
  }, [isTemplate, exerciseVariants, client]);

  if (!exerciseSource) {
    console.log("Missing exercise id: " + exercise?.exercise_master_id);
  }

  if (!exercise) {
    return <Typography>Finding exercise</Typography>;
  }

  const isSelected = selectedExercises.includes(exercise.exercise_id);

  if (!exerciseSource) {
    return (
      <Typography>
        Error: Could not find exercise in library. id=
        {exercise.exercise_master_id}
      </Typography>
    );
  }

  const isBlacklisted = client?.blacklisted_exercises?.includes(
    exercise.exercise_master_id,
  );
  const isGroupBlacklisted = exerciseVariants?.every((variant) =>
    client?.blacklisted_exercises?.includes(variant.id),
  );
  const isAnyGroupBlacklisted = exerciseVariants?.some((variant) =>
    client?.blacklisted_exercises?.includes(variant.id),
  );

  return (
    <Fragment>
      <Accordion
        disableGutters
        elevation={0}
        sx={{
          backgroundColor: (theme) =>
            isSelected
              ? alpha(theme.palette.primary.main, 0.2)
              : theme.palette.background.paper,
          py: 0.25,
          opacity: isDragging ? 0.4 : 1,
        }}
        slotProps={{ transition: { timeout: 0, unmountOnExit: true } }}
        expanded={expanded}
        onChange={() => {
          dispatch(setExerciseExpanded({ exerciseId, expanded: !expanded }));
        }}
        square={true}
        onContextMenu={(event) => {
          event.preventDefault();
          // Todo uncomment this
          setContextMenu(
            contextMenu === null
              ? {
                  mouseX: event.clientX + 2,
                  mouseY: event.clientY - 6,
                }
              : // repeated contextmenu when it is already open closes it with Chrome 84 on Ubuntu
                // Other native context menus might behave different.
                // With this behavior we prevent contextmenu from the backdrop to re-locale existing context menus.
                null,
          );
        }}
        id={exerciseId}
      >
        <AccordionSummary
          ref={dragHandleRef}
          sx={{
            px: 0.5,
            minHeight: "32px",
            "& .MuiAccordionSummary-content": {
              my: expanded ? 0 : 0.5,
            },
          }}
        >
          <Box sx={{ display: "flex", alignItems: "center" }}>
            {/* <IconButton
              size="small"
              color="default"
              onClick={(event) => {
                event.stopPropagation();
              }}
            >
              <DragIndicatorRoundedIcon fontSize="small" />
            </IconButton> */}
            <Checkbox
              sx={{ p: "5px" }}
              size="small"
              name="exerciseCell.checkbox"
              checked={isSelected}
              onClick={(e) => {
                e.stopPropagation();

                if (e.shiftKey === true) {
                  dispatch(selectEveryExerciseWithID(exercise.exercise_id));
                } else {
                  if (isSelected) {
                    dispatch(deselectExercise(exercise.exercise_id));
                  } else {
                    if (isSelected) {
                      dispatch(deselectExercise(exercise.exercise_id));
                    } else {
                      dispatch(selectExercise(exercise.exercise_id));
                    }
                  }
                }
              }}
            />
            {!isTemplate && (
              <SetHistoryButton
                exerciseName={exerciseSource.key_readable_copilot}
                exerciseId={exercise.exercise_master_id}
                userId={client?.user_id ?? ""}
                weightSystem={client?.preferred_weight_system ?? "imperial"}
                exerciseSource={exerciseSource}
              />
            )}
            <ExerciseIcon exerciseMasterId={exercise.exercise_master_id} />
            <Box sx={{ display: "flex", alignItems: "center" }}>
              <Box sx={{ mr: 0.5, ml: 1 }}>
                <Box sx={{ display: "flex", alignItems: "center" }}>
                  <ExerciseName exerciseId={exerciseId} />
                  <TrackingStatus
                    exerciseMasterId={exercise.exercise_master_id}
                    sx={{ ml: 1 }}
                  />
                </Box>
                {!isTemplate && expanded && (
                  <ExerciseStats
                    exerciseMasterId={exercise.exercise_master_id}
                  />
                )}
              </Box>
              {hasNotes && !expanded && (
                <Tooltip title="Some sets have notes" disableInteractive>
                  <ChatBubbleRoundedIcon sx={{ ml: 1 }} fontSize="small" />
                </Tooltip>
              )}
            </Box>
          </Box>
          <Box sx={{ display: "flex", alignItems: "center" }}>
            {(exerciseSource.timed_variant_id ||
              exerciseSource.reps_variant_id) && (
              <ToggleButtonGroup
                color="primary"
                value={
                  exerciseSource.timed_variant_id === null ? "timed" : "tracked"
                }
                exclusive
                onChange={(e) => {
                  e.stopPropagation();

                  dispatch(
                    toggleTimedAndReps({
                      exerciseId: exerciseId,
                      client: client!,
                    }),
                  );
                }}
                aria-label="exercise variant"
                sx={{
                  mr: 1,
                }}
              >
                <ToggleButton value="timed" aria-label="timed" sx={{ p: 0.5 }}>
                  <Tooltip title="Timed" enterDelay={500} disableInteractive>
                    <AccessTimeRoundedIcon fontSize="small" />
                  </Tooltip>
                </ToggleButton>
                <ToggleButton
                  value="tracked"
                  aria-label="tracked"
                  sx={{ p: 0.5 }}
                >
                  <Tooltip title="Reps" enterDelay={500} disableInteractive>
                    <WatchRoundedIcon fontSize="small" />
                  </Tooltip>
                </ToggleButton>
              </ToggleButtonGroup>
            )}
            {(exerciseSource.quick_variant_id ||
              exerciseSource.not_quick_variant_id) && (
              <ToggleButtonGroup
                color="primary"
                value={
                  exerciseSource.quick_variant_id === null
                    ? "quick"
                    : "not_quick"
                }
                exclusive
                // size="small"
                onChange={(e) => {
                  e.stopPropagation();

                  dispatch(
                    toggleManualAndAuto({
                      exerciseId: exerciseId,
                      client: client!,
                    }),
                  );
                }}
                aria-label="exercise variant"
                sx={{
                  mr: 1,
                }}
              >
                <ToggleButton
                  value="not_quick"
                  aria-label="not quick"
                  sx={{ p: 0.5 }}
                >
                  <Tooltip
                    title="Manual end"
                    enterDelay={500}
                    disableInteractive
                  >
                    <TouchAppRoundedIcon fontSize="small" />
                  </Tooltip>
                </ToggleButton>
                <ToggleButton value="quick" aria-label="quick" sx={{ p: 0.5 }}>
                  <Tooltip title="Auto end" enterDelay={500} disableInteractive>
                    <AccessTimeRoundedIcon fontSize="small" />
                  </Tooltip>
                </ToggleButton>
              </ToggleButtonGroup>
            )}
            <IconButton
              aria-label="copy"
              onClick={(event) => {
                event.stopPropagation();

                dispatch(copyExercise(exerciseId));
              }}
              name="exerciseCell.copy"
              size="small"
            >
              <ContentCopyRoundedIcon fontSize="small" />
            </IconButton>
            {!isTemplate && (
              <IconButton
                aria-label="more"
                onClick={(event) => {
                  event.stopPropagation();

                  setMoreAnchorEl(event.currentTarget);
                }}
                name="exerciseCell.more"
                size="small"
              >
                <MoreVertRoundedIcon fontSize="small" />
              </IconButton>
            )}
            <IconButton
              aria-label="delete"
              onClick={(event) => {
                event.stopPropagation();

                dispatch(deleteExercise(exerciseId));
              }}
              name="exerciseCell.delete"
              size="small"
            >
              <DeleteRoundedIcon fontSize="small" />
            </IconButton>
          </Box>
        </AccordionSummary>
        <AccordionDetails sx={{ p: 0, pb: 2 }}>
          <Stack
            direction={"row"}
            spacing={2}
            alignItems={"center"}
            sx={{ mb: 0.5, ml: "102px" }}
          >
            {equipmentPickerIds.length >= 2 && (
              <Box
                sx={{
                  display: "flex",
                  pt: 1,
                  mb: 0.5,
                  flexWrap: "wrap",
                  gap: 0.5,
                }}
              >
                {equipmentPickerIds.map((equipmentPickerId) => {
                  const clientMissingEquipment =
                    !isTemplate &&
                    !client?.equipment_detailed[equipmentPickerId] &&
                    equipmentPickerId !== "plate" &&
                    !(
                      equipmentPickerId === "ezbar" &&
                      client?.equipment_detailed.ez_curl_bar
                    ) &&
                    equipmentPickerId !== "bodyweight";

                  return (
                    <Chip
                      key={equipmentPickerId}
                      size="small"
                      color={
                        exerciseSource.picker_equipment_selection_id ===
                          equipmentPickerId && clientMissingEquipment
                          ? "warningSurface"
                          : exerciseSource.picker_equipment_selection_id !==
                              equipmentPickerId
                            ? undefined
                            : "blueSurface"
                      }
                      variant={
                        exerciseSource.picker_equipment_selection_id ===
                        equipmentPickerId
                          ? "filled"
                          : clientMissingEquipment
                            ? "outlined"
                            : "outlined"
                      }
                      sx={{
                        borderStyle:
                          exerciseSource.picker_equipment_selection_id ===
                          equipmentPickerId
                            ? undefined
                            : clientMissingEquipment
                              ? "dashed"
                              : undefined,
                      }}
                      onClick={() => {
                        dispatch(
                          toggleEquipment({
                            exerciseId: exerciseId,
                            equipmentPickerId: equipmentPickerId,
                          }),
                        )
                          .unwrap()
                          .catch(() => {
                            dispatch(
                              setEquipmentWarningDialog({
                                exerciseId: exerciseId,
                                equipmentPickerId: equipmentPickerId,
                              }),
                            );
                          });
                      }}
                      label={
                        equipmentPickerId === "plate"
                          ? "Plate"
                          : equipmentPickerId === "ezbar"
                            ? "EZ bar"
                            : equipmentPickerId === "bodyweight"
                              ? "Bodyweight"
                              : EquipmentType[equipmentPickerId]
                      }
                      icon={
                        exerciseSource.picker_equipment_selection_id ===
                        equipmentPickerId ? (
                          <CheckRoundedIcon />
                        ) : undefined
                      }
                    />
                  );
                })}
              </Box>
            )}
          </Stack>
          <Stack
            direction={"column"}
            alignItems={"flex-start"}
            sx={{ mb: 2, ml: "73px" }}
            spacing={1}
          >
            {(exerciseAlerts ?? []).map((update) => (
              <WorkoutAlertChip update={update} key={update._id} />
            ))}
          </Stack>
          <Stack direction={"column"} sx={{ pl: 6 }} spacing={0.25}>
            {exercise.sets.map((setId, index) => (
              <SetCell
                key={setId}
                setId={setId}
                exerciseId={exerciseId}
                sectionId={sectionId}
                exerciseMasterID={exercise.exercise_master_id}
                setIndex={index}
                isLastSet={exercise.sets.length === 1}
              />
            ))}
          </Stack>
        </AccordionDetails>
      </Accordion>
      {moreAnchorEl && milestonePopoverOpen && (
        <MilestonePopover
          anchorEl={milestonePopoverOpen ? moreAnchorEl : null}
          onClose={() => {
            setMilestonePopoverOpen(false);
            setMoreAnchorEl(null);
          }}
          userId={client?.user_id ?? ""}
          defaultExerciseMasterId={exercise.exercise_master_id}
        />
      )}
      {moreAnchorEl && !milestonePopoverOpen && (
        <Menu
          open={Boolean(moreAnchorEl) && !milestonePopoverOpen}
          anchorEl={moreAnchorEl}
          anchorOrigin={{
            vertical: "bottom",
            horizontal: "left",
          }}
          transformOrigin={{
            vertical: "top",
            horizontal: "left",
          }}
          onClose={() => {
            setMoreAnchorEl(null);
          }}
        >
          {showMilestones && (
            <MenuItem
              onClick={() => {
                setMilestonePopoverOpen(true);
              }}
            >
              <ListItemIcon>
                <EmojiEventsRoundedIcon fontSize="small" />
              </ListItemIcon>
              <ListItemText>Add milestone</ListItemText>
            </MenuItem>
          )}
          {isBlacklisted && (
            <MenuItem
              onClick={() => {
                dispatch(
                  removeFromExerciseBlacklist([exercise.exercise_master_id]),
                );

                setMoreAnchorEl(null);
              }}
            >
              <ListItemIcon>
                <UndoRoundedIcon fontSize="small" />
              </ListItemIcon>
              <ListItemText>Unblock</ListItemText>
            </MenuItem>
          )}
          {isAnyGroupBlacklisted && (
            <MenuItem
              onClick={() => {
                dispatch(
                  removeFromExerciseBlacklist(
                    exerciseVariants?.map((variant) => variant.id) ?? [],
                  ),
                );

                setMoreAnchorEl(null);
              }}
            >
              <ListItemIcon>
                <UndoRoundedIcon fontSize="small" />
              </ListItemIcon>
              <ListItemText>Unblock entire movement</ListItemText>
            </MenuItem>
          )}
          {!isBlacklisted && (
            <MenuItem
              onClick={() => {
                dispatch(addToExerciseBlacklist([exercise.exercise_master_id]));

                setMoreAnchorEl(null);
              }}
            >
              <ListItemIcon>
                <BlockRoundedIcon fontSize="small" color="error" />
              </ListItemIcon>
              <ListItemText
                sx={{
                  color: (theme) => theme.palette.error.main,
                }}
              >
                Block
              </ListItemText>
            </MenuItem>
          )}
          {!isGroupBlacklisted && (
            <MenuItem
              onClick={() => {
                dispatch(
                  addToExerciseBlacklist(
                    exerciseVariants?.map((variant) => variant.id) ?? [],
                  ),
                );

                setMoreAnchorEl(null);
              }}
            >
              <ListItemIcon>
                <BlockRoundedIcon fontSize="small" color="error" />
              </ListItemIcon>
              <ListItemText
                sx={{
                  color: (theme) => theme.palette.error.main,
                }}
              >
                Block entire movement
              </ListItemText>
            </MenuItem>
          )}
        </Menu>
      )}
      <ExerciseSwapPopover
        position={contextMenu}
        exerciseId={exerciseId}
        onClose={() => {
          setContextMenu(null);
        }}
      />
    </Fragment>
  );
});

type PropsDraggable = {
  exerciseId: string;
  sectionId: string;
  exerciseIndex: number;
};

function ExerciseCellDraggable(props: PropsDraggable) {
  const ref = useRef(null);
  const dragHandleRef = useRef<HTMLDivElement>(null);
  const [dragging, setDragging] = useState<boolean>(false);
  const [closestEdge, setClosestEdge] = useState<Edge | null>(null);
  const [previewContainer, setPreviewContainer] = useState<HTMLElement | null>(
    null,
  );

  const exercise = useAppSelector((state) =>
    selectExerciseById(state, props.exerciseId),
  );

  useEffect(() => {
    const element = ref.current;
    const dragHandle = dragHandleRef.current;
    const index = props.exerciseIndex;

    if (!element || !dragHandle) {
      return;
    }

    const data = {
      type: "exercise",
      exerciseId: props.exerciseId,
      sectionId: props.sectionId,
      index: index,
    };

    return combine(
      draggable({
        element: dragHandle,
        getInitialData: () => data,
        onDragStart: () => setDragging(true),
        onDrop: () => setDragging(false),
        onGenerateDragPreview({ nativeSetDragImage }) {
          setCustomNativeDragPreview({
            nativeSetDragImage,
            getOffset: pointerOutsideOfPreview({
              x: "16px",
              y: "8px",
            }),
            render({ container }) {
              setPreviewContainer(container);
            },
          });
        },
      }),
      dropTargetForElements({
        element,
        canDrop({ source }) {
          return (
            source.data.type === "exercise" ||
            source.data.type === "exercise_source"
          );
        },
        getData({ input }) {
          return attachClosestEdge(data, {
            element,
            input,
            allowedEdges: ["top", "bottom"],
          });
        },
        onDrag({ self, source }) {
          const isSource =
            source.element === element || source.element === dragHandle;
          if (isSource) {
            setClosestEdge(null);
            return;
          }

          const closestEdge = extractClosestEdge(self.data);

          const sourceIndex = source.data.index;
          if (typeof sourceIndex !== "number") {
            setClosestEdge(closestEdge);

            return;
          }

          if (source.data.sectionId !== props.sectionId) {
            setClosestEdge(closestEdge);

            return;
          }

          const isItemBeforeSource = index === sourceIndex - 1;
          const isItemAfterSource = index === sourceIndex + 1;

          const isDropIndicatorHidden =
            (isItemBeforeSource && closestEdge === "bottom") ||
            (isItemAfterSource && closestEdge === "top");

          if (isDropIndicatorHidden) {
            setClosestEdge(null);
            return;
          }

          setClosestEdge(closestEdge);
        },
        onDragLeave() {
          setClosestEdge(null);
        },
        onDrop() {
          setClosestEdge(null);
        },
      }),
    );
  }, [props.exerciseId, props.exerciseIndex, props.sectionId]);

  return (
    <>
      <div
        style={{
          position: "relative",
        }}
      >
        <div ref={ref}>
          <ExerciseCell
            isDragging={dragging}
            dragHandleRef={dragHandleRef}
            {...props}
          />
        </div>
        {closestEdge && <DropIndicator edge={closestEdge} gap="0px" />}
      </div>
      {previewContainer
        ? createPortal(
            <DragPreview
              text={getExerciseDisplayName(exercise?.exercise_master_id ?? "")}
              icon={
                <img
                  style={{
                    borderRadius: "4px",
                  }}
                  src={
                    getExerciseIconURL(exercise?.exercise_master_id ?? "") ?? ""
                  }
                  alt={exercise?.exercise_master_id}
                  width={30}
                  height={30}
                />
              }
            />,
            previewContainer,
          )
        : null}
    </>
  );
}

export default ExerciseCellDraggable;

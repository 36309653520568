import ThumbUpRoundedIcon from "@mui/icons-material/ThumbUpRounded";
import WarningRoundedIcon from "@mui/icons-material/WarningRounded";
import { Chip } from "@mui/material";
import type { LogSet, SetDetailIdentifier } from "@trainwell/features/legacy";

type Props = {
  setLog: LogSet;
  badStuff?: (SetDetailIdentifier | undefined)[] | string[];
};

export function SetLogDifficultyChip({ setLog, badStuff }: Props) {
  if (setLog.rir === "0_to_2") {
    return (
      <Chip
        color={badStuff?.includes("difficulty") ? "error" : "default"}
        size="small"
        icon={<ThumbUpRoundedIcon />}
        label={"0-2 RiR"}
        sx={{
          fontSize: 10,
          "& .MuiChip-iconSmall": {
            fontSize: 14,
          },
        }}
      />
    );
  } else if (setLog.rir === "not_finished") {
    return (
      <Chip
        color={badStuff?.includes("difficulty") ? "error" : "default"}
        size="small"
        icon={<WarningRoundedIcon />}
        label={"Didn't finish"}
        sx={{
          fontSize: 10,
          "& .MuiChip-iconSmall": {
            fontSize: 14,
          },
        }}
      />
    );
  } else if (setLog.rir === "3+") {
    return (
      <Chip
        color={badStuff?.includes("difficulty") ? "error" : "default"}
        size="small"
        icon={<WarningRoundedIcon />}
        label={"3+ RiR"}
        sx={{
          fontSize: 10,
          "& .MuiChip-iconSmall": {
            fontSize: 14,
          },
        }}
      />
    );
  } else if (setLog.user_feedback?.difficulty === "too_easy") {
    return (
      <Chip
        color={badStuff?.includes("difficulty") ? "error" : "default"}
        size="small"
        icon={<WarningRoundedIcon />}
        label={"Too easy"}
        sx={{
          fontSize: 10,
          "& .MuiChip-iconSmall": {
            fontSize: 14,
          },
        }}
      />
    );
  } else if (setLog.user_feedback?.difficulty === "too_hard") {
    return (
      <Chip
        color={badStuff?.includes("difficulty") ? "error" : "default"}
        size="small"
        icon={<WarningRoundedIcon />}
        label={"Too hard"}
        sx={{
          fontSize: 10,
          "& .MuiChip-iconSmall": {
            fontSize: 14,
          },
        }}
      />
    );
  } else if (setLog.user_feedback?.difficulty === "just_right") {
    return (
      <Chip
        color={badStuff?.includes("difficulty") ? "error" : "default"}
        size="small"
        icon={<ThumbUpRoundedIcon />}
        label={"Just right"}
        sx={{
          fontSize: 10,
          "& .MuiChip-iconSmall": {
            fontSize: 14,
          },
        }}
      />
    );
  }

  return null;
}

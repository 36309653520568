import LanguageRoundedIcon from "@mui/icons-material/LanguageRounded";
import LocalLibraryRoundedIcon from "@mui/icons-material/LocalLibraryRounded";
import {
  Box,
  Button,
  Checkbox,
  Dialog,
  DialogContent,
  DialogContentText,
  DialogTitle,
  FormControlLabel,
  FormGroup,
} from "@mui/material";
import { isBlank } from "@trainwell/features";
import type {
  HabitTemplate,
  HabitType,
  HabitWeek,
} from "@trainwell/features/legacy";
import { useEffect, useState } from "react";
import { useParams } from "react-router";
import { useAppDispatch, useAppSelector } from "src/hooks/stateHooks";
import { trainerHasAccess } from "src/lib/accessRoles";
import {
  createHabitTemplate,
  updateHabitTemplate,
} from "src/slices/clientSlice";
import { selectPrimaryTrainer } from "src/slices/trainerSlice";
import HabitEditFields from "./HabitEditFields";

const defaultHabitType: HabitType = "mindfulness";
const defaultHabitSchedule: HabitTemplate["schedule"] = "daily";
const defaultCustomDays = [false, false, false, false, false, false, false];

type Props = {
  open: boolean;
  onClose: () => void;
  habitTemplateId?: string;
  defaultHabit?: Partial<HabitTemplate>;
  disableLibrary?: boolean;
};

const defaultHabitValues: Partial<HabitWeek> = {
  name: "",
  type: defaultHabitType,
  movement_type: null,
  suggested_time: null,
  notes_coach_default: null,
  steps_required: null,
  schedule: defaultHabitSchedule,
  schedule_intended: defaultHabitSchedule,
  custom_days: defaultCustomDays,
  custom_days_intended: defaultCustomDays,
};

export default function HabitEditDialog({
  open,
  onClose,
  habitTemplateId,
  defaultHabit,
  disableLibrary,
}: Props) {
  const dispatch = useAppDispatch();
  const { userId } = useParams();
  const trainer = useAppSelector(selectPrimaryTrainer);
  const [habit, setHabit] =
    useState<Partial<HabitTemplate>>(defaultHabitValues);
  const [addToCoachLibrary, setAddToCoachLibrary] = useState(
    Boolean(defaultHabit?.trainer_id),
  );

  const defaultGlobalHabit =
    defaultHabit?.trainer_id === null && defaultHabit?.user_id === null;

  const [addToCoPilotLibrary, setAddToCoPilotLibrary] =
    useState(defaultGlobalHabit);

  useEffect(() => {
    if (open && defaultHabit) {
      setHabit((prevState) => ({ ...prevState, ...defaultHabit }));
    }

    if (!open) {
      setHabit(defaultHabitValues);
    }
  }, [open]);

  useEffect(() => {
    if (!open) {
      setAddToCoachLibrary(Boolean(defaultHabit?.trainer_id));
      setAddToCoPilotLibrary(defaultGlobalHabit);
    }
  }, [open, defaultHabit]);

  return (
    <Dialog open={open} onClose={onClose}>
      <DialogTitle>
        {habitTemplateId ? "Edit habit" : "Create habit"}
      </DialogTitle>
      <DialogContent>
        {addToCoachLibrary && (
          <DialogContentText>
            This habit is in your Habit Library. Updating it here updates your
            library.
          </DialogContentText>
        )}
        <HabitEditFields
          sx={{ my: 1 }}
          habit={habit as HabitWeek}
          onHabitChange={(update) => {
            setHabit((prevState) => ({ ...prevState, ...update }));
          }}
        />
        <Box
          sx={{
            display: "flex",
            justifyContent: "space-between",
            alignItems: "flex-end",
            mt: 2,
          }}
        >
          {!disableLibrary ? (
            <Box sx={{ display: "flex", flexDirection: "column" }}>
              <Box sx={{ display: "flex", alignItems: "center" }}>
                <LocalLibraryRoundedIcon
                  sx={{ color: (theme) => theme.palette.text.secondary, mr: 1 }}
                />
                <FormGroup>
                  <FormControlLabel
                    control={
                      <Checkbox
                        checked={addToCoachLibrary}
                        onChange={(event) => {
                          setAddToCoachLibrary(event.target.checked);
                        }}
                      />
                    }
                    label="Add to your Habit Library"
                    disabled={addToCoPilotLibrary || defaultGlobalHabit}
                  />
                </FormGroup>
              </Box>
              {trainerHasAccess(trainer?.access_roles, "global_habits") && (
                <Box sx={{ display: "flex", alignItems: "center" }}>
                  <LanguageRoundedIcon
                    sx={{
                      color: (theme) => theme.palette.text.secondary,
                      mr: 1,
                    }}
                  />
                  <FormGroup>
                    <FormControlLabel
                      control={
                        <Checkbox
                          checked={addToCoPilotLibrary || defaultGlobalHabit}
                          onChange={(event) => {
                            setAddToCoPilotLibrary(event.target.checked);
                          }}
                        />
                      }
                      label="Add to CoPilot Habit Library"
                      disabled={addToCoachLibrary || defaultGlobalHabit}
                    />
                  </FormGroup>
                </Box>
              )}
            </Box>
          ) : (
            <Box />
          )}
          <Box>
            <Button variant="text" onClick={onClose} sx={{ mr: 2 }}>
              Cancel
            </Button>
            <Button
              variant="contained"
              onClick={() => {
                if (!habit.name) {
                  return;
                }

                if (habitTemplateId) {
                  dispatch(
                    updateHabitTemplate({
                      id: habitTemplateId,
                      name: habit.name,
                      notes_coach_default: habit.notes_coach_default,
                      schedule: habit.schedule_intended,
                      schedule_intended: habit.schedule_intended,
                      steps_required: habit.steps_required,
                      suggested_time: habit.suggested_time,
                      type: habit.type,
                      custom_days: habit.custom_days_intended,
                      custom_days_intended: habit.custom_days_intended,
                      trainer_id: addToCoachLibrary
                        ? trainer?.trainer_id
                        : null,
                      user_id:
                        addToCoPilotLibrary || addToCoachLibrary
                          ? null
                          : userId,
                      movement_type: habit.movement_type!,
                    }),
                  );
                } else {
                  dispatch(
                    createHabitTemplate({
                      name: habit.name,
                      notes_coach_default: habit.notes_coach_default!,
                      schedule: habit.schedule_intended!,
                      schedule_intended: habit.schedule_intended!,
                      steps_required: habit.steps_required!,
                      suggested_time: habit.suggested_time!,
                      type: habit.type!,
                      custom_days: habit.custom_days_intended!,
                      custom_days_intended: habit.custom_days_intended!,
                      trainer_id: addToCoachLibrary
                        ? trainer!.trainer_id
                        : null,
                      created_by_trainer_id: trainer!.trainer_id,
                      user_id:
                        addToCoPilotLibrary || addToCoachLibrary
                          ? null
                          : userId!,
                      movement_type: habit.movement_type!,
                    }),
                  );
                }

                onClose();
              }}
              disabled={isBlank(habit.name)}
            >
              Save
            </Button>
          </Box>
        </Box>
      </DialogContent>
    </Dialog>
  );
}

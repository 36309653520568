import ChatBubbleOutlineRoundedIcon from "@mui/icons-material/ChatBubbleOutlineRounded";
import ChatBubbleRoundedIcon from "@mui/icons-material/ChatBubbleRounded";
import { Box, IconButton, Popover, Tooltip } from "@mui/material";
import { isBlank } from "@trainwell/features";
import type { WorkoutSet } from "@trainwell/features/legacy";
import { useState } from "react";
import TextFieldWithLimit from "src/components/misc/TextFieldWithLimit";
import { useAppDispatch } from "src/hooks/stateHooks";
import { updateSetNotes } from "src/slices/workoutSlice";

type Props = {
  set: WorkoutSet;
  setId: string;
  sectionId: string;
  exerciseId: string;
};

export default function SetNotesPopover({
  setId,
  sectionId,
  exerciseId,
  set,
}: Props) {
  const dispatch = useAppDispatch();
  const [anchorEl, setAnchorEl] = useState<HTMLButtonElement | null>(null);
  const notesOpen = Boolean(anchorEl);

  return (
    <>
      <Tooltip
        title={
          set.notes_trainer && set.notes_trainer !== "" ? set.notes_trainer : ""
        }
        enterDelay={0}
        disableInteractive
        placement="left"
      >
        <IconButton
          onClick={(event) => {
            setAnchorEl(event.currentTarget);
          }}
          size="small"
        >
          {!isBlank(set.notes_trainer) ? (
            <ChatBubbleRoundedIcon sx={{ fontSize: 16 }} />
          ) : (
            <ChatBubbleOutlineRoundedIcon sx={{ fontSize: 16 }} />
          )}
        </IconButton>
      </Tooltip>
      <Popover
        open={notesOpen}
        onClose={() => {
          setAnchorEl(null);
        }}
        anchorEl={anchorEl}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "center",
        }}
        transformOrigin={{
          vertical: "top",
          horizontal: "center",
        }}
        disableRestoreFocus
      >
        <Box sx={{ p: 1 }}>
          <TextFieldWithLimit
            autoFocus
            characterLimit={80}
            defaultValue={set.notes_trainer}
            onSave={(value) => {
              dispatch(
                updateSetNotes({
                  notes: value,
                  sectionId: sectionId,
                  exerciseId: exerciseId,
                  setId: setId,
                }),
              );
            }}
            onKeyDownEnter={() => {
              setAnchorEl(null);
            }}
            helperText="These notes will read to the client before the set"
            name="setCell.notes"
            multiline
            onKeyDown={(event) => {
              if (event.key === "Enter") {
                event.preventDefault();
              }
            }}
            fullWidth
            defaultCursorPosition="end"
          />
        </Box>
      </Popover>
    </>
  );
}

import type { PayloadAction } from "@reduxjs/toolkit";
import {
  createAsyncThunk,
  createSelector,
  createSlice,
} from "@reduxjs/toolkit";
import type { Phase, PhaseTemplate, Workout } from "@trainwell/features/legacy";
import { exerciseMap } from "src/lib/exercises";
import { isProgramHabit, isWeekPlanInPast } from "src/lib/habits";
import { api } from "src/lib/trainwellApi";
import { workoutLib } from "src/lib/trainwellWorkoutLib";
import type { PhaseLocal } from "src/types/PhaseLocal";
import { selectAllWeekPlans } from "./clientSlice";
import { downloadPhaseTemplate } from "./phaseTemplatesSlice";
import type { RootState } from "./store";

export const fetchPhases = createAsyncThunk(
  "phases/fetchPhases",
  async (data: { userId: string }) => {
    const { userId } = data;

    const { phases, workouts } = await api.phases.getMany({
      userId: userId,
      includeWorkouts: true,
    });

    return {
      phases: phases,
      workouts: workouts,
    };
  },
);

export const createWorkout = createAsyncThunk(
  "phases/createWorkout",
  async (data: { userId: string; workoutId?: string }) => {
    const { userId, workoutId } = data;

    const { phase, workout } = await api.phases.createOneWorkout({
      userId,
      workoutId,
    });

    return {
      phase: phase,
      workout: workout,
    };
  },
);

export const duplicateWorkout = createAsyncThunk(
  "phases/duplicateWorkout",
  async (workoutId: string) => {
    const duplicatedWorkout = await api.workouts.duplicate(workoutId);

    return duplicatedWorkout;
  },
);

export const createPhase = createAsyncThunk(
  "phases/createPhase",
  async (
    data: {
      userId: string;
    },
    { getState, dispatch },
  ) => {
    const { userId } = data;

    const state = getState() as RootState;

    const phaseEditing = state.phases.phaseEditing;

    if (!phaseEditing) {
      throw new Error("No phase editing");
    }

    const { name, description, days_draggable } = phaseEditing;

    const workoutIds = days_draggable.map((d) =>
      d.workouts.map((w) => w.workout_id),
    );

    const { phase } = await api.phases.createOne({
      name,
      description,
      workoutIds: workoutIds,
      userId: userId,
    });

    const phasesToDelete = state.phases.phases.filter(
      (pt) => pt.deleted === true,
    );

    for (const phaseToDelete of phasesToDelete) {
      dispatch(
        deletePhase({
          phaseId: phaseToDelete._id,
          ignoreWorkout: true,
        }),
      );
    }

    return {
      phase: phase,
    };
  },
);

export const updatePhase = createAsyncThunk(
  "phases/updatePhase",
  async (
    data: {
      id: string;
      name?: string;
      description?: string;
      daysDraggable?: PhaseDayDraggable[];
      tags?: string[];
      dateDeleted?: null;
    },
    { getState, dispatch },
  ) => {
    const { name, daysDraggable, description, tags, id, dateDeleted } = data;

    const state = getState() as RootState;

    const days = daysDraggable?.map((d) => d.workouts.map((w) => w.workout_id));

    const { phase } = await api.phases.updateOne({
      phaseId: id,
      name,
      description,
      days,
      tags: tags,
      dateDeleted: dateDeleted,
    });

    const phasesToDelete = state.phases.phases.filter(
      (pt) => pt.deleted === true,
    );

    for (const phaseToDelete of phasesToDelete) {
      dispatch(
        deletePhase({
          phaseId: phaseToDelete._id,
          ignoreWorkout: true,
        }),
      );
    }

    return {
      phase: phase,
    };
  },
);

export const deletePhase = createAsyncThunk(
  "phases/deletePhase",
  async (data: { phaseId: string; ignoreWorkout?: boolean }) => {
    const { phaseId, ignoreWorkout } = data;

    await api.phases.deleteOne({
      phaseId: phaseId,
      ignoreWorkout: ignoreWorkout,
    });

    return;
  },
);

export const duplicatePhase = createAsyncThunk(
  "phases/duplicatePhase",
  async (data: { phaseId: string }) => {
    const { phaseId } = data;

    const { phase, new_workouts } = await api.phases.duplicateOne(phaseId);

    return {
      phase: phase,
      workouts: new_workouts,
    };
  },
);

export const updateWorkout = createAsyncThunk(
  "phases/updateWorkout",
  async (workout: Partial<Workout> & { workout_id: string }) => {
    const response = await api.workouts.updateOne(workout);

    return response;
  },
);

export type PhaseDayDraggable = {
  draggable_id: string;
  workouts: { draggable_id: string; workout_id: string }[];
};

interface SliceState {
  phases: PhaseLocal[];
  workouts: Workout[];
  status: "idle" | "loading" | "succeeded" | "failed";
  error: string | undefined;
  phaseEditing:
    | (Omit<Phase, "_id"> & {
        _id: string | null;
        days_draggable: PhaseDayDraggable[];
      })
    | null;
}

const initialState: SliceState = {
  phases: [],
  workouts: [],
  status: "idle",
  error: undefined,
  phaseEditing: null,
};

export const phasesSlice = createSlice({
  name: "phases",
  initialState,
  reducers: {
    updateLocalPhase: (
      state,
      action: PayloadAction<{
        workoutId: string;
        phase: Partial<PhaseLocal>;
      }>,
    ) => {
      console.log("Redux: Update local phase");

      const { phase, workoutId } = action.payload;

      const index = state.phases.findIndex(
        (t) => t.type === "single" && t.workout_ids[0][0] === workoutId,
      );

      if (index !== -1) {
        state.phases[index] = {
          ...state.phases[index],
          ...phase,
        };
      }
    },
    updateLocalWorkout: (
      state,
      action: PayloadAction<Partial<Workout> & Pick<Workout, "workout_id">>,
    ) => {
      console.log("Redux: Update local workout");

      const workout = action.payload;

      const index = state.workouts.findIndex(
        (w) => w.workout_id === workout.workout_id,
      );

      if (index !== -1) {
        state.workouts[index] = {
          ...state.workouts[index],
          ...workout,
        };
      }
    },
    setPhaseEditing: (
      state,
      action: PayloadAction<
        | (Omit<Phase, "_id"> & {
            _id: string | null;
            days_draggable: PhaseDayDraggable[];
          })
        | null
      >,
    ) => {
      state.phaseEditing = action.payload;
    },
    updatePhaseEditing: (
      state,
      action: PayloadAction<Partial<
        PhaseTemplate & {
          days_draggable: PhaseDayDraggable[];
        }
      > | null>,
    ) => {
      state.phaseEditing = {
        ...state.phaseEditing,
        ...action.payload,
      } as any;
    },
    addDayToPhaseEditing: (
      state,
      action: PayloadAction<{
        workoutId: string;
      }>,
    ) => {
      const { workoutId } = action.payload;

      if (!state.phaseEditing) {
        return;
      }

      state.phaseEditing.days_draggable = [
        ...state.phaseEditing.days_draggable,
        {
          draggable_id: crypto.randomUUID(),
          workouts: [
            {
              draggable_id: crypto.randomUUID(),
              workout_id: workoutId,
            },
          ],
        },
      ];
    },
    removeWorkoutFromPhaseDay: (
      state,
      action: PayloadAction<{
        dayIndex: number;
        workoutId: string;
      }>,
    ) => {
      const { workoutId, dayIndex } = action.payload;

      if (!state.phaseEditing) {
        return;
      }

      state.phaseEditing.days_draggable[dayIndex].workouts =
        state.phaseEditing.days_draggable[dayIndex].workouts.filter(
          (w) => w.workout_id !== workoutId,
        );
    },
  },
  extraReducers: (builder) => {
    builder.addCase(fetchPhases.pending, (state) => {
      state.status = "loading";
      state.phases = [];
      state.workouts = [];
    });
    builder.addCase(fetchPhases.fulfilled, (state, action) => {
      console.log("Redux: Fetched phased");
      state.status = "succeeded";

      const { phases, workouts } = action.payload;

      phases.map(
        (phase: PhaseLocal) => (phase.draggable_id = crypto.randomUUID()),
      );

      state.phases = phases.sort((a, b) => a.name.localeCompare(b.name));

      if (workouts) {
        state.workouts = workouts;
      }
    });
    builder.addCase(fetchPhases.rejected, (state, action) => {
      state.status = "failed";
      state.error = action.error.message;
    });
    builder.addCase(createWorkout.fulfilled, (state, action) => {
      const { phase, workout } = action.payload;

      (phase as PhaseLocal).draggable_id = crypto.randomUUID();

      state.phases.unshift(phase);

      const workoutIndex = state.workouts.findIndex(
        (w) => w.workout_id === workout.workout_id,
      );

      if (workoutIndex === -1) {
        state.workouts.unshift(workout);
      } else {
        state.workouts[workoutIndex] = workout;
      }
    });
    builder.addCase(createPhase.fulfilled, (state, action) => {
      const { phase } = action.payload;

      (phase as PhaseLocal).draggable_id = crypto.randomUUID();

      state.phases.unshift(phase);

      const workoutIds = phase.workout_ids.flat();

      for (const workoutId of workoutIds) {
        const workoutIndex = state.workouts.findIndex(
          (w) => w.workout_id === workoutId,
        );

        if (workoutIndex === -1) {
          continue;
        }

        state.workouts[workoutIndex].phase_id = phase._id;
      }
    });
    builder.addCase(updatePhase.fulfilled, (state, action) => {
      const { phase } = action.payload;

      state.phases = state.phases.filter((t) => t._id !== phase._id);

      (phase as PhaseLocal).draggable_id = crypto.randomUUID();

      state.phases.unshift(phase);

      if (action.meta.arg.dateDeleted === null) {
        // Undelete phase

        state.workouts.map((w) => {
          if (phase.workout_ids.flat().includes(w.workout_id)) {
            w.metadata.date_deleted = null;
          }
        });
      }
    });
    builder.addCase(duplicatePhase.fulfilled, (state, action) => {
      const { phase, workouts } = action.payload;

      (phase as PhaseLocal).draggable_id = crypto.randomUUID();

      state.phases.unshift(phase);
      state.workouts.unshift(...workouts);
    });
    builder.addCase(downloadPhaseTemplate.fulfilled, (state, action) => {
      const { phase, newWorkouts } = action.payload;

      (phase as PhaseLocal).draggable_id = crypto.randomUUID();

      state.phases.unshift(phase);
      state.workouts.unshift(...newWorkouts);
    });
    builder.addCase(deletePhase.fulfilled, (state, action) => {
      const { phaseId } = action.meta.arg;

      const phaseIndex = state.phases.findIndex((t) => t._id === phaseId);

      if (phaseIndex === -1) {
        return;
      }

      state.phases[phaseIndex].date_deleted = new Date().toISOString();
    });
    builder.addCase(updateWorkout.fulfilled, (state, action) => {
      const workout = action.payload;

      const workoutIndex = state.workouts.findIndex(
        (w) => w.workout_id === workout.workout_id,
      );

      if (workoutIndex === -1) {
        return;
      }

      state.workouts[workoutIndex] = workout;

      const index = state.phases.findIndex(
        (t) =>
          t.type === "single" && t.workout_ids[0][0] === workout.workout_id,
      );

      if (index !== -1) {
        state.phases[index].name = workout.name;
      }
    });
    builder.addCase(duplicateWorkout.fulfilled, (state, action) => {
      const { workout } = action.payload;
      const oldWorkoutId = action.meta.arg;

      const oldWorkoutIndex = state.workouts.findIndex(
        (w) => w.workout_id === oldWorkoutId,
      );

      state.workouts.splice(oldWorkoutIndex + 1, 0, workout);
    });
  },
});

// Action creators are generated for each case reducer function
export const {
  updateLocalPhase,
  updateLocalWorkout,
  setPhaseEditing,
  updatePhaseEditing,
  addDayToPhaseEditing,
  removeWorkoutFromPhaseDay,
} = phasesSlice.actions;

export default phasesSlice.reducer;

export const selectPhaseById = (state: RootState, phaseId: string) =>
  state.phases.phases.find((phase) => phase._id === phaseId);

export const selectWorkoutById = (state: RootState, workoutId: string) =>
  state.phases.workouts.find((workout) => workout.workout_id === workoutId);

export const selectExtraWorkoutIds = (state: RootState) => {
  return state.phases.workouts
    .filter((w) => w.is_extra && !w.metadata.date_deleted)
    .map((w) => w.workout_id);
};

export const selectCurrentPhaseIds = (state: RootState) => {
  const phases = state.phases.phases;

  let workoutIds: string[] = [];

  const weekPlans = selectAllWeekPlans(state);

  const upcomingWeekPlans = weekPlans.filter(
    (weekPlan) => !isWeekPlanInPast(weekPlan.date, true),
  );

  for (const weekPlan of upcomingWeekPlans) {
    const workoutHabit = weekPlan.habit_weeks.find(isProgramHabit);

    if (!workoutHabit) {
      continue;
    }

    workoutIds.push(
      ...(
        (workoutHabit.anchored_workout_days ?? [])
          .flat()
          .filter(Boolean) as string[]
      ).flat(),
    );
  }

  workoutIds = [...new Set(workoutIds)];

  const phaseIds: string[] = [];

  for (const phase of phases) {
    if (
      phase.type === "single" &&
      workoutIds.includes(phase.workout_ids[0][0])
    ) {
      phaseIds.push(phase._id);
    }
  }

  return phaseIds;
};

export const selectDefaultTags = createSelector(
  [(state: RootState) => state.phases.workouts, selectPhaseById],
  (workouts, phase) => {
    const workoutIds = [
      ...new Set(
        (phase?.workout_ids ?? [[]]).flat().filter(Boolean) as string[],
      ),
    ];

    const workoutsInPhase: Workout[] = [];

    for (const workoutId of workoutIds) {
      const workout = workouts.find((w) => w.workout_id === workoutId);

      if (workout) {
        workoutsInPhase.push(workout);
      }
    }

    const tags = workoutLib.phaseTemplates.getPhaseTemplateSmartTags({
      phaseTemplate: phase!,
      exerciseLibrary: exerciseMap,
      workoutTemplates: workoutsInPhase,
    });

    return tags;
  },
);

import type { TemplateLibraryFolder } from "@trainwell/features/legacy";

export type FolderHeirarchy = {
  folder: TemplateLibraryFolder;
  children: FolderHeirarchy[];
  level: number;
};

export function buildFolderHierarchy(
  folders: TemplateLibraryFolder[],
  parentId: string | null = null,
  level = 0,
): FolderHeirarchy[] {
  return folders
    .filter((f) =>
      parentId ? f.parent_folder_id === parentId : !f.parent_folder_id,
    )
    .map((folder) => ({
      folder: folder,
      children: buildFolderHierarchy(folders, folder._id, level + 1),
      level: level,
    }));
}

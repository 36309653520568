import type {
  ActionItem,
  ActionItemWorkoutReview,
  Client,
  HabitTaskMessage,
  Message,
  MessageWorkoutsMissed,
  NotificationMessage,
  SupportTicketChat,
} from "@trainwell/features/legacy";
import {
  addDays,
  differenceInCalendarDays,
  format,
  isFuture,
  isPast,
  isSameWeek,
  max,
  min,
} from "date-fns";
import { toZonedTime } from "date-fns-tz";
import type { Chat } from "src/slices/chatSlice";
import type { ClientInfo } from "src/slices/clientsSlice";
import { getNotificationTitle } from "./message";
import { notCheckedInToday } from "./misc";
import { workoutLib } from "./trainwellWorkoutLib";

export function messageAsText(message: Message, client?: Client) {
  switch (message.type) {
    case "text":
      return message.text;
    case "notification":
      return getNotificationTitle(
        (message as NotificationMessage).notification_type ?? "",
      );
    case "video":
      return "[Video]";
    case "workout":
      return "[Workout completed]";
    case "image":
      return "[Image]";
    case "habit_task_log":
      if ((message as HabitTaskMessage).habit_task.workout_id) {
        return `Workout: ${(message as HabitTaskMessage).habit_task.name}`;
      } else {
        return `Habit: ${(message as HabitTaskMessage).habit_task.name}`;
      }
    case "workouts_missed": {
      const missedWorkoutCount =
        (message as MessageWorkoutsMissed).content.workouts.length ?? 1;
      const missedWorkoutDayStreakCount =
        client?.missed_workout_day_streak ?? 0;

      if (!missedWorkoutDayStreakCount) {
        return `😢 Missed ${missedWorkoutCount} workout${missedWorkoutCount > 1 ? "s" : ""}`;
      }

      return `😢 Missed ${missedWorkoutDayStreakCount} workout day${missedWorkoutDayStreakCount > 1 ? "s in a row" : ""}`;
    }
    case "call_scheduled":
      return "[Call scheduled]";
    case "progress_logged":
      return "[Progress logged]";
  }
}

export function getChatFromTicket(
  ticketChat: SupportTicketChat,
  coachName: string,
  trainerId: string,
) {
  let ticketName = `#${ticketChat.support_ticket.number}, ${coachName}`;

  if (coachName !== ticketChat.support_ticket.client_name) {
    ticketName += ` & ${ticketChat.support_ticket.client_name}`;
  }

  const chat: Chat = {
    isGroupChat: true,
    id: ticketChat.chat_id,
    messages: ticketChat.latest_message
      ? [ticketChat.latest_message as any]
      : [],
    firstMessageFetchState: "idle",
    clientName: ticketName,
    clientHeadshotURL: ticketChat.thumbnail_url ?? "",
    loadingState: "idle",
    oldestUnreadMessageFromClient:
      ticketChat.latest_message &&
      (ticketChat.latest_message as any).trainer_id !== trainerId &&
      !((ticketChat.latest_message as any).read_statuses ?? {})[trainerId]
        ? new Date().toISOString()
        : undefined,
    ticketId: ticketChat.support_ticket_id,
    dateCreated: ticketChat.date_created as string,
    memberIds: ticketChat.member_ids,
    pinned: false,
    firstChatIndex: 1000000,
  };

  return chat;
}

export function sortChatsByUnread(a: Chat, b: Chat) {
  if (b.messages.length > 0 && a.messages.length > 0) {
    const aUnread = Boolean(a.oldestUnreadMessageFromClient);
    const bUnread = Boolean(b.oldestUnreadMessageFromClient);

    if (aUnread && !bUnread) {
      return -1;
    } else if (!aUnread && bUnread) {
      return 1;
    }

    return (
      b.messages[b.messages.length - 1].send_date as string
    ).localeCompare(a.messages[a.messages.length - 1].send_date as string);
  } else if (a.messages.length === 0 && b.messages.length > 0) {
    return 1;
  } else if (b.messages.length === 0 && a.messages.length > 0) {
    return -1;
  } else {
    return 0;
  }
}

export function sortChatsByNewest(a: Chat, b: Chat) {
  if (a.pinned && !b.pinned) {
    return -1;
  } else if (!a.pinned && b.pinned) {
    return 1;
  }

  if (
    b.messages.length > 0 &&
    a.messages.length > 0 &&
    b.messages[b.messages.length - 1].send_date &&
    a.messages[a.messages.length - 1].send_date
  ) {
    if (
      a.messages[a.messages.length - 1] &&
      b.messages[b.messages.length - 1]
    ) {
      return (
        b.messages[b.messages.length - 1].send_date as string
      ).localeCompare(a.messages[a.messages.length - 1].send_date as string);
    } else if (
      a.messages[a.messages.length - 1] &&
      !b.messages[b.messages.length - 1]
    ) {
      return 1;
    } else if (
      !a.messages[a.messages.length - 1] &&
      b.messages[b.messages.length - 1]
    ) {
      return -1;
    } else {
      return 0;
    }
  } else if (a.messages.length === 0 && b.messages.length > 0) {
    return 1;
  } else if (b.messages.length === 0 && a.messages.length > 0) {
    return -1;
  } else {
    return 0;
  }
}

function sortChatsByOldest(a: Chat, b: Chat): number {
  if (a.pinned && !b.pinned) {
    return -1;
  } else if (!a.pinned && b.pinned) {
    return 1;
  }

  if (!a.oldestUnreadMessageFromClient && b.oldestUnreadMessageFromClient) {
    return 1;
  } else if (
    a.oldestUnreadMessageFromClient &&
    !b.oldestUnreadMessageFromClient
  ) {
    return -1;
  } else if (
    a.oldestUnreadMessageFromClient &&
    b.oldestUnreadMessageFromClient
  ) {
    return (a.oldestUnreadMessageFromClient as string).localeCompare(
      b.oldestUnreadMessageFromClient as string,
    );
  } else if (
    a.messages.length > 0 &&
    b.messages.length > 0 &&
    a.messages[a.messages.length - 1].send_date &&
    b.messages[b.messages.length - 1].send_date
  ) {
    return (
      a.messages[a.messages.length - 1].send_date as string
    ).localeCompare(b.messages[b.messages.length - 1].send_date as string);
  } else if (a.messages.length === 0 && b.messages.length > 0) {
    return 1;
  } else if (b.messages.length === 0 && a.messages.length > 0) {
    return -1;
  } else {
    return 0;
  }
}

export function sortChatsByUnanswered(a: Chat, b: Chat, trainerId: string) {
  if (b.messages.length > 0 && a.messages.length > 0) {
    if (
      a.messages[a.messages.length - 1] &&
      b.messages[b.messages.length - 1] &&
      b.messages[b.messages.length - 1].send_date &&
      a.messages[a.messages.length - 1].send_date
    ) {
      if (
        a.messages[a.messages.length - 1].from_id === trainerId &&
        b.messages[b.messages.length - 1].from_id !== trainerId
      ) {
        return 1;
      } else if (
        a.messages[a.messages.length - 1].from_id !== trainerId &&
        b.messages[b.messages.length - 1].from_id === trainerId
      ) {
        return -1;
      }

      return (
        b.messages[b.messages.length - 1].send_date as string
      ).localeCompare(a.messages[a.messages.length - 1].send_date as string);
    } else if (
      a.messages[a.messages.length - 1] &&
      !b.messages[b.messages.length - 1]
    ) {
      return 1;
    } else if (
      !a.messages[a.messages.length - 1] &&
      b.messages[b.messages.length - 1]
    ) {
      return -1;
    } else {
      return 0;
    }
  } else if (a.messages.length === 0 && b.messages.length > 0) {
    return 1;
  } else if (b.messages.length === 0 && a.messages.length > 0) {
    return -1;
  } else {
    return 0;
  }
}

export function sortChatsByOldestActionItem(
  a: Chat,
  b: Chat,
  actionItems: ActionItem[],
): number {
  if (a.pinned && !b.pinned) {
    return -1;
  } else if (!a.pinned && b.pinned) {
    return 1;
  }

  const aActionItems = actionItems.filter(
    (item) => item.user_id === a.id && item.type !== "custom",
  );
  const bActionItems = actionItems.filter(
    (item) => item.user_id === b.id && item.type !== "custom",
  );

  const aDates = aActionItems.map(
    (actionItem) => actionItem.date_to_send || actionItem.date_created,
  ) as string[];
  const bDates = bActionItems.map(
    (actionItem) => actionItem.date_to_send || actionItem.date_created,
  ) as string[];

  const aHasUnread = aActionItems.some((item) => item.type === "read_chat");
  const bHasUnread = bActionItems.some((item) => item.type === "read_chat");

  if (aHasUnread && !bHasUnread) {
    return -1;
  } else if (!aHasUnread && bHasUnread) {
    return 1;
  }

  if (aDates.length === 0 && bDates.length === 0) {
    return sortChatsByOldest(a, b);
  } else if (aDates.length === 0) {
    return 1;
  } else if (bDates.length === 0) {
    return -1;
  }

  const aOldestDate = min(aDates);
  const bOldestDate = min(bDates);

  return aOldestDate.getTime() - bOldestDate.getTime();
}

export function sortChatsByNewestActionItem(
  a: Chat,
  b: Chat,
  actionItems: ActionItem[],
) {
  if (a.pinned && !b.pinned) {
    return -1;
  } else if (!a.pinned && b.pinned) {
    return 1;
  }

  const aActionItems = actionItems.filter(
    (item) => item.user_id === a.id && item.type !== "custom",
  );
  const bActionItems = actionItems.filter(
    (item) => item.user_id === b.id && item.type !== "custom",
  );

  const aDates = aActionItems.map(
    (actionItem) => actionItem.date_to_send || actionItem.date_created,
  ) as string[];
  const bDates = bActionItems.map(
    (actionItem) => actionItem.date_to_send || actionItem.date_created,
  ) as string[];

  const aHasUnread = aActionItems.some(
    (item) =>
      item.type === "read_chat" ||
      (item.type === "workout_review" &&
        (item as ActionItemWorkoutReview).is_internal),
  );
  const bHasUnread = bActionItems.some(
    (item) =>
      item.type === "read_chat" ||
      (item.type === "workout_review" &&
        (item as ActionItemWorkoutReview).is_internal),
  );

  if (aHasUnread && !bHasUnread) {
    return -1;
  } else if (!aHasUnread && bHasUnread) {
    return 1;
  }

  if (aDates.length === 0 && bDates.length === 0) {
    return sortChatsByNewest(a, b);
  } else if (aDates.length === 0) {
    return 1;
  } else if (bDates.length === 0) {
    return -1;
  }

  const aNewestDate = max(aDates);
  const bNewestDate = max(bDates);

  return bNewestDate.getTime() - aNewestDate.getTime();
}

export function getUserIdsMatchingFilter(
  filter: string,
  clients: Client[],
  clientInfo: ClientInfo,
  actionItems: ActionItem[],
): string[] {
  const today = new Date();

  const dayIndex = today.getDay();
  const daysLeftInWeek = 6 - dayIndex;

  if (filter.startsWith("ai:")) {
    const actionItemType = filter.split(":")[1];

    return clients
      .filter((client) =>
        actionItems.some(
          (actionItem) =>
            actionItem.user_id === client.user_id &&
            actionItem.type === actionItemType,
        ),
      )
      .map((client) => client.user_id);
  } else if (filter.startsWith("client_tag:")) {
    const tag = filter.split(":")[1];

    return clients
      .filter((client) => client.tags?.includes(tag) ?? false)
      .map((client) => client.user_id);
  } else if (filter === "not_visited") {
    return clients
      .filter((client) => notCheckedInToday(client))
      .map((client) => client.user_id);
  } else if (filter === "workout_today") {
    return clients
      .filter(
        (client) => clientInfo[client.user_id]?.daysUntilNextWorkout === 0,
      )
      .map((client) => client.user_id);
  } else if (filter === "workout_tomorrow") {
    return clients
      .filter(
        (client) => clientInfo[client.user_id]?.daysUntilNextWorkout === 1,
      )
      .map((client) => client.user_id);
  } else if (filter === "any_workout_week") {
    return clients
      .filter(
        (client) =>
          (clientInfo[client.user_id]?.daysUntilNextWorkout ?? 999) <=
          daysLeftInWeek,
      )
      .map((client) => client.user_id);
  } else if (filter === "missed_streak_0") {
    return clients
      .filter((client) => client.missed_workout_day_streak === 0)
      .map((client) => client.user_id);
  } else if (filter === "missed_streak_1") {
    return clients
      .filter((client) => client.missed_workout_day_streak === 1)
      .map((client) => client.user_id);
  } else if (filter === "missed_streak_2_or_more") {
    return clients
      .filter((client) => (client.missed_workout_day_streak ?? 0) >= 2)
      .map((client) => client.user_id);
  } else if (filter === "visible") {
    return clients
      .filter((client) => !client.account.dashboard.is_hidden)
      .map((client) => client.user_id);
  } else if (filter === "hidden") {
    return clients
      .filter((client) => client.account.dashboard.is_hidden === true)
      .map((client) => client.user_id);
  } else if (filter === "pre_trial") {
    return clients
      .filter((client) => {
        return !client.account.dashboard.date_onboarded;
      })
      .map((client) => client.user_id);
  } else if (filter === "in_trial") {
    return clients
      .filter((client) => {
        return (
          client.account.dashboard.date_onboarded &&
          client.account.plan.date_trial_end &&
          isFuture(new Date(client.account.plan.date_trial_end))
        );
      })
      .map((client) => client.user_id);
  } else if (filter === "post_trial") {
    return clients
      .filter((client) => {
        return (
          client.account.dashboard.date_onboarded &&
          client.account.plan.date_trial_end &&
          isPast(new Date(client.account.plan.date_trial_end))
        );
      })
      .map((client) => client.user_id);
  } else if (filter === "unmessaged_3_or_more") {
    return clients
      .filter((client) => {
        const lastMessageDate = client.last_trainer_message_date
          ? new Date(client.last_trainer_message_date)
          : undefined;

        if (!lastMessageDate) {
          return false;
        }

        const daysSinceMessage = differenceInCalendarDays(
          today,
          lastMessageDate,
        );

        return daysSinceMessage >= 3;
      })
      .map((client) => client.user_id);
  } else if (filter === "unmessaged_7_or_more") {
    return clients
      .filter((client) => {
        const lastMessageDate = client.last_trainer_message_date
          ? new Date(client.last_trainer_message_date)
          : undefined;

        if (!lastMessageDate) {
          return false;
        }

        const daysSinceMessage = differenceInCalendarDays(
          today,
          lastMessageDate,
        );

        return daysSinceMessage >= 7;
      })
      .map((client) => client.user_id);
  } else if (filter === "worked_out_today") {
    return clients
      .filter((client) => {
        const lastWorkoutDate = client.last_workout_date
          ? toZonedTime(
              new Date(client.last_workout_date),
              client?.timezone ?? "America/New_York",
            )
          : undefined;

        if (!lastWorkoutDate) {
          return false;
        }

        const daysSinceWorkout = differenceInCalendarDays(
          today,
          lastWorkoutDate,
        );

        return daysSinceWorkout === 0;
      })
      .map((client) => client.user_id);
  } else if (filter === "worked_out_week") {
    return clients
      .filter((client) => {
        const lastWorkoutDate = client.last_workout_date
          ? toZonedTime(
              new Date(client.last_workout_date),
              client?.timezone ?? "America/New_York",
            )
          : undefined;

        if (!lastWorkoutDate) {
          return false;
        }

        const daysSinceWorkout = differenceInCalendarDays(
          today,
          lastWorkoutDate,
        );

        return daysSinceWorkout <= 7;
      })
      .map((client) => client.user_id);
  }

  return [];
}

export function getChatsMatchingFilter(
  filter: string,
  chats: Chat[],
  clients: Client[],
  clientInfo: ClientInfo,
  actionItems: ActionItem[],
) {
  const userIds = getUserIdsMatchingFilter(
    filter,
    clients,
    clientInfo,
    actionItems,
  );

  return chats.filter((chat) => userIds.includes(chat.id));
}

const dayNames = [
  "Sunday",
  "Monday",
  "Tuesday",
  "Wednesday",
  "Thursday",
  "Friday",
  "Saturday",
];

export type SerializeMessageData = {
  magicKeyId: string;
  client: Client;
  clientInfo: ClientInfo;
  lastWord?: string;
};

export function getSerializedMessage(data: SerializeMessageData) {
  const { magicKeyId, client, clientInfo: allClientInfo, lastWord } = data;
  const clientInfo = allClientInfo[client.user_id];

  console.log("last word", lastWord);

  const today = new Date();

  const clientsToday = toZonedTime(
    today,
    client?.timezone ?? "America/New_York",
  );

  let foundError = false;
  let serializedMagicKey = "{{ ERROR }}";

  if (!clientInfo) {
    return {
      message: "{{ ERROR: no client info }}",
      foundError: true,
    };
  }

  if (magicKeyId === "first_name") {
    serializedMagicKey = client.first_name;
  } else if (magicKeyId === "full_name") {
    serializedMagicKey = client.full_name;
  } else if (magicKeyId === "url_change_coach") {
    serializedMagicKey = `https://account.trainwell.net/change-coach?user_id=${client.user_id}`;
  } else if (magicKeyId === "url_schedule_call") {
    serializedMagicKey = `https://account.trainwell.net/schedule?user_id=${client.user_id}`;
  } else if (magicKeyId === "url_billing_portal") {
    serializedMagicKey = `https://account.trainwell.net`;
  } else if (magicKeyId === "missed_workout_streak_length") {
    if (client.missed_workout_day_streak === undefined) {
      serializedMagicKey = `{{ ERROR: never missed a workout}`;
      foundError = true;
    } else {
      serializedMagicKey += client.missed_workout_day_streak;
    }
  } else if (magicKeyId === "next_workout_name") {
    if (!clientInfo.upcomingWorkout) {
      serializedMagicKey = `{{ ERROR: no upcoming workout }}`;
      foundError = true;
    } else {
      serializedMagicKey = clientInfo.upcomingWorkout.name;
    }
  } else if (magicKeyId === "next_workout_duration") {
    if (!clientInfo.upcomingWorkout) {
      serializedMagicKey = `{{ ERROR: no upcoming workout }}`;
      foundError = true;
    } else {
      serializedMagicKey = `${Math.floor(
        workoutLib.workouts.getWorkoutDuration(clientInfo.upcomingWorkout) / 60,
      )} minutes`;
    }
  } else if (magicKeyId === "next_workout_date") {
    if (!clientInfo.upcomingWorkout) {
      serializedMagicKey = `{{ ERROR: no upcoming workout }}`;
      foundError = true;
    } else {
      const date = addDays(new Date(), clientInfo.daysUntilNextWorkout!);

      serializedMagicKey = format(date, "do");
    }
  } else if (magicKeyId === "next_workout_day") {
    if (!clientInfo.upcomingWorkout) {
      serializedMagicKey = `{{ ERROR: no upcoming workout }}`;
      foundError = true;
    } else {
      const date = addDays(new Date(), clientInfo.daysUntilNextWorkout!);

      // const lastWord = message.split(" ").at(-2);

      if (lastWord !== "on" && clientInfo.daysUntilNextWorkout === 1) {
        serializedMagicKey = "tomorrow";
      } else if (lastWord !== "on" && clientInfo.daysUntilNextWorkout === 0) {
        serializedMagicKey = "today";
      } else {
        serializedMagicKey = format(date, "EEEE");
      }
    }
  } else if (magicKeyId === "last_missed_workout_name") {
    if (!clientInfo.latestMissedWorkout) {
      serializedMagicKey = `{{ ERROR: no missed workout }}`;
      foundError = true;
    } else {
      serializedMagicKey = clientInfo.latestMissedWorkout.name;
    }
  } else if (magicKeyId === "last_missed_workout_duration") {
    if (!clientInfo.latestMissedWorkout) {
      serializedMagicKey = `{{ ERROR: no missed workout }}`;
      foundError = true;
    } else {
      serializedMagicKey = `${Math.floor(
        workoutLib.workouts.getWorkoutDuration(clientInfo.latestMissedWorkout) /
          60,
      )} minutes`;
    }
  } else if (magicKeyId === "last_missed_workout_date") {
    if (!clientInfo.latestMissedWorkoutDate) {
      serializedMagicKey = `{{ ERROR: no missed workout }}`;
      foundError = true;
    } else {
      const date = toZonedTime(
        new Date(clientInfo.latestMissedWorkoutDate),
        client?.timezone ?? "America/New_York",
      );

      serializedMagicKey = format(date, "do");
    }
  } else if (magicKeyId === "last_missed_workout_day") {
    if (!clientInfo.latestMissedWorkoutDate) {
      serializedMagicKey = `{{ ERROR: no missed workout }}`;
      foundError = true;
    } else {
      const date = toZonedTime(
        new Date(clientInfo.latestMissedWorkoutDate),
        client?.timezone ?? "America/New_York",
      );

      const daysAgo = differenceInCalendarDays(clientsToday, date);

      // const lastWord = message.split(" ").at(-2);

      if (lastWord !== "on" && daysAgo === 1) {
        serializedMagicKey = "yesterday";
      } else {
        serializedMagicKey = format(date, "EEEE");
      }
    }
  } else if (magicKeyId === "upcoming_workout_days_this_week") {
    if (!clientInfo.upcomingWorkoutSchedules.at(0)) {
      serializedMagicKey = `{{ ERROR: no workout schedule this week }}`;
      foundError = true;
    } else {
      if (!isSameWeek(today, clientsToday)) {
        serializedMagicKey = `{{ ERROR: cleint timezone is different week }}`;
        foundError = true;
      } else {
        let todaysDayIndex = toZonedTime(
          today,
          client?.timezone ?? "America/New_York",
        ).getDay();

        const lastWorkoutDate = client.last_workout_date
          ? toZonedTime(
              new Date(client.last_workout_date),
              client?.timezone ?? "America/New_York",
            )
          : undefined;

        if (lastWorkoutDate) {
          const daysSinceWorkout = differenceInCalendarDays(
            today,
            lastWorkoutDate,
          );

          if (daysSinceWorkout) {
            // Worked out today

            if (todaysDayIndex === 6) {
              serializedMagicKey = `{{ ERROR: no upcoming workouts this week (did a workout today) }}`;
              foundError = true;
            } else {
              todaysDayIndex = todaysDayIndex + 1;
            }
          }
        }

        const days: string[] = [];

        // const lastWord = message.split(" ").at(-2);

        for (let dayIndex = todaysDayIndex; dayIndex <= 6; dayIndex++) {
          if (clientInfo.upcomingWorkoutSchedules.at(0)?.at(dayIndex)) {
            if (dayIndex === todaysDayIndex && lastWord !== "on") {
              days.push("today");
            } else if (dayIndex === todaysDayIndex + 1 && lastWord !== "on") {
              days.push("tomorrow");
            } else {
              days.push(dayNames[dayIndex]);
            }
          }
        }

        if (days.length >= 2) {
          days[days.length - 2] = `${days[days.length - 2]}${
            days.length >= 3 ? "," : ""
          } and ${days[days.length - 1]}`;

          days.pop();
        }

        if (days.length > 0) {
          serializedMagicKey = days.join(", ");
        } else {
          serializedMagicKey = `{{ ERROR: no upcoming workouts this week }}`;
          foundError = true;
        }
      }
    }
  } else if (magicKeyId === "upcoming_workout_days_next_week") {
    if (!clientInfo.upcomingWorkoutSchedules.at(1)) {
      serializedMagicKey = `{{ ERROR: no workout schedule next week }}`;
      foundError = true;
    } else {
      if (
        !isSameWeek(
          today,
          toZonedTime(today, client?.timezone ?? "America/New_York"),
        )
      ) {
        serializedMagicKey = `{{ ERROR: cleint timezone is different week }}`;
        foundError = true;
      } else {
        const days: string[] = [];

        for (let dayIndex = 0; dayIndex <= 6; dayIndex++) {
          if (clientInfo.upcomingWorkoutSchedules.at(1)?.at(dayIndex)) {
            days.push(dayNames[dayIndex]);
          }
        }

        if (days.length >= 2) {
          days[days.length - 2] = `${days[days.length - 2]}${
            days.length >= 3 ? "," : ""
          } and ${days[days.length - 1]}`;

          days.pop();
        }

        if (days.length > 0) {
          serializedMagicKey = days.join(", ");
        } else {
          serializedMagicKey = `{{ ERROR: no workouts next week }}`;
          foundError = true;
        }
      }
    }
  } else {
    serializedMagicKey = `{{ ERROR: missingKey ${magicKeyId} }}`;
    foundError = true;
  }

  return {
    message: serializedMagicKey,
    foundError: foundError,
  };
}

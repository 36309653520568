import type { SxProps, Theme } from "@mui/material";
import {
  Autocomplete,
  Box,
  FormControl,
  Grid,
  InputLabel,
  ListItemText,
  MenuItem,
  Select,
  TextField,
  ToggleButton,
  ToggleButtonGroup,
} from "@mui/material";
import type {
  HabitTemplate,
  HabitType,
  HabitWeek,
  MovementHabitType,
} from "@trainwell/features/legacy";
import { movementHabitTypes } from "@trainwell/features/legacy";
import { useEffect } from "react";
import TextFieldWithLimit from "src/components/misc/TextFieldWithLimit";
import {
  getHabitColor,
  getHabitTypeLabel,
  getSelectedDays,
} from "src/lib/habits";

const days = ["S", "M", "T", "W", "T", "F", "S"];

const types = ["mindfulness", "nutrition", "recovery", "movement"];

type Props = {
  habit: HabitWeek;
  onHabitChange: (update: Partial<HabitWeek>) => void;
  size?: "small" | "medium";
  sx?: SxProps<Theme>;
};

export default function HabitEditFields({
  habit,
  onHabitChange,
  size = "medium",
  sx = [],
}: Props) {
  const selectedDays = getSelectedDays(
    habit.schedule_intended,
    habit.custom_days,
  );

  useEffect(() => {
    if (habit.type === "movement" && !habit.movement_type) {
      onHabitChange({ movement_type: "manual" });
    } else if (habit.type !== "movement" && habit.type) {
      onHabitChange({ movement_type: null });
    }
  }, [habit.type]);

  useEffect(() => {
    if (habit.type === "movement" && habit.movement_type !== "manual") {
      onHabitChange({ steps_required: null });
    }
  }, [habit.type, habit.movement_type]);

  return (
    <Grid
      container
      spacing={size === "small" ? 1 : 2}
      sx={Array.isArray(sx) ? sx : [sx]}
    >
      <Grid item xs={habit.type === "movement" ? 4 : 6}>
        <TextFieldWithLimit
          label="Habit name"
          value={habit.name}
          onSave={(newValue) => {
            onHabitChange({ name: newValue });
          }}
          fullWidth
          characterLimit={44}
          name="habitDialog.name"
          size={size}
        />
      </Grid>
      <Grid item xs={habit.type === "movement" ? 4 : 6}>
        <FormControl fullWidth size={size}>
          <InputLabel id="label">Type</InputLabel>
          <Select
            labelId="label"
            value={habit.type}
            label="Type"
            onChange={(event) => {
              onHabitChange({
                type: event.target.value as HabitType,
              });
            }}
            renderValue={(type) => (
              <Box sx={{ display: "flex", alignItems: "center" }}>
                <Box
                  sx={{
                    width: "16px",
                    height: "16px",
                    borderRadius: "8px",
                    backgroundColor: (theme) =>
                      getHabitColor(type as any, theme),
                    mr: 2,
                  }}
                />
                <ListItemText
                  primary={getHabitTypeLabel(type as any)}
                  sx={{ my: 0 }}
                />
              </Box>
            )}
          >
            {types.map((type) => (
              <MenuItem value={type} key={type}>
                <Box sx={{ display: "flex", alignItems: "center" }}>
                  <Box
                    sx={{
                      width: "16px",
                      height: "16px",
                      borderRadius: "8px",
                      backgroundColor: (theme) =>
                        getHabitColor(type as any, theme),
                      mr: 2,
                    }}
                  />
                  <ListItemText primary={getHabitTypeLabel(type as any)} />
                </Box>
              </MenuItem>
            ))}
          </Select>
        </FormControl>
      </Grid>
      {habit.type === "movement" && (
        <Grid item xs={4}>
          <Autocomplete
            fullWidth
            options={Object.keys(movementHabitTypes)}
            value={habit.movement_type}
            renderInput={(params) => (
              <TextField {...params} label="Movement type" />
            )}
            getOptionLabel={(option) =>
              movementHabitTypes[option as MovementHabitType]
            }
            onChange={(_, newValue: string | null) => {
              onHabitChange({
                movement_type: (newValue as MovementHabitType) ?? "manual",
              });
            }}
            size={size}
          />
        </Grid>
      )}
      <Grid item xs={12}>
        <TextFieldWithLimit
          label="Notes (optional)"
          defaultValue={habit.notes_coach_default}
          onSave={(newValue) => {
            onHabitChange({
              notes_coach_default: newValue || null,
            });
          }}
          name="habitDialog.notes"
          multiline
          fullWidth
          characterLimit={69}
          size={size}
        />
      </Grid>
      <Grid item xs={6}>
        <TextField
          fullWidth
          label="Suggested time (optional)"
          defaultValue={habit.suggested_time}
          onChange={(event) => {
            onHabitChange({
              suggested_time: event.target.value || null,
            });
          }}
          name="habitDialog.suggestedTime"
          multiline
          size={size}
        />
      </Grid>
      <Grid item xs={6}>
        <FormControl
          fullWidth
          disabled={
            habit.type === "movement" && habit.movement_type !== "manual"
          }
          size={size}
        >
          <InputLabel id="label">Steps</InputLabel>
          <Select
            labelId="label"
            value={habit.steps_required === null ? 0 : habit.steps_required}
            label="Steps"
            onChange={(event) => {
              onHabitChange({
                steps_required:
                  Number(event.target.value) === 0
                    ? null
                    : Number(event.target.value),
              });
            }}
          >
            <MenuItem value={0}>None</MenuItem>
            <MenuItem value={2}>2</MenuItem>
            <MenuItem value={3}>3</MenuItem>
            <MenuItem value={4}>4</MenuItem>
            <MenuItem value={5}>5</MenuItem>
            <MenuItem value={6}>6</MenuItem>
            <MenuItem value={7}>7</MenuItem>
            <MenuItem value={8}>8</MenuItem>
            <MenuItem value={9}>9</MenuItem>
            <MenuItem value={10}>10</MenuItem>
          </Select>
        </FormControl>
      </Grid>
      <Grid item xs={6}>
        <FormControl fullWidth size={size}>
          <InputLabel id="label">Schedule</InputLabel>
          <Select
            labelId="label"
            value={habit.schedule_intended}
            label="Schedule"
            onChange={(event) => {
              onHabitChange({
                schedule_intended: event.target
                  .value as HabitTemplate["schedule"],
                custom_days:
                  event.target.value === "custom" ? selectedDays : null,
              });
            }}
          >
            <MenuItem value={"daily"}>Daily</MenuItem>
            <MenuItem value={"weekdays"}>Weekdays</MenuItem>
            <MenuItem value={"weekends"}>Weekends</MenuItem>
            <MenuItem value={"custom"}>Custom</MenuItem>
          </Select>
        </FormControl>
      </Grid>
      {habit.schedule_intended === "custom" && (
        <Grid item xs={6} sx={{ display: "flex", alignItems: "center" }}>
          <ToggleButtonGroup
            value={habit.custom_days_intended?.map((day, index) => {
              if (day) {
                return index;
              }
            })}
            onChange={(_, selectedDayIndexes: number[]) => {
              const newCustomDays = [
                false,
                false,
                false,
                false,
                false,
                false,
                false,
              ];

              selectedDayIndexes.forEach((index) => {
                newCustomDays[index] = true;
              });

              onHabitChange({ custom_days_intended: newCustomDays });
            }}
            aria-label="days"
            size="small"
            fullWidth
            color="primary"
          >
            {days.map((day, dayIndex) => (
              <ToggleButton
                key={dayIndex}
                value={dayIndex}
                aria-label={day}
                sx={{ textTransform: "none" }}
              >
                {day}
              </ToggleButton>
            ))}
          </ToggleButtonGroup>
        </Grid>
      )}
    </Grid>
  );
}

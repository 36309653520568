import type { CoachActionLogEvent } from "@trainwell/features/legacy";
import { useEffect, useRef } from "react";
import { api } from "src/lib/trainwellApi";
import { selectIsClientOnboarded } from "src/slices/clientSlice";
import {
  selectIsGhosting,
  selectPrimaryTrainer,
} from "src/slices/trainerSlice";
import { useAppSelector } from "./stateHooks";

export default function useAnalytics() {
  const trainer = useAppSelector(selectPrimaryTrainer);
  const coachRef = useRef(trainer);
  const isGhosting = useAppSelector(selectIsGhosting);
  const isClientOnboarded = useAppSelector(selectIsClientOnboarded);
  const isClientOnboardedRef = useRef(isClientOnboarded);
  const isGhostingRef = useRef(isGhosting);

  const clicks = useRef<CoachActionLogEvent[]>([]);
  const keystrokes = useRef<CoachActionLogEvent[]>([]);
  const didScroll = useRef(false);

  function getScreen() {
    const paths = location.pathname.split("/");
    const firstPath = paths[1];

    let screen = "misc";

    if (firstPath === "") {
      screen = "home";
    } else if (firstPath === "templates") {
      screen = "template";
    } else if (location.search.includes("workoutId=")) {
      screen = "workout_builder_mini";
    } else if (
      firstPath === "clients" &&
      location.pathname.split("/")[3] === "workouts"
    ) {
      screen = "workout_builder";
    } else if (
      firstPath === "clients" &&
      location.pathname.split("/")[3] === "logs"
    ) {
      screen = "workout_log";
    } else if (firstPath === "clients" && !isClientOnboardedRef.current) {
      screen = "client_onboarding";
    } else if (firstPath === "clients") {
      screen = "client";
    }

    return screen;
  }

  function onClick(event: MouseEvent) {
    if (!isGhosting) {
      const coachEvent: CoachActionLogEvent = {
        screen: getScreen(),
      };

      if ((event.target as any)?.name) {
        coachEvent.element_name = (event.target as any)?.name || undefined;
      } else {
        const paths = event.composedPath();

        for (const item of paths) {
          if ((item as any)?.name) {
            coachEvent.element_name = (item as any)?.name || undefined;
            break;
          }
        }
      }

      clicks.current.push(coachEvent);
    }
  }

  function onKeystroke(event: KeyboardEvent) {
    if (!isGhosting) {
      const coachEvent: CoachActionLogEvent = {
        screen: getScreen(),
      };

      if ((event.target as any)?.name) {
        coachEvent.element_name = (event.target as any)?.name || undefined;
      }

      keystrokes.current.push(coachEvent);
    }
  }

  function onScroll(event: Event) {
    if (!isGhosting) {
      didScroll.current = true;
    }
  }

  function logAction() {
    if (!isGhostingRef.current && coachRef.current) {
      if (
        clicks.current.length > 0 ||
        keystrokes.current.length > 0 ||
        didScroll.current
      ) {
        api.trainers
          .logAction({
            trainerId: coachRef.current.trainer_id,
            clickEvents: clicks.current,
            keystrokeEvents: keystrokes.current,
            didScroll: didScroll.current,
          })
          .catch(() => undefined);

        clicks.current = [];
        keystrokes.current = [];
        didScroll.current = false;
      }
    }

    setTimeout(logAction, 1000 * 60);
  }

  useEffect(() => {
    document.addEventListener("click", onClick);
    document.addEventListener("keydown", onKeystroke);
    window.addEventListener("scroll", onScroll);

    return () => {
      document.removeEventListener("click", onClick);
      document.removeEventListener("keydown", onKeystroke);
      window.removeEventListener("scroll", onScroll);
    };
  }, []);

  useEffect(() => {
    logAction();
  }, []);

  useEffect(() => {
    coachRef.current = trainer;
  }, [trainer]);

  useEffect(() => {
    isGhostingRef.current = isGhosting;
  }, [isGhosting]);

  useEffect(() => {
    isClientOnboardedRef.current = isClientOnboarded;
  }, [isClientOnboarded]);

  return;
}

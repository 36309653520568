import { extractClosestEdge } from "@atlaskit/pragmatic-drag-and-drop-hitbox/closest-edge";
import { getReorderDestinationIndex } from "@atlaskit/pragmatic-drag-and-drop-hitbox/util/get-reorder-destination-index";
import { reorder } from "@atlaskit/pragmatic-drag-and-drop/reorder";
import type {
  BaseEventPayload,
  ElementDragType,
} from "@atlaskit/pragmatic-drag-and-drop/types";
import type { EntityState, PayloadAction } from "@reduxjs/toolkit";
import {
  createAsyncThunk,
  createEntityAdapter,
  createSelector,
  createSlice,
} from "@reduxjs/toolkit";
import type {
  Client,
  HabitTask,
  HabitTemplate,
  HabitWeek,
  ProgressMetricSummary,
  WorkoutTimes,
} from "@trainwell/features/legacy";
import { type WeekPlan } from "@trainwell/features/week-plans";
import {
  differenceInWeeks,
  getDay,
  isSameWeek,
  startOfWeek,
  subWeeks,
} from "date-fns";
import set from "lodash-es/set";
import { getCurrentWeekStartDate, getLocalDate } from "src/lib/date";
import {
  getHabitSchedule,
  getSelectedDays,
  habitWeekFromHabitTemplate,
  isProgramHabit,
  isWeekPlanCurrentWeek,
  isWeekPlanInPast,
  trainwellWorkoutHabit,
} from "src/lib/habits";
import { api } from "src/lib/trainwellApi";
import { workoutLib } from "src/lib/trainwellWorkoutLib";
import { canAddWorkoutToWeekPlan } from "src/lib/weekPlans";
import { countWorkoutsInWeekPlans } from "src/lib/workoutPlan";
import type { HabitTaskLocal } from "src/types/HabitTaskLocal";
import { dismissActionItemWithType } from "./actionItemSlice";
import { openChat } from "./chatSlice";
import { updateClientEditLocal } from "./clientEditSlice";
import {
  selectClientById,
  setClientCompletedWeeks,
  updateClientInListLocal,
} from "./clientsSlice";
import {
  addDayToPhaseEditing,
  createWorkout,
  removeWorkoutFromPhaseDay,
  selectPhaseById,
  selectWorkoutById,
  setPhaseEditing,
  updateLocalPhase,
  updateLocalWorkout,
  updatePhaseEditing,
} from "./phasesSlice";
import type { RootState } from "./store";
import { selectIsGhosting, selectPrimaryTrainer } from "./trainerSlice";

const weekPlansAdapter = createEntityAdapter<WeekPlan>({
  sortComparer: (a, b) => (b.date as string).localeCompare(a.date as string),
});

export const fetchClient = createAsyncThunk(
  "client/fetchClient",
  async (userId: string, { getState, dispatch }) => {
    const state = getState() as RootState;

    const isGhosting = selectIsGhosting(state);
    const dashMode = state.app.dashMode;
    const shouldMarkClientAsCheckedIn =
      !isGhosting && dashMode !== "programming";

    if (shouldMarkClientAsCheckedIn) {
      api.clients.updateOne({
        user_id: userId,
        date_last_trainer_check_in: new Date().toISOString(),
      });

      dispatch(
        updateClientInListLocal({
          user_id: userId,
          date_last_trainer_check_in: new Date().toISOString(),
        }),
      );
    }

    const staleClient = selectClientById(state, userId);

    if (staleClient) {
      dispatch(setClient(staleClient));
    }

    dispatch(
      openChat({
        chatId: userId,
      }),
    );

    const [clientResponse, estimatedOrms] = await Promise.all([
      api.clients.getData(userId),
      api.clients.getEstimatedOrms(userId),
    ]);
    clientResponse.client.orms_estimated = estimatedOrms;

    return {
      client: {
        ...clientResponse.client,
        ...(shouldMarkClientAsCheckedIn
          ? { date_last_trainer_check_in: new Date().toISOString() }
          : {}),
      },
    };
  },
);

export const saveWeekPlans = createAsyncThunk(
  "client/saveWeekPlans",
  async (
    data: { weekPlans: WeekPlan[]; client: Client },
    { dispatch, getState },
  ) => {
    // Client might be null when this is called, we need to pass it in
    const { weekPlans, client } = data;

    if (!weekPlans.length) {
      console.log("No week plans to save");
      return;
    }

    const state = getState() as RootState;
    const clientInfo = state.clients.clientInfo[weekPlans[0].user_id];

    const upcomingWeekPlans = weekPlans.filter(
      (weekPlan) => !isWeekPlanInPast(weekPlan.date, true),
    );

    const weekPlansToSave = JSON.parse(JSON.stringify(upcomingWeekPlans));

    await api.weekPlans.updateMany(weekPlansToSave);

    let newPlans = JSON.parse(JSON.stringify(weekPlans)) as WeekPlan[];

    const startOfWeek = getCurrentWeekStartDate();
    newPlans = newPlans
      .filter((plan) => new Date(plan.date) >= startOfWeek && plan.published)
      .sort((a, b) => (a.date > b.date ? 1 : b.date > a.date ? -1 : 0));

    let count = 0;
    let prevDate = subWeeks(getCurrentWeekStartDate(), 1);

    for (const plan of newPlans) {
      const planDate = new Date(plan.date);
      if (differenceInWeeks(planDate, prevDate) <= 1) {
        count = count + 1;
        prevDate = planDate;
      }
    }

    if (clientInfo.weeks !== count) {
      // A week was published or unpublished, update the dash's local state

      dispatch(
        setClientCompletedWeeks({ userId: client.user_id, weeks: count }),
      );
    }

    if (!client?.did_publish_first_week) {
      dispatch(
        dismissActionItemWithType({
          userId: client.user_id,
          type: "client_submitted_onboarding_survey",
        }),
      );

      dispatch(
        updateClient({
          user_id: client.user_id,
          did_publish_first_week: true,
        }),
      );
    }
  },
);

export const fetchWeekPlans = createAsyncThunk(
  "client/fetchWeekPlans",
  async (_, { getState }) => {
    const state = getState() as RootState;
    const client = state.client.client;

    if (!client) {
      throw new Error("No client");
    }

    const trainer = selectPrimaryTrainer(state);

    if (!trainer) {
      throw new Error("No trainer");
    }

    const startDate = subWeeks(getCurrentWeekStartDate(), 4);

    const habitTasksRequest = api.habitTasks.findMany({
      userId: client.user_id,
      startDate: startDate,
    });

    const habitPlansRequest = api.weekPlans.getMany({
      userId: client.user_id,
      dateLeft: startDate.toISOString(),
    });

    const [habitTasks, weekPlans] = await Promise.all([
      habitTasksRequest,
      habitPlansRequest,
    ]);

    return {
      habitTasks: habitTasks,
      weekPlans: weekPlans,
    };
  },
);

export const fetchHabitTemplates = createAsyncThunk(
  "client/fetchHabitTemplates",
  async (_, { getState }) => {
    const state = getState() as RootState;
    const client = state.client.client;

    if (!client) {
      throw new Error("No client");
    }

    const trainer = selectPrimaryTrainer(state);

    if (!trainer) {
      throw new Error("No trainer");
    }

    const habitTemplatesRequest = api.habitTemplates.getManyClient(
      client.user_id,
    );
    const coachHabitTemplatesRequest = api.habitTemplates.getManyCoach(
      trainer.trainer_id,
    );
    const trainwellHabitTemplatesRequest =
      api.habitTemplates.getManyTrainwell();

    const [habitTemplates, coachHabitTemplates, trainwellHabitTemplates] =
      await Promise.all([
        habitTemplatesRequest,
        coachHabitTemplatesRequest,
        trainwellHabitTemplatesRequest,
      ]);

    return {
      habitTemplates: [
        ...habitTemplates.habit_templates,
        ...coachHabitTemplates.habit_templates,
        ...trainwellHabitTemplates.habit_templates,
      ],
    };
  },
);

export const fetchMoreClientData = createAsyncThunk(
  "client/fetchMoreClientData",
  async (_, { getState }) => {
    const state = getState() as RootState;

    const weekPlanIds = selectWeekPlanIds(state);
    const latestWeekPlan = selectWeekPlanById(
      state,
      weekPlanIds[weekPlanIds.length - 1],
    );

    if (!latestWeekPlan) {
      throw new Error("Latest week plan not found");
    }

    const newStartDate = new Date(latestWeekPlan.date);

    const response = await api.clients.getMoreData(
      state.client.client!.user_id,
      newStartDate,
    );

    return { data: response, newStartDate: newStartDate.toISOString() };
  },
);

export const addToExerciseBlacklist = createAsyncThunk(
  "client/addToExerciseBlacklist",
  async (exerciseIds: string[], { getState, dispatch }) => {
    const state = getState() as RootState;

    if (state.client.client) {
      let exerciseBlacklist = [
        ...(state.client.client.blacklisted_exercises ?? []),
      ];
      exerciseBlacklist.push(...exerciseIds);
      exerciseBlacklist = [...new Set(exerciseBlacklist)];

      dispatch(
        updateClient({
          user_id: state.client.client.user_id,
          blacklisted_exercises: exerciseBlacklist,
        }),
      );

      return;
    } else {
      throw Error("No client found");
    }
  },
);

export const removeFromExerciseBlacklist = createAsyncThunk(
  "client/removeFromExerciseBlacklist",
  async (exerciseIds: string[], { getState, dispatch }) => {
    const state = getState() as RootState;

    if (state.client.client) {
      let exerciseBlacklist = [
        ...(state.client.client.blacklisted_exercises ?? []),
      ];
      exerciseBlacklist = exerciseBlacklist.filter(
        (exerciseId) => !exerciseIds.includes(exerciseId),
      );

      console.log("blacklist", exerciseBlacklist);

      dispatch(
        updateClient({
          user_id: state.client.client.user_id,
          blacklisted_exercises: exerciseBlacklist,
        }),
      );

      return;
    } else {
      throw Error("No client found");
    }
  },
);

export const updateClient = createAsyncThunk(
  "client/updateClient",
  async (update: Partial<Client> & Pick<Client, "user_id">, { dispatch }) => {
    const newClient = await api.clients.updateOne(update);

    dispatch(updateClientLocal(update));
    dispatch(updateClientInListLocal(update));
    dispatch(updateClientEditLocal(update));

    return newClient;
  },
);

export const updateClientEmail = createAsyncThunk(
  "client/updateClientEmail",
  async (data: { email: string; userId: string }, { dispatch }) => {
    const response = await api.clients.updateEmail(data.userId, data.email);

    dispatch(updateClientLocal({ user_id: data.userId, email: data.email }));
    dispatch(
      updateClientEditLocal({ user_id: data.userId, email: data.email }),
    );

    return response;
  },
);

export const updateClientPhoneNumber = createAsyncThunk(
  "client/updateClientPhoneNumber",
  async (data: { phoneNumber: string; userId: string }, { dispatch }) => {
    const response = await api.clients.updatePhoneNumber(
      data.userId,
      data.phoneNumber,
    );

    dispatch(
      updateClientLocal({
        user_id: data.userId,
        phone_number: data.phoneNumber,
      }),
    );
    dispatch(
      updateClientEditLocal({
        user_id: data.userId,
        phone_number: data.phoneNumber,
      }),
    );

    return response;
  },
);

// Goals

export const updateProgressMetricSummaries = createAsyncThunk(
  "client/updateProgressMetricSummaries",
  async (
    progressMetricSummary: Pick<
      ProgressMetricSummary,
      | "user_id"
      | "progress_metric_id"
      | "months_graphed"
      | "measurement_frequency"
    >[],
  ) => {
    const response = await api.progressMetricSummaries.updateMany(
      progressMetricSummary,
    );

    return response;
  },
);

// Habit templates

export const createHabitTemplate = createAsyncThunk(
  "client/createHabitTemplate",
  async (
    data: Pick<
      HabitTemplate,
      | "name"
      | "type"
      | "notes_coach_default"
      | "steps_required"
      | "schedule_intended"
      | "schedule"
      | "suggested_time"
      | "custom_days_intended"
      | "custom_days"
      | "trainer_id"
      | "user_id"
      | "created_by_trainer_id"
      | "movement_type"
    >,
  ) => {
    const response = await api.habitTemplates.createOne(data);

    return response;
  },
);

export const updateHabitTemplate = createAsyncThunk(
  "client/updateHabitTemplate",
  async (data: Partial<HabitTemplate> & Pick<HabitTemplate, "id">) => {
    const response = await api.habitTemplates.updateOne(data);

    return response;
  },
);

export const removeHabitTemplate = createAsyncThunk(
  "client/removeHabitTemplate",
  async (habitTemplateId: string) => {
    const response = await api.habitTemplates.removeOne(habitTemplateId);

    return response;
  },
);

export const restoreHabitTemplate = createAsyncThunk(
  "client/restoreHabitTemplate",
  async (data: { habitTemplateId: string; index: number }) => {
    const response = await api.habitTemplates.restoreOne(data.habitTemplateId);

    return response;
  },
);

export const duplicateHabitTemplate = createAsyncThunk(
  "client/duplicateHabitTemplate",
  async (data: { habitTemplateId: string; index: number }) => {
    const response = await api.habitTemplates.duplicateOne(
      data.habitTemplateId,
    );

    return response;
  },
);

// Workout program

export const transferProgram = createAsyncThunk(
  "client/transferProgram",
  async (
    data: {
      phaseId: string;
      weekPlanId: string;
      dayIndex: number;
      cycleCount: number;
    },
    { dispatch, getState, rejectWithValue },
  ) => {
    const state = getState() as RootState;

    const phase = selectPhaseById(state, data.phaseId);

    if (!phase) {
      throw new Error("Phase not found");
    }

    const weekPlans = selectAllWeekPlans(state);

    const programDays = new Array(data.cycleCount)
      .fill(phase.workout_ids)
      .flat();

    let availableDays = 0;

    const habitPlanIndex = weekPlans.findIndex(
      (plan) => plan.id === data.weekPlanId,
    );

    const selectedDays = state.client.client!.workout_times?.map((time) =>
      Boolean(time.has_time),
    ) ?? [false, false, false, false, false, false, false];

    for (let weekIndex = 0; weekIndex <= habitPlanIndex; weekIndex++) {
      const programHabitIndex = weekPlans[weekIndex].habit_weeks.findIndex(
        (habitWeek) => isProgramHabit(habitWeek),
      );

      if (programHabitIndex !== -1) {
        if (weekPlans[habitPlanIndex].id === weekPlans[weekIndex].id) {
          availableDays += selectedDays
            .slice(data.dayIndex, 8)
            .filter(Boolean).length;
        } else {
          availableDays += selectedDays.filter(Boolean).length;
        }
      }
    }

    console.log("days", programDays.length, availableDays);

    if (programDays.length > availableDays) {
      const additionalWeeks =
        (programDays.length - availableDays) /
        selectedDays.filter(Boolean).length;
      return rejectWithValue({
        requiredAdditionalWeeks: Math.ceil(additionalWeeks),
        ...data,
      });
    }

    weekPlans.forEach((weekPlan) => {
      const workoutsForDays = selectWorkoutsForDaysInWeek(
        state,
        weekPlan.id,
        {
          weekPlanId: data.weekPlanId,
          dayIndex: data.dayIndex,
        },
        programDays,
      );

      const newHabitPlan = JSON.parse(JSON.stringify(weekPlan)) as WeekPlan;

      const programHabitIndex = newHabitPlan.habit_weeks.findIndex(
        (habitWeek) => isProgramHabit(habitWeek),
      );

      if (programHabitIndex !== -1) {
        workoutsForDays.forEach((day, dayIndex) => {
          if (
            !newHabitPlan.habit_weeks[programHabitIndex].anchored_workout_days
          ) {
            newHabitPlan.habit_weeks[programHabitIndex].anchored_workout_days =
              [[], [], [], [], [], [], []];
          }

          if (day) {
            if (
              !newHabitPlan.habit_weeks[programHabitIndex]
                .anchored_workout_days![dayIndex]
            ) {
              newHabitPlan.habit_weeks[
                programHabitIndex
              ].anchored_workout_days![dayIndex] = [];
            }

            newHabitPlan.habit_weeks[programHabitIndex].anchored_workout_days![
              dayIndex
            ] = [
              ...new Set([
                ...newHabitPlan.habit_weeks[programHabitIndex]
                  .anchored_workout_days![dayIndex],
                ...day,
              ]),
            ];
          }
        });

        dispatch(
          updateHabitWeek({
            habitWeek: newHabitPlan.habit_weeks[programHabitIndex],
            planId: weekPlan.id,
          }),
        );
      }
    });

    return;
  },
);

// Habit tasks

export const deleteHabitTask = createAsyncThunk(
  "client/deleteHabitTask",
  async (habitTaskId: string, { getState }) => {
    const state = getState() as RootState;

    if (!state.client.client) {
      throw new Error("Client not found");
    }

    const res = await api.habitTasks.deleteOne(habitTaskId);

    return res;
  },
);

// Habit week plans

export const duplicateLatestWeekPlan = createAsyncThunk(
  "client/duplicateLatestWeekPlan",
  async (times: number, { getState, dispatch }) => {
    const state = getState() as RootState;

    if (!state.client.client) {
      throw new Error("Client not found");
    }

    const weekPlans = selectAllWeekPlans(state);

    const latestHabitWeekId = weekPlans[0].id;

    for (let i = 0; i < times; i++) {
      await dispatch(
        duplicateWeekPlan({
          weekPlanId: latestHabitWeekId,
          clearWorkouts: true,
        }),
      );
    }

    return;
  },
);

export const duplicateWeekPlan = createAsyncThunk(
  "client/duplicateWeekPlan",
  async (
    data: { weekPlanId: string; clearWorkouts?: boolean },
    { getState },
  ) => {
    const state = getState() as RootState;

    if (!state.client.client) {
      throw new Error("Client not found");
    }

    const response = await api.weekPlans.createBlank(
      state.client.client.user_id,
    );

    return response;
  },
);

export const removeWeekPlan = createAsyncThunk(
  "client/removeWeekPlan",
  async (weekPlanId: string) => {
    const response = await api.weekPlans.removeOne(weekPlanId);

    return response;
  },
);

export const createBlankWeekPlan = createAsyncThunk(
  "client/createBlankWeekPlan",
  async (userId: string) => {
    const response = await api.weekPlans.createBlank(userId);

    return response;
  },
);

export const bumpWorkoutsUp = createAsyncThunk(
  "client/bumpWorkoutsUp",
  async (_, { getState, rejectWithValue }) => {
    const state = getState() as RootState;

    if (!state.client.client) {
      return;
    }

    const weekPlans = selectAllWeekPlans(state);

    let availableDays = 0;

    // const habitPlanIndex = weekPlans.findIndex((plan) =>
    //   isWeekPlanCurrentWeek(plan.date),
    // );

    const selectedDays = state.client.client!.workout_times?.map((time) =>
      Boolean(time.has_time),
    ) ?? [false, false, false, false, false, false, false];

    for (const weekPlan of weekPlans) {
      const programHabitIndex = weekPlan.habit_weeks.findIndex((habitWeek) =>
        isProgramHabit(habitWeek),
      );

      if (programHabitIndex !== -1) {
        let minimumBound = 0;
        if (isWeekPlanCurrentWeek(weekPlan.date)) {
          minimumBound = getDay(new Date());
        }

        for (let dayIndex = 6; dayIndex >= minimumBound; dayIndex--) {
          const anchoredWorkoutDays = weekPlan.habit_weeks[programHabitIndex]
            .anchored_workout_days ?? [[], [], [], [], [], [], []];

          console.log(dayIndex, anchoredWorkoutDays);

          if (
            selectedDays[dayIndex] &&
            !anchoredWorkoutDays[dayIndex]?.length
          ) {
            availableDays++;
            return;
          } else if (
            anchoredWorkoutDays &&
            anchoredWorkoutDays.length &&
            anchoredWorkoutDays[dayIndex]?.length
          ) {
            if (selectedDays.every((day) => day === false)) {
              return rejectWithValue("No workout schedule");
            }

            return rejectWithValue("Not enough weeks");
          }
        }
      }

      if (availableDays > 0) {
        return;
      }
    }

    if (availableDays === 0) {
      return rejectWithValue("Not enough weeks");
    }

    return;
  },
);

// -
// Drag and drop
// -

export const handleDragEndInClientPage = createAsyncThunk(
  "client/handleDragEndInClientPage",
  async (
    dropResult: BaseEventPayload<ElementDragType>,
    { dispatch, getState },
  ) => {
    const state = getState() as RootState;

    const source = dropResult.source;
    const sourceType = source.data.type as string | undefined;

    const target = dropResult.location.current.dropTargets[0];

    if (!target) {
      return;
    }

    const targetType = target.data.type as string | undefined;

    if (sourceType === "habit_week" && targetType === "habit_week") {
      // Reorder habit weeks
      console.log("DND: reorder habit weeks");

      dispatch(reorderHabitWeek(dropResult));
    } else if (
      sourceType === "habit_week_source" &&
      targetType === "habit_week"
    ) {
      // Drag in a new habit week
      console.log("DND: drag in new habit week");

      const itemId = source.data.id as string;
      const habitType = source.data.habit_type as "habit" | "template";
      let habitId = itemId;

      if (habitType === "template") {
        if (!state.client.client) {
          return;
        }

        const habitIndex = state.client.habitTemplates.findIndex(
          (habit) => habit.id === itemId,
        );

        const sourceHabit = state.client.habitTemplates[habitIndex];

        if (!isProgramHabit(sourceHabit)) {
          const newHabit = await api.habitTemplates.createOne({
            ...sourceHabit,
            notes_coach_default: sourceHabit.notes_coach_default || null,
            trainer_id: null,
            user_id: state.client.client.user_id,
            created_by_trainer_id: selectPrimaryTrainer(state)!.trainer_id,
            movement_type: sourceHabit.movement_type || null,
          });

          dispatch(addHabitTemplateToLibrary(newHabit));

          habitId = newHabit.id;
        }
      }

      dispatch(
        moveHabitWeekTemplateIntoWeekPlan({
          dropResult: dropResult,
          habitTemplateId: habitId,
        }),
      );
    } else if (
      (sourceType === "workout" || sourceType === "workout_extra") &&
      (targetType === "day" || targetType === "workout_task")
    ) {
      // Drag workout into calendar from selector column
      console.log(
        "DND: drag workout into calendar from selector column or extras",
      );

      const workoutId = source.data.workoutId as string;
      const dayIndex = target.data.dayIndex as number;
      const planId = target.data.weekPlanId as string;

      let finishIndex = 0;

      if (targetType === "workout_task") {
        // Figure out where to insert the workout
        const closestEdgeOfTarget = extractClosestEdge(target.data);

        const targetIndex = target.data.index as number;

        finishIndex =
          closestEdgeOfTarget === "bottom" ? targetIndex + 1 : targetIndex;
      }

      dispatch(
        addWorkoutToWeekPlanDay({
          workoutId: workoutId,
          dayIndex: dayIndex,
          weekPlanId: planId,
          workoutIndex: finishIndex,
        }),
      );
    } else if (
      sourceType === "workout_task" &&
      (targetType === "workout_task" || targetType === "day")
    ) {
      // Reorder workout in week plan
      console.log("DND: reorder workout in week plan");

      const sourceWorkoutId = source.data.workoutId as string;

      const sourceWeekPlanId = source.data.weekPlanId as string;
      const sourceDayIndex = source.data.dayIndex as number;
      const sourceIndex = source.data.index as number;

      const targetDayIndex = target.data.dayIndex as number;
      const targetWeekPlanId = target.data.weekPlanId as string;
      const targetIndex = target.data.index as number;

      const isSameDay =
        targetDayIndex === sourceDayIndex &&
        sourceWeekPlanId === targetWeekPlanId;

      const closestEdgeOfTarget = extractClosestEdge(target.data);

      let finishIndex = 0;

      if (isSameDay) {
        finishIndex = getReorderDestinationIndex({
          startIndex: sourceIndex,
          closestEdgeOfTarget,
          indexOfTarget: targetIndex,
          axis: "vertical",
        });
      } else {
        finishIndex =
          closestEdgeOfTarget === "bottom" ? targetIndex + 1 : targetIndex;
      }

      if (sourceWeekPlanId === targetWeekPlanId) {
        // Dragging within the same week plan
        // We can move the workout
        dispatch(
          reorderWorkoutInWeekPlan({
            workoutId: sourceWorkoutId,
            destinationDayIndex: targetDayIndex,
            destinationWeekPlanId: targetWeekPlanId,
            destinationWorkoutIndex: finishIndex,
            sourceDayIndex: sourceDayIndex,
            sourceWeekPlanId: sourceWeekPlanId,
            sourceWorkoutIndex: sourceIndex,
          }),
        );
      } else {
        // Dragging to a different week
        // We should duplicate the workout
        let finishIndex = 0;

        if (targetType === "workout_task") {
          // Figure out where to insert the workout
          const closestEdgeOfTarget = extractClosestEdge(target.data);

          const targetIndex = target.data.index as number;

          finishIndex =
            closestEdgeOfTarget === "bottom" ? targetIndex + 1 : targetIndex;
        }

        dispatch(
          addWorkoutToWeekPlanDay({
            workoutId: sourceWorkoutId,
            dayIndex: targetDayIndex,
            weekPlanId: targetWeekPlanId,
            workoutIndex: finishIndex,
          }),
        );
      }
    } else if (
      sourceType === "workout_task_past" &&
      (targetType === "day" || targetType === "workout_task")
    ) {
      // Drag workout log into a new day (copy it)
      console.log("DND: drag workout log into a new day (copy it)");

      const workoutId = source.data.workoutId as string;
      const dayIndex = target.data.dayIndex as number;
      const planId = target.data.weekPlanId as string;

      let finishIndex = 0;

      if (targetType === "workout_task") {
        // Figure out where to insert the workout
        const closestEdgeOfTarget = extractClosestEdge(target.data);

        const targetIndex = target.data.index as number;

        finishIndex =
          closestEdgeOfTarget === "bottom" ? targetIndex + 1 : targetIndex;
      }

      dispatch(
        addWorkoutToWeekPlanDay({
          workoutId: workoutId,
          dayIndex: dayIndex,
          weekPlanId: planId,
          workoutIndex: finishIndex,
        }),
      );
    } else if (sourceType === "phase_day" && targetType === "phase_day") {
      // Reorder phase days
      console.log("DND: reorder phase days");

      const sourceIndex = source.data.index as number;

      const targetIndex = target.data.index as number;

      const newDays = [...state.phases.phaseEditing!.days_draggable];

      newDays.splice(targetIndex, 0, newDays.splice(sourceIndex, 1)[0]);

      dispatch(
        updatePhaseEditing({
          days_draggable: newDays,
        }),
      );
    } else if (
      sourceType === "phase_workout" &&
      targetType === "new_phase_day"
    ) {
      // Drag workout from phase into a day
      console.log("DND: Drag workout from phase into a new day");

      const sourceWorkoutId = source.data.workoutId as string;
      const sourceDayIndex = source.data.dayIndex as number;

      dispatch(
        removeWorkoutFromPhaseDay({
          dayIndex: sourceDayIndex,
          workoutId: sourceWorkoutId,
        }),
      );

      dispatch(
        addDayToPhaseEditing({
          workoutId: sourceWorkoutId,
        }),
      );
    } else if (
      sourceType === "phase_workout" &&
      (targetType === "phase_workout" || targetType === "empty_phase_day")
    ) {
      // Reorder workouts in a phase day
      console.log("DND: reorder workouts in a phase day");

      const sourceWorkoutId = source.data.workoutId as string;
      const sourceIndex = source.data.index as number;
      const sourceDayIndex = source.data.dayIndex as number;

      const targetIndex = target.data.index as number;
      const targetDayIndex = target.data.dayIndex as number;

      const closestEdgeOfTarget = extractClosestEdge(target.data);

      let finishIndex = 0;

      if (targetType === "phase_workout") {
        if (sourceDayIndex === targetDayIndex) {
          finishIndex = getReorderDestinationIndex({
            startIndex: sourceIndex,
            closestEdgeOfTarget,
            indexOfTarget: targetIndex,
            axis: "vertical",
          });
        } else {
          finishIndex =
            closestEdgeOfTarget === "bottom" ? targetIndex + 1 : targetIndex;
        }
      }

      const newDays = structuredClone(
        state.phases.phaseEditing!.days_draggable,
      );

      if (sourceDayIndex === targetDayIndex) {
        const sourceDay = newDays[sourceDayIndex];

        sourceDay.workouts = reorder({
          list: sourceDay.workouts,
          startIndex: sourceIndex,
          finishIndex: finishIndex,
        });
      } else {
        console.log("DND: move workout to a different day");

        // No duplicate workout_ids allowed
        if (
          newDays[targetDayIndex].workouts.some(
            (w) => w.workout_id === sourceWorkoutId,
          )
        ) {
          return;
        }

        newDays[targetDayIndex].workouts.splice(finishIndex, 0, {
          draggable_id: crypto.randomUUID(),
          workout_id: sourceWorkoutId,
        });

        newDays[sourceDayIndex].workouts = newDays[
          sourceDayIndex
        ].workouts.filter((_, index) => index !== sourceIndex);
      }

      dispatch(
        updatePhaseEditing({
          days_draggable: newDays,
        }),
      );
    } else if (
      (sourceType === "workout" ||
        sourceType === "workout_task" ||
        sourceType === "workout_task_past") &&
      (targetType === "empty_phase_day" ||
        targetType === "phase_workout" ||
        targetType === "new_phase_day")
    ) {
      // Drag workout into phase from selector column or calendar
      console.log(
        "DND: drag workout into phase from selector column or calendar",
      );

      const sourceWorkoutId = source.data.workoutId as string;

      const targetIndex = target.data.index as number;
      const targetDayIndex = target.data.dayIndex as number;

      const phaseEditing = state.phases.phaseEditing;

      // Delete the standalone workout cause it's in a phase now

      dispatch(
        updateLocalPhase({
          workoutId: sourceWorkoutId,
          phase: {
            deleted: true,
          },
        }),
      );

      dispatch(
        updateLocalWorkout({
          workout_id: sourceWorkoutId,
          phase_id: phaseEditing?._id ?? undefined,
        }),
      );

      if (targetType === "new_phase_day") {
        dispatch(
          addDayToPhaseEditing({
            workoutId: sourceWorkoutId,
          }),
        );

        return;
      }

      // OK now onto the actual drag and drop logic

      const closestEdgeOfTarget = extractClosestEdge(target.data);

      let finishIndex = 0;

      if (targetType === "phase_workout") {
        finishIndex =
          closestEdgeOfTarget === "bottom" ? targetIndex + 1 : targetIndex;
      }

      const newDays = structuredClone(
        state.phases.phaseEditing!.days_draggable,
      );

      // No duplicate workout_ids allowed
      if (
        newDays[targetDayIndex].workouts.some(
          (w) => w.workout_id === sourceWorkoutId,
        )
      ) {
        return;
      }

      newDays[targetDayIndex].workouts.splice(finishIndex, 0, {
        draggable_id: crypto.randomUUID(),
        workout_id: sourceWorkoutId,
      });

      dispatch(
        updatePhaseEditing({
          days_draggable: newDays,
        }),
      );
    } else if (sourceType === "phase" && targetType === "day") {
      console.log("DND: drag phase into week plan");

      const phaseId = source.data.phaseId as string;

      const targetWeekPlanId = target.data.weekPlanId as string;
      const targetDayIndex = target.data.dayIndex as number;

      return {
        transfer: true,
        dayIndex: targetDayIndex,
        phaseId: phaseId,
        weekPlanId: targetWeekPlanId,
      };
    } else if (
      sourceType === "phase_workout" &&
      targetType === "phase_workout_panel"
    ) {
      console.log("DND: drag workout back into phase panel");

      const sourceWorkoutId = source.data.workoutId as string;
      const sourceDayIndex = source.data.dayIndex as number;

      if (
        state.phases.phases.some(
          (phase) =>
            phase.type === "single" &&
            phase.workout_ids.flat().includes(sourceWorkoutId),
        )
      ) {
        console.log("DND: Local edit");

        dispatch(
          updateLocalPhase({
            workoutId: sourceWorkoutId,
            phase: {
              deleted: false,
            },
          }),
        );

        dispatch(
          removeWorkoutFromPhaseDay({
            dayIndex: sourceDayIndex,
            workoutId: sourceWorkoutId,
          }),
        );
      } else {
        console.log("DND: Server edit");

        dispatch(
          removeWorkoutFromPhaseDay({
            dayIndex: sourceDayIndex,
            workoutId: sourceWorkoutId,
          }),
        );

        dispatch(
          createWorkout({
            userId: state.client.client!.user_id,
            workoutId: sourceWorkoutId,
          }),
        );
      }
    }
  },
);

export const turnWeekIntoPhase = createAsyncThunk(
  "client/turnWeekIntoPhase",
  async (data: { weekPlanId: string }, { getState, dispatch }) => {
    const { weekPlanId } = data;

    const state = getState() as RootState;

    const weekPlan = selectWeekPlanById(state, weekPlanId);

    const workoutHabitWeek = weekPlan.habit_weeks.find(isProgramHabit);

    if (!workoutHabitWeek) {
      return;
    }

    const workoutIds = (workoutHabitWeek.anchored_workout_days ?? [])
      .flat()
      .filter(Boolean) as string[];

    for (const workoutId of workoutIds) {
      dispatch(
        updateLocalPhase({
          workoutId: workoutId,
          phase: {
            deleted: true,
          },
        }),
      );
    }

    const days = (
      (workoutHabitWeek.anchored_workout_days ?? []).filter(
        Boolean,
      ) as string[][]
    ).filter((d) => d && d.length > 0);

    const daysDraggable = days
      .filter((d) => d!.length > 0)
      .map((day) => ({
        draggable_id: crypto.randomUUID(),
        workouts: day.map((workoutId) => ({
          draggable_id: crypto.randomUUID(),
          workout_id: workoutId,
        })),
      }));

    dispatch(
      setPhaseEditing({
        _id: null,
        date_created: new Date().toISOString(),
        date_updated: new Date().toISOString(),
        date_deleted: null,
        workout_ids: [],
        days_draggable: daysDraggable,
        tags: [],
        name: "New phase",
        type: "multiple",
        user_id: state.client.client!.user_id,
      }),
    );

    return;
  },
);

export const addWeekToPhase = createAsyncThunk(
  "client/addWeekToPhase",
  async (data: { weekPlanId: string }, { getState, dispatch }) => {
    const { weekPlanId } = data;

    const state = getState() as RootState;

    const weekPlan = selectWeekPlanById(state, weekPlanId);

    const workoutHabitWeek = weekPlan.habit_weeks.find(isProgramHabit);

    if (!workoutHabitWeek) {
      return;
    }

    const workoutIds = (workoutHabitWeek.anchored_workout_days ?? [])
      .flat()
      .filter(Boolean) as string[];

    for (const workoutId of workoutIds) {
      dispatch(
        updateLocalPhase({
          workoutId: workoutId,
          phase: {
            deleted: true,
          },
        }),
      );
    }

    const days = (
      (workoutHabitWeek.anchored_workout_days ?? []).filter(
        Boolean,
      ) as string[][]
    ).filter((d) => d && d.length > 0);

    const daysDraggable = days
      .filter((d) => d!.length > 0)
      .map((day) => ({
        draggable_id: crypto.randomUUID(),
        workouts: day.map((workoutId) => ({
          draggable_id: crypto.randomUUID(),
          workout_id: workoutId,
        })),
      }));

    dispatch(
      setPhaseEditing({
        _id: null,
        date_created: new Date().toISOString(),
        date_updated: new Date().toISOString(),
        date_deleted: null,
        workout_ids: [],
        days_draggable: [
          ...state.phases.phaseEditing!.days_draggable,
          ...daysDraggable,
        ],
        tags: [],
        name: "New phase",
        type: "multiple",
        user_id: state.client.client!.user_id,
      }),
    );

    return;
  },
);

type TransferInfo = {
  dayIndex: number;
  phaseId: string;
  weekPlanId: string;
  cycleCount?: number;
};

// Define a type for the slice state
interface ClientState extends EntityState<WeekPlan, string> {
  client: Client | undefined;
  habitTemplates: HabitTemplate[];
  habitTasks: HabitTaskLocal[];
  sortedHabitTasks: Record<string, HabitTaskLocal[][]>;
  status: "idle" | "loading" | "succeeded" | "failed";
  error: string | undefined;
  startDate: string;
  loadMoreStatus: "idle" | "loading" | "failed" | "done";
  addWeekStatus: "idle" | "loading" | "succeeded" | "failed";
  habitTemplateStatus: "idle" | "loading" | "succeeded" | "failed";
  weekPlansStatus: "idle" | "loading" | "succeeded" | "failed";
  transferInfo: TransferInfo | null;
  isDraggingPhase: boolean;
  notesDialogOpen: boolean;
  notesSaving: boolean;
  equipmentDialogOpen: boolean;
  auditPanelOpen: boolean;
}

// Define the initial state using that type
const initialState: ClientState = {
  client: undefined,
  habitTemplates: [],
  habitTasks: [],
  sortedHabitTasks: {},
  status: "idle",
  error: undefined,
  startDate: subWeeks(startOfWeek(new Date()), 4).toISOString(),
  loadMoreStatus: "idle",
  addWeekStatus: "idle",
  habitTemplateStatus: "idle",
  weekPlansStatus: "idle",
  transferInfo: null,
  isDraggingPhase: false,
  notesDialogOpen: false,
  notesSaving: false,
  equipmentDialogOpen: false,
  auditPanelOpen: false,
  ...weekPlansAdapter.getInitialState(),
};

export const clientSclice = createSlice({
  name: "client",
  initialState,
  reducers: {
    resetClient: () => {
      console.log("Redux: reset client");
      return { ...initialState };
    },
    setClient: (state, action: PayloadAction<Client>) => {
      const client = action.payload;

      state.client = client;
    },
    setNotesSaving: (state, action: PayloadAction<boolean>) => {
      state.notesSaving = action.payload;
    },
    setAuditPanelOpen: (state, action: PayloadAction<boolean>) => {
      state.auditPanelOpen = action.payload;
    },
    toggleNotesDialogOpen: (state) => {
      state.notesDialogOpen = !state.notesDialogOpen;
    },
    toggleEquipmentDialogOpen: (state) => {
      state.equipmentDialogOpen = !state.equipmentDialogOpen;
    },
    setOnboarded: (state) => {
      state.client!.account.dashboard.date_onboarded = new Date().toISOString();
    },
    setTransferInfo: (state, action: PayloadAction<TransferInfo | null>) => {
      state.transferInfo = action.payload;
    },
    setIsDraggingPhase: (state, action: PayloadAction<boolean>) => {
      state.isDraggingPhase = action.payload;
    },
    togglePlanPublished: (state, action: PayloadAction<string>) => {
      const planId = action.payload;

      const weekPlan = state.entities[planId];

      if (weekPlan) {
        weekPlan.published = !weekPlan.published;
      }
    },
    setDayNotes: (
      state,
      action: PayloadAction<{
        planId: string;
        dayIndex: number;
        newNotes: string | null;
      }>,
    ) => {
      const { planId, dayIndex, newNotes } = action.payload;

      const weekPlan = state.entities[planId];

      if (weekPlan) {
        weekPlan.notes_coach_days[dayIndex] = newNotes;
      }
    },
    setHabitOverrideNotes: (
      state,
      action: PayloadAction<{
        planId: string;
        dayIndex: number;
        habitId: string;
        newNotes: string;
      }>,
    ) => {
      const { planId, dayIndex, habitId, newNotes } = action.payload;

      const weekPlan = state.entities[planId];

      if (weekPlan) {
        const habitIndex = weekPlan.habit_weeks.findIndex(
          (habit) => habit.id === habitId,
        );

        if (habitIndex !== -1) {
          weekPlan.habit_weeks[habitIndex].notes_coach_days[dayIndex] =
            newNotes;
        }
      }
    },
    addHabitWeekToNextWeek: (state, action: PayloadAction<string>) => {
      const planId = action.payload;

      const weekPlan = state.entities[planId];
      const planIndex = state.ids.indexOf(planId);

      if (weekPlan && planIndex !== 0) {
        state.entities[state.ids[planIndex - 1]]!.habit_weeks = [];

        state.entities[state.ids[planIndex]]!.habit_weeks.forEach((habit) => {
          if (
            !(
              isProgramHabit(habit) &&
              state.entities[state.ids[planIndex - 1]]!.habit_weeks.some(
                (habitWeek) => isProgramHabit(habitWeek),
              )
            )
          ) {
            const newHabit = JSON.parse(JSON.stringify(habit)) as HabitWeek;
            newHabit.id = crypto.randomUUID();

            const newSchedule = getHabitSchedule(
              newHabit.schedule_intended,
              newHabit.custom_days_intended,
              state.entities[state.ids[planIndex - 1]]!.date,
              habit.anchored_workout_days,
            );

            newHabit.schedule = newSchedule.schedule;
            newHabit.custom_days = newSchedule.custom_days;

            if (
              isProgramHabit(newHabit) &&
              (newHabit.bumped_workout_days?.length ?? 0) > 0
            ) {
              newHabit.anchored_workout_days =
                newHabit.anchored_workout_days?.map((day, dayIndex) => {
                  if (day) {
                    let newDay = [...day];

                    newDay = day.filter(
                      (workoutId) =>
                        !newHabit
                          .bumped_workout_days![
                            dayIndex
                          ].map((b) => b.workout_id)
                          .includes(workoutId),
                    );

                    if (newHabit.pulled_workout_days) {
                      newDay.push(
                        ...newHabit.pulled_workout_days[dayIndex].map(
                          (b) => b.workout_id,
                        ),
                      );
                    }

                    return newDay;
                  }

                  return null;
                }) ?? null;
            }

            newHabit.bumped_workout_days = null;
            newHabit.pulled_workout_days = null;

            state.entities[state.ids[planIndex - 1]]!.habit_weeks.push(
              newHabit,
            );
          }
        });

        state.entities[state.ids[planIndex - 1]]!.notes_coach_days =
          state.entities[state.ids[planIndex]]!.notes_coach_days;
      }
    },
    updateHabitWeek: (
      state,
      action: PayloadAction<{
        habitWeek: Partial<HabitWeek> & Pick<HabitWeek, "id">;
        planId: string;
      }>,
    ) => {
      const { habitWeek, planId } = action.payload;

      // Copy otherwise we get a crash related to immer
      const newHabitWeek = JSON.parse(
        JSON.stringify(habitWeek),
      ) as Partial<HabitWeek> & Pick<HabitWeek, "id">;

      const weekPlan = state.entities[planId];

      if (weekPlan) {
        const habitIndex = weekPlan.habit_weeks.findIndex(
          (habit) => habit.id === habitWeek.id,
        );

        if (habitIndex !== -1) {
          if (
            "schedule_intended" in newHabitWeek ||
            "custom_days_intended" in newHabitWeek
          ) {
            if (
              "schedule_intended" in newHabitWeek &&
              newHabitWeek.schedule_intended === "custom" &&
              weekPlan.habit_weeks[habitIndex].schedule_intended !== "custom"
            ) {
              newHabitWeek.custom_days_intended = getSelectedDays(
                weekPlan.habit_weeks[habitIndex].schedule_intended,
                null,
              );
            }

            const newSchedule = getHabitSchedule(
              "schedule_intended" in newHabitWeek
                ? newHabitWeek.schedule_intended!
                : weekPlan.habit_weeks[habitIndex].schedule_intended,
              "custom_days_intended" in newHabitWeek
                ? (newHabitWeek.custom_days_intended ?? null)
                : weekPlan.habit_weeks[habitIndex].custom_days_intended,
              weekPlan.date,
              isProgramHabit(weekPlan.habit_weeks[habitIndex])
                ? weekPlan.habit_weeks[habitIndex].anchored_workout_days
                : undefined,
            );

            newHabitWeek.schedule = newSchedule.schedule;
            newHabitWeek.custom_days = newSchedule.custom_days;
          }

          if (
            "schedule_intended" in newHabitWeek &&
            newHabitWeek.schedule_intended !== "custom"
          ) {
            newHabitWeek.custom_days_intended = null;
          }

          if (
            "schedule" in newHabitWeek &&
            newHabitWeek.schedule !== "custom"
          ) {
            newHabitWeek.custom_days = null;
          }

          weekPlan.habit_weeks[habitIndex] = {
            ...weekPlan.habit_weeks[habitIndex],
            ...newHabitWeek,
          };
        }
      }
    },
    updateClientLocal: (state, action: PayloadAction<Partial<Client>>) => {
      const update = action.payload;

      if (state.client && state.client.user_id === update.user_id) {
        for (const [key, value] of Object.entries(update)) {
          set(state.client, key, value);
        }
      }
    },
    removeHabitWeekFromPlan: (
      state,
      action: PayloadAction<{
        habitWeekId: string;
        weekPlanId: string;
      }>,
    ) => {
      console.log("Redux: Remove habit week from plan");
      const { habitWeekId, weekPlanId } = action.payload;

      const weekPlan = state.entities[weekPlanId];

      if (weekPlan) {
        if (weekPlan.habit_weeks.length <= 1) {
          weekPlan.published = false;
        }

        const habitWeekIndex = weekPlan.habit_weeks.findIndex(
          (habit) => habit.id === habitWeekId,
        );

        if (habitWeekIndex !== -1) {
          weekPlan.habit_weeks.splice(habitWeekIndex, 1);
        }
      }
    },
    clearWeekPlan: (state, action: PayloadAction<string>) => {
      const weekPlanId = action.payload;

      const weekPlan = state.entities[weekPlanId];

      if (weekPlan) {
        weekPlan.habit_weeks = [];
        weekPlan.published = false;
        weekPlan.notes_coach_days = [null, null, null, null, null, null, null];
      }
    },
    clearWorkoutHabitWeek: (
      state,
      action: PayloadAction<{ weekPlanId: string }>,
    ) => {
      const { weekPlanId } = action.payload;

      const weekPlan = state.entities[weekPlanId];

      const habitWeek = weekPlan?.habit_weeks.find(isProgramHabit);

      if (!habitWeek) {
        return;
      }

      habitWeek.anchored_workout_days = [];
    },
    addHabitTemplateToLibrary: (
      state,
      action: PayloadAction<HabitTemplate>,
    ) => {
      const newTemplate = action.payload;

      state.habitTemplates.push(newTemplate);
    },
    removeWorkoutFromWeekPlanDay: (
      state,
      action: PayloadAction<{
        weekPlanId: string;
        dayIndex: number;
        workoutId: string;
      }>,
    ) => {
      const { weekPlanId, dayIndex, workoutId } = action.payload;

      const weekPlan = state.entities[weekPlanId];

      if (!weekPlan) {
        return;
      }

      const habitWeek = weekPlan.habit_weeks.find((habitWeek) =>
        isProgramHabit(habitWeek),
      );

      if (!habitWeek) {
        return;
      }

      const workoutIndex =
        habitWeek.anchored_workout_days![dayIndex]?.indexOf(workoutId);

      if (workoutIndex === undefined || workoutIndex === -1) {
        return;
      }

      habitWeek.anchored_workout_days![dayIndex]?.splice(workoutIndex, 1);
    },
    addWorkoutToWeekPlanDay: (
      state,
      action: PayloadAction<{
        weekPlanId: string;
        dayIndex: number;
        workoutIndex: number;
        workoutId: string;
      }>,
    ) => {
      const { weekPlanId, dayIndex, workoutId, workoutIndex } = action.payload;

      const weekPlan = state.entities[weekPlanId];

      const canAdd = canAddWorkoutToWeekPlan({
        workoutId: workoutId,
        dayIndex: dayIndex,
        weekPlan: weekPlan,
      });

      if (!canAdd) {
        return;
      }

      const habitWeek = weekPlan.habit_weeks.find((habitWeek) =>
        isProgramHabit(habitWeek),
      );

      if (!habitWeek) {
        return;
      }

      if (!habitWeek.anchored_workout_days) {
        habitWeek.anchored_workout_days = [
          null,
          null,
          null,
          null,
          null,
          null,
          null,
        ];
      }

      if (!habitWeek.anchored_workout_days![dayIndex]) {
        habitWeek.anchored_workout_days![dayIndex] = [];
      }

      habitWeek.anchored_workout_days![dayIndex]!.splice(
        workoutIndex,
        0,
        workoutId,
      );
    },
    reorderWorkoutInWeekPlan: (
      state,
      action: PayloadAction<{
        sourceWeekPlanId: string;
        sourceDayIndex: number;
        sourceWorkoutIndex: number;
        destinationWeekPlanId: string;
        destinationDayIndex: number;
        destinationWorkoutIndex: number;
        workoutId: string;
      }>,
    ) => {
      const {
        workoutId,
        sourceDayIndex,
        sourceWeekPlanId,
        sourceWorkoutIndex,
        destinationDayIndex,
        destinationWeekPlanId,
        destinationWorkoutIndex,
      } = action.payload;

      const sourceWeekPlan = state.entities[sourceWeekPlanId];
      const destinationWeekPlan = state.entities[destinationWeekPlanId];

      if (!sourceWeekPlan || !destinationWeekPlan) {
        return;
      }

      const sourceHabitWeek = sourceWeekPlan.habit_weeks.find(isProgramHabit);

      const destinationHabitWeek =
        destinationWeekPlan.habit_weeks.find(isProgramHabit);

      if (!sourceHabitWeek || !destinationHabitWeek) {
        return;
      }

      if (!destinationHabitWeek.anchored_workout_days) {
        destinationHabitWeek.anchored_workout_days = [
          [],
          [],
          [],
          [],
          [],
          [],
          [],
        ];
      }

      if (!destinationHabitWeek.anchored_workout_days![destinationDayIndex]) {
        destinationHabitWeek.anchored_workout_days![destinationDayIndex] = [];
      }

      if (
        !(
          destinationDayIndex === sourceDayIndex &&
          destinationWeekPlanId === sourceWeekPlanId
        ) &&
        destinationHabitWeek.anchored_workout_days![
          destinationDayIndex
        ]!.includes(workoutId)
      ) {
        return;
      }

      if (
        sourceDayIndex === destinationDayIndex &&
        sourceWeekPlanId === destinationWeekPlanId
      ) {
        const newWorkoutDays = reorder({
          list: destinationHabitWeek.anchored_workout_days![
            destinationDayIndex
          ]!,
          startIndex: sourceWorkoutIndex,
          finishIndex: destinationWorkoutIndex,
        });

        destinationHabitWeek.anchored_workout_days![destinationDayIndex] =
          newWorkoutDays;
      } else {
        // Remove the source
        sourceHabitWeek.anchored_workout_days![sourceDayIndex]!.splice(
          sourceWorkoutIndex,
          1,
        );

        // Add to destination
        destinationHabitWeek.anchored_workout_days![
          destinationDayIndex
        ]!.splice(destinationWorkoutIndex, 0, workoutId);
      }
    },
    reorderHabitWeek: (
      state,
      action: PayloadAction<BaseEventPayload<ElementDragType>>,
    ) => {
      const dropResult = action.payload;

      const source = dropResult.source;

      const target = dropResult.location.current.dropTargets[0];

      if (!target) {
        return;
      }

      const sourceWeekPlanId = source.data.week_plan_id as string;
      const sourceHabitIndex = source.data.index as number;

      const targetWeekPlanId = target.data.week_plan_id as string;
      const targetHabitIndex = target.data.index as number;

      const closestEdgeOfTarget = extractClosestEdge(target.data);

      let finishIndex = 0;

      if (sourceWeekPlanId === targetWeekPlanId) {
        finishIndex = getReorderDestinationIndex({
          startIndex: sourceHabitIndex,
          closestEdgeOfTarget,
          indexOfTarget: targetHabitIndex,
          axis: "vertical",
        });
      } else {
        finishIndex =
          closestEdgeOfTarget === "bottom"
            ? targetHabitIndex + 1
            : targetHabitIndex;
      }

      const sourceWeekPlan = state.entities[sourceWeekPlanId];

      const destinationWeekPlan = state.entities[targetWeekPlanId];

      if (sourceWeekPlan && destinationWeekPlan) {
        const tempHabit = sourceWeekPlan.habit_weeks[sourceHabitIndex];

        if (
          isProgramHabit(tempHabit) &&
          sourceWeekPlan.id !== destinationWeekPlan.id &&
          destinationWeekPlan.habit_weeks.filter((habitWeek) =>
            isProgramHabit(habitWeek),
          ).length >= 1
        ) {
          // Don't allow dragging a workout program into a new week that already has a program habit
          return;
        }

        if (sourceWeekPlan.id === destinationWeekPlan.id) {
          sourceWeekPlan.habit_weeks.splice(sourceHabitIndex, 1);
        } else {
          tempHabit.id = crypto.randomUUID();
        }

        const newSchedule = getHabitSchedule(
          tempHabit.schedule_intended,
          tempHabit.custom_days_intended,
          destinationWeekPlan.date,
        );

        destinationWeekPlan.habit_weeks.splice(finishIndex, 0, {
          ...tempHabit,
          schedule: newSchedule.schedule,
          custom_days: newSchedule.custom_days,
        });
      }
    },
    moveHabitWeekTemplateIntoWeekPlan: (
      state,
      action: PayloadAction<{
        dropResult: BaseEventPayload<ElementDragType>;
        habitTemplateId: string;
      }>,
    ) => {
      const { dropResult, habitTemplateId } = action.payload;

      const target = dropResult.location.current.dropTargets[0];

      if (!target) {
        return;
      }

      const targetWeekPlanId = target.data.week_plan_id as string;
      const targetIndex = target.data.index as number;

      let sourceHabit = JSON.parse(
        JSON.stringify(
          state.habitTemplates.find((habit) => habit.id === habitTemplateId),
        ),
      ) as HabitTemplate;

      if (!sourceHabit && habitTemplateId === trainwellWorkoutHabit.id) {
        sourceHabit = trainwellWorkoutHabit;
      }

      const targetWeekPlan = state.entities[targetWeekPlanId];

      if (!sourceHabit || !targetWeekPlan) {
        return;
      }

      if (
        isProgramHabit(sourceHabit) &&
        targetWeekPlan.habit_weeks.some((habit) => isProgramHabit(habit))
      ) {
        return;
      }

      const newHabitWeek = habitWeekFromHabitTemplate({
        habitTemplate: sourceHabit,
        clientWorkoutTimes: state.client?.workout_times,
        planDate: targetWeekPlan.date,
      });

      const closestEdgeOfTarget = extractClosestEdge(target.data);

      const finishIndex =
        closestEdgeOfTarget === "bottom" ? targetIndex + 1 : targetIndex;

      targetWeekPlan.habit_weeks.splice(finishIndex, 0, newHabitWeek);
    },
  },
  extraReducers: (builder) => {
    builder.addCase(fetchClient.pending, (state) => {
      Object.assign(state, initialState);

      state.status = "loading";
    });
    builder.addCase(fetchClient.fulfilled, (state, action) => {
      console.log("Redux: Got client");
      state.status = "succeeded";

      const { client } = action.payload;

      if (!("quick_notes" in client)) {
        client.quick_notes = "";
      }

      if (!("tags" in client)) {
        client.tags = [];
      }

      if (!client.workout_times) {
        const convertedAvailability: WorkoutTimes[] = [];

        for (let i = 0; i < 7; i++) {
          const availableDay: WorkoutTimes = {
            time: null,
            has_time: false,
            anytime: null,
          };

          convertedAvailability.push(availableDay);
        }

        client.workout_times = convertedAvailability;
      }

      if (!client.equipment_detailed || client.equipment_detailed === null) {
        client.equipment_detailed = {};
      }

      //Combine all maxes together
      const userMaxes: Record<string, number> = {};
      if (client.orms_estimated) {
        for (const key in client.orms_estimated) {
          userMaxes[key] = client.orms_estimated[key];
        }
      }

      for (const key in client.best_sets) {
        userMaxes[key] = client.best_sets[key].estimated_orm;
      }
      client.maxes = userMaxes;

      state.client = client;
    });
    builder.addCase(fetchWeekPlans.pending, (state) => {
      state.weekPlansStatus = "loading";
    });
    builder.addCase(fetchWeekPlans.fulfilled, (state, action) => {
      console.log("Redux: Got client");
      state.weekPlansStatus = "succeeded";

      const { weekPlans, habitTasks } = action.payload;

      const sortedTasks: Record<string, HabitTask[][]> = {};

      weekPlans.forEach((plan) => {
        sortedTasks[plan.id] = [[], [], [], [], [], [], []];
      });

      habitTasks.forEach((task) => {
        const planIndex = weekPlans.findIndex((plan) =>
          isSameWeek(getLocalDate(plan.date), getLocalDate(task.date)),
        );

        if (planIndex !== -1) {
          const planId = weekPlans[planIndex].id;

          sortedTasks[planId][new Date(task.date).getUTCDay()].push(task);
        }
      });

      // Check for orphaned habit tasks and add workout program if needed
      for (const weekPlan of weekPlans) {
        if (
          weekPlan.habit_weeks.some((habitWeek) => isProgramHabit(habitWeek))
        ) {
          continue;
        }

        const tasks = sortedTasks[weekPlan.id];

        if (!tasks) {
          continue;
        }

        for (const habitTasksDay of sortedTasks[weekPlan.id]) {
          if (habitTasksDay.some((task) => task.habit_week_id === null)) {
            const programHabit = JSON.parse(
              JSON.stringify(trainwellWorkoutHabit),
            ) as HabitTemplate;

            const newHabitWeek: HabitWeek = {
              id: crypto.randomUUID(),
              name: programHabit.name,
              date_created: programHabit.date_created,
              type: programHabit.type,
              movement_type: programHabit.movement_type,
              anchored_workout_days: null,
              bumped_workout_days: null,
              pulled_workout_days: null,
              notes_coach_default: programHabit.notes_coach_default || null,
              suggested_time: programHabit.suggested_time,
              steps_required: programHabit.steps_required,
              schedule: "custom",
              schedule_intended: "custom",
              custom_days: [false, false, false, false, false, false, false],
              custom_days_intended: [
                false,
                false,
                false,
                false,
                false,
                false,
                false,
              ],
              date_deleted: null,
              notes_coach_days: [null, null, null, null, null, null, null],
              metric_completion: null,
            };

            weekPlan.habit_weeks.push(newHabitWeek);

            break;
          }
        }
      }

      weekPlansAdapter.upsertMany(state, weekPlans);
      state.habitTasks = habitTasks;
      state.sortedHabitTasks = sortedTasks;
    });
    builder.addCase(fetchWeekPlans.rejected, (state) => {
      state.weekPlansStatus = "failed";
    });
    builder.addCase(fetchHabitTemplates.pending, (state) => {
      state.habitTemplateStatus = "loading";
    });
    builder.addCase(fetchHabitTemplates.fulfilled, (state, action) => {
      const user = state.client as Client;
      const { habitTemplates } = action.payload;

      state.habitTemplateStatus = "succeeded";

      const clientWorkoutHabit = JSON.parse(
        JSON.stringify(trainwellWorkoutHabit),
      ) as HabitTemplate;
      if (user.workout_times) {
        clientWorkoutHabit.schedule = "custom";
        clientWorkoutHabit.custom_days = [
          false,
          false,
          false,
          false,
          false,
          false,
          false,
        ];

        user.workout_times.forEach((day, i) => {
          if (day.has_time) {
            clientWorkoutHabit.custom_days![i] = true;
          }
        });
      } else {
        clientWorkoutHabit.schedule = "weekdays";
      }

      state.habitTemplates = [clientWorkoutHabit, ...habitTemplates];
    });
    builder.addCase(fetchHabitTemplates.rejected, (state) => {
      state.habitTemplateStatus = "failed";
    });
    builder.addCase(
      updateProgressMetricSummaries.fulfilled,
      (state, action) => {
        const { goal } = action.payload;

        state.client!.goal = goal;
      },
    );
    builder.addCase(fetchMoreClientData.pending, (state) => {
      state.loadMoreStatus = "loading";
    });
    builder.addCase(fetchMoreClientData.fulfilled, (state, action) => {
      console.log("Redux: Got more client data");

      const { weekPlans, habitTasks } = action.payload.data;
      const { newStartDate } = action.payload;

      if (weekPlans.length >= 1) {
        state.loadMoreStatus = "idle";
      } else {
        state.loadMoreStatus = "done";
      }

      const sortedTasks: Record<string, HabitTask[][]> =
        state.sortedHabitTasks ?? {};

      weekPlans.forEach((plan) => {
        if (!sortedTasks[plan.id]) {
          sortedTasks[plan.id] = [[], [], [], [], [], [], []];
        }
      });

      habitTasks.forEach((task) => {
        const planIndex = weekPlans.findIndex((plan) =>
          isSameWeek(getLocalDate(plan.date), getLocalDate(task.date)),
        );

        if (planIndex !== -1) {
          const planId = weekPlans[planIndex].id;

          sortedTasks[planId][new Date(task.date).getUTCDay()].push(task);
        }
      });

      state.habitTasks.push(...habitTasks);
      weekPlansAdapter.upsertMany(state, weekPlans);
      // state.weekPlans.push(...weekPlans);
      state.sortedHabitTasks = sortedTasks;
      state.startDate = newStartDate;
    });
    builder.addCase(fetchMoreClientData.rejected, (state) => {
      state.loadMoreStatus = "done";
    });
    builder.addCase(fetchClient.rejected, (state, action) => {
      state.status = "failed";
      state.error = action.error.message;
    });
    builder.addCase(createHabitTemplate.fulfilled, (state, action) => {
      const habitTemplate = action.payload;

      state.habitTemplates.unshift(habitTemplate);
    });
    builder.addCase(updateHabitTemplate.fulfilled, (state, action) => {
      const habitTemplate = action.payload;

      const habitTemplateIndex = state.habitTemplates.findIndex(
        (habit) => habit.id === habitTemplate.id,
      );

      if (habitTemplateIndex !== -1) {
        state.habitTemplates[habitTemplateIndex] = habitTemplate;
      }
    });
    builder.addCase(removeHabitTemplate.fulfilled, (state, action) => {
      const habitTemplateId = action.meta.arg;

      const habitTemplateIndex = state.habitTemplates.findIndex(
        (habit) => habit.id === habitTemplateId,
      );

      if (habitTemplateIndex !== -1) {
        state.habitTemplates.splice(habitTemplateIndex, 1);
      }
    });
    builder.addCase(restoreHabitTemplate.fulfilled, (state, action) => {
      const habitTemplate = action.payload;
      const { index } = action.meta.arg;

      state.habitTemplates.splice(index, 0, habitTemplate);
    });
    builder.addCase(deleteHabitTask.fulfilled, (state, action) => {
      const habitTaskId = action.meta.arg;

      state.habitTasks = state.habitTasks.filter(
        (task) => task.id !== habitTaskId,
      );

      state.sortedHabitTasks = Object.fromEntries(
        Object.entries(state.sortedHabitTasks).map(([key, value]) => [
          key,
          value.map((day) => day.filter((task) => task.id !== habitTaskId)),
        ]),
      );
    });
    builder.addCase(duplicateHabitTemplate.fulfilled, (state, action) => {
      const habitTemplate = action.payload;
      const { index } = action.meta.arg;

      state.habitTemplates.splice(index, 0, habitTemplate);
    });
    builder.addCase(createBlankWeekPlan.pending, (state) => {
      state.addWeekStatus = "loading";
    });
    builder.addCase(createBlankWeekPlan.fulfilled, (state, action) => {
      state.addWeekStatus = "succeeded";
      const weekPlan = action.payload;

      weekPlansAdapter.sortComparer = (a, b) =>
        (a.date as string).localeCompare(b.date as string);

      weekPlansAdapter.addOne(state, weekPlan);
    });
    builder.addCase(createBlankWeekPlan.rejected, (state) => {
      state.addWeekStatus = "failed";
    });
    builder.addCase(duplicateWeekPlan.pending, (state) => {
      state.addWeekStatus = "loading";
    });
    builder.addCase(duplicateWeekPlan.fulfilled, (state, action) => {
      state.addWeekStatus = "succeeded";
      const newWeekPlan = action.payload;

      const { weekPlanId: originalWeekPlanId, clearWorkouts } = action.meta.arg;
      const originalWeekPlanIndex = state.entities[originalWeekPlanId];

      newWeekPlan.habit_weeks = [];

      if (originalWeekPlanIndex) {
        originalWeekPlanIndex.habit_weeks.forEach((habit) => {
          if (
            !(
              isProgramHabit(habit) &&
              newWeekPlan.habit_weeks.some((habitWeek) =>
                isProgramHabit(habitWeek),
              )
            )
          ) {
            const newHabit = JSON.parse(JSON.stringify(habit)) as HabitWeek;
            newHabit.id = crypto.randomUUID();

            const newSchedule = getHabitSchedule(
              newHabit.schedule_intended,
              newHabit.custom_days_intended,
              newWeekPlan.date,
              newHabit.anchored_workout_days,
            );

            newHabit.schedule = newSchedule.schedule;
            newHabit.custom_days = newSchedule.custom_days;

            if (
              isProgramHabit(newHabit) &&
              (newHabit.bumped_workout_days?.length ?? 0) > 0
            ) {
              newHabit.anchored_workout_days =
                newHabit.anchored_workout_days?.map((day, dayIndex) => {
                  if (day) {
                    let newDay = [...day];

                    newDay = day.filter(
                      (workoutId) =>
                        !newHabit
                          .bumped_workout_days![
                            dayIndex
                          ].map((b) => b.workout_id)
                          .includes(workoutId),
                    );

                    if (newHabit.pulled_workout_days) {
                      newDay.push(
                        ...newHabit.pulled_workout_days[dayIndex].map(
                          (b) => b.workout_id,
                        ),
                      );
                    }

                    return newDay;
                  }

                  return null;
                }) ?? null;
            }

            newHabit.bumped_workout_days = null;
            newHabit.pulled_workout_days = null;

            newWeekPlan.habit_weeks.push(newHabit);
          }
        });

        newWeekPlan.notes_coach_days = originalWeekPlanIndex.notes_coach_days;

        const workoutProgramHabitIndex = newWeekPlan.habit_weeks.findIndex(
          (habitWeek) => isProgramHabit(habitWeek),
        );

        if (workoutProgramHabitIndex !== -1) {
          if (clearWorkouts) {
            newWeekPlan.habit_weeks[
              workoutProgramHabitIndex
            ].anchored_workout_days = null;
          }
        }

        weekPlansAdapter.addOne(state, newWeekPlan);
      }
    });
    builder.addCase(duplicateWeekPlan.rejected, (state) => {
      state.addWeekStatus = "failed";
    });
    builder.addCase(removeWeekPlan.fulfilled, (state, action) => {
      const weekPlanId = action.meta.arg;

      weekPlansAdapter.removeOne(state, weekPlanId);
    });
    builder.addCase(bumpWorkoutsUp.fulfilled, (state) => {
      // Moves day of workouts to the next day that has workouts
      // The last workout moves to the next day in the client's default schedule

      const weekPlans = state.ids.map((id) => state.entities[id]!);

      const currentHabitWeekIndex = weekPlans.findIndex((plan) =>
        isWeekPlanCurrentWeek(plan.date),
      );

      let workoutsToMove: string[] = [];
      let lastFoundWorkout: { weekIndex: number; dayIndex: number } | null =
        null;

      for (let weekIndex = currentHabitWeekIndex; weekIndex >= 0; weekIndex--) {
        const programHabitIndex = weekPlans[weekIndex].habit_weeks.findIndex(
          (habitWeek) => isProgramHabit(habitWeek),
        );

        if (
          programHabitIndex !== -1 &&
          weekPlans[weekIndex].habit_weeks[programHabitIndex]
            .anchored_workout_days
        ) {
          let minimumBound = 0;

          if (weekIndex === currentHabitWeekIndex) {
            minimumBound = getDay(new Date());
          }

          for (let dayIndex = minimumBound; dayIndex < 7; dayIndex++) {
            if (
              weekPlans[weekIndex].habit_weeks[programHabitIndex]
                .anchored_workout_days![dayIndex] &&
              weekPlans[weekIndex].habit_weeks[programHabitIndex]
                .anchored_workout_days![dayIndex]!.length > 0
            ) {
              // Found workouts to move

              const oldWorkouts =
                weekPlans[weekIndex].habit_weeks[programHabitIndex]
                  .anchored_workout_days![dayIndex]!;

              weekPlans[weekIndex].habit_weeks[
                programHabitIndex
              ].anchored_workout_days![dayIndex] = workoutsToMove;

              workoutsToMove = oldWorkouts;

              lastFoundWorkout = { weekIndex: weekIndex, dayIndex: dayIndex };
            }
          }
        }
      }

      if (workoutsToMove.length > 0 && lastFoundWorkout) {
        // We need to find a spot for the remaining workout

        const selectedDays = state.client!.workout_times?.map((time) =>
          Boolean(time.has_time),
        ) ?? [false, false, false, false, false, false, false];

        for (
          let weekIndex = lastFoundWorkout.weekIndex;
          weekIndex >= 0;
          weekIndex--
        ) {
          const programHabitIndex = weekPlans[weekIndex].habit_weeks.findIndex(
            (habitWeek) => isProgramHabit(habitWeek),
          );

          if (programHabitIndex !== -1) {
            let minimumBound = 0;

            if (weekIndex === lastFoundWorkout.weekIndex) {
              minimumBound = lastFoundWorkout.dayIndex + 1;
            }

            for (let dayIndex = minimumBound; dayIndex < 7; dayIndex++) {
              if (selectedDays[dayIndex]) {
                if (
                  !weekPlans[weekIndex].habit_weeks[programHabitIndex]
                    .anchored_workout_days
                ) {
                  weekPlans[weekIndex].habit_weeks[
                    programHabitIndex
                  ].anchored_workout_days = [[], [], [], [], [], [], []];
                }

                weekPlans[weekIndex].habit_weeks[
                  programHabitIndex
                ].anchored_workout_days![dayIndex] = workoutsToMove;

                return;
              }
            }
          }
        }
      }
    });
  },
});

// Action creators are generated for each case reducer function
export const {
  resetClient,
  setClient,
  setDayNotes,
  setHabitOverrideNotes,
  reorderHabitWeek,
  moveHabitWeekTemplateIntoWeekPlan,
  addWorkoutToWeekPlanDay,
  reorderWorkoutInWeekPlan,
  updateClientLocal,
  addHabitWeekToNextWeek,
  removeHabitWeekFromPlan,
  updateHabitWeek,
  setOnboarded,
  togglePlanPublished,
  setNotesSaving,
  toggleNotesDialogOpen,
  setAuditPanelOpen,
  toggleEquipmentDialogOpen,
  addHabitTemplateToLibrary,
  removeWorkoutFromWeekPlanDay,
  clearWeekPlan,
  clearWorkoutHabitWeek,
  setTransferInfo,
  setIsDraggingPhase,
} = clientSclice.actions;

export default clientSclice.reducer;

export const selectHabitTaskById = (state: RootState, taskId: string) =>
  state.client.habitTasks.find((task) => task.id === taskId);

export const selectSortedHabitTasks = (state: RootState) =>
  state.client.sortedHabitTasks;

export const selectClientEquipment = (state: RootState) =>
  state.workout.isTemplate
    ? undefined
    : state.client.client?.equipment_detailed;

export const selectClientWeightSystem = (state: RootState) =>
  state.workout.isTemplate
    ? "imperial"
    : (state.client.client?.preferred_weight_system ?? "imperial");

export const selectClientMaxes = (state: RootState) =>
  state.workout.isTemplate ? undefined : (state.client.client?.maxes ?? {});

export const {
  selectAll: selectAllWeekPlans,
  selectById: selectWeekPlanById,
  selectIds: selectWeekPlanIds,
  // Pass in a selector that returns the posts slice of state
} = weekPlansAdapter.getSelectors((state: RootState) => state.client);

type SelectHabitTasksForDayInput = {
  planId: string;
  dayIndex: number;
  habitWeekId: string;
};
export const makeSelectHabitTasksForDay = () => {
  const selectHabitTasksForDay = createSelector(
    [
      selectAllWeekPlans,
      selectSortedHabitTasks,
      (_: RootState, data: SelectHabitTasksForDayInput) => {
        return JSON.stringify(data);
      },
    ],
    (weekPlans, sortedHabitTasks, dataString) => {
      const data = JSON.parse(dataString) as SelectHabitTasksForDayInput;
      const planIndex = weekPlans.findIndex((plan) => plan.id === data.planId);

      if (planIndex === -1) {
        return [];
      }

      const habitWeekIndex = weekPlans[planIndex].habit_weeks.findIndex(
        (habitWeek) => habitWeek.id === data.habitWeekId,
      );

      if (habitWeekIndex === -1) {
        return [];
      }

      const isProgram = isProgramHabit(
        weekPlans[planIndex].habit_weeks[habitWeekIndex],
      );

      if (sortedHabitTasks[data.planId]) {
        if (!isProgram) {
          return sortedHabitTasks[data.planId][data.dayIndex].filter(
            (task) => task.habit_week_id === data.habitWeekId,
          );
        } else {
          return sortedHabitTasks[data.planId][data.dayIndex].filter(
            (task) =>
              task.movement_type === "copilot_workout" ||
              task.habit_week_id === data.habitWeekId ||
              !task.habit_week_id,
          );
          // return sortedHabitTasks[data.planId][data.dayIndex].filter(
          //   (task) =>
          //     task.habit_week_id === data.habitWeekId || !task.habit_week_id
          // );
        }
      } else {
        return [];
      }
    },
  );

  return selectHabitTasksForDay;
};

export const selectHabitLibrary = (state: RootState) =>
  state.client.habitTemplates;

export const selectWorkoutsForDaysInWeek = (
  state: RootState,
  planId: string,
  startFrom: { weekPlanId: string; dayIndex: number },
  programDays: string[][],
) => {
  const weekPlans = selectAllWeekPlans(state);
  // const workoutProgram = state.client.client?.queued_program_phases ?? [];
  // const programDays: string[][] = [];
  const offsetProgramDays = 0;

  // workoutProgram.forEach((node) => {
  //   Array.from(Array(node.cycle_count).keys()).forEach((_, cycleIndex) => {
  //     node.days?.forEach((day) => {
  //       programDays.push(day);
  //     });
  //   });
  // });

  const thisWeeksPlanIndex = weekPlans.findIndex(
    (plan) => plan.id === startFrom.weekPlanId,
  );

  const targetPlanIndex = weekPlans.findIndex((plan) => plan.id === planId);

  let dayDif = 0;

  if (targetPlanIndex < thisWeeksPlanIndex) {
    // Target is more recent than current

    for (let i = thisWeeksPlanIndex; i > targetPlanIndex; i--) {
      const programHabitIndex = weekPlans[i].habit_weeks.findIndex(
        (habitWeek) => isProgramHabit(habitWeek),
      );

      if (programHabitIndex !== -1) {
        const selectedDays = state.client.client!.workout_times?.map((time) =>
          Boolean(time.has_time),
        ) ?? [false, false, false, false, false, false, false];

        if (weekPlans[i].id === startFrom.weekPlanId) {
          const num = selectedDays
            .slice(startFrom.dayIndex, 8)
            .filter(Boolean).length;
          dayDif += num;
        } else {
          const num = selectedDays.filter(Boolean).length;
          dayDif += num;
        }
      }
    }
  } else if (targetPlanIndex === thisWeeksPlanIndex) {
    const programHabitIndex = weekPlans[targetPlanIndex].habit_weeks.findIndex(
      (habitWeek) => isProgramHabit(habitWeek),
    );

    if (programHabitIndex !== -1) {
      const selectedDays = state.client.client!.workout_times?.map((time) =>
        Boolean(time.has_time),
      ) ?? [false, false, false, false, false, false, false];

      dayDif -= selectedDays
        .slice(0, startFrom.dayIndex)
        .filter(Boolean).length;
    }
  }

  dayDif += offsetProgramDays;

  const programHabitIndex = weekPlans[targetPlanIndex].habit_weeks.findIndex(
    (habitWeek) => isProgramHabit(habitWeek),
  );

  if (programHabitIndex !== -1) {
    const selectedDays = state.client.client!.workout_times?.map((time) =>
      Boolean(time.has_time),
    ) ?? [false, false, false, false, false, false, false];

    const workouts: string[][] = [];

    selectedDays.forEach((day, dayIndex) => {
      if (
        targetPlanIndex > thisWeeksPlanIndex ||
        (targetPlanIndex === thisWeeksPlanIndex &&
          dayIndex < startFrom.dayIndex)
      ) {
        workouts.push([]);
      } else {
        if (day) {
          workouts.push(
            programDays[
              selectedDays.slice(0, dayIndex).filter(Boolean).length + dayDif
            ],
          );
        } else {
          workouts.push([]);
        }
      }
    });

    return workouts;
  }

  return [];
};

export const selectClientErrors = createSelector(
  [
    selectAllWeekPlans,
    (state: RootState) => state.client.client,
    (state: RootState) => state.phases.workouts,
    (state: RootState) => state.workout.isTemplate,
  ],
  (weekPlans, client, workouts, isTemplate) => {
    const errors: { name: string }[] = [];

    for (const plan of weekPlans) {
      if (isWeekPlanCurrentWeek(plan.date) && !plan.published) {
        errors.push({ name: "Current week is unpublished" });
      }
    }

    let workoutHasError = false;

    for (const plan of weekPlans) {
      if (workoutHasError) {
        break;
      }

      if (!isWeekPlanInPast(plan.date) && plan.published) {
        const workoutHabitWeek = plan.habit_weeks.find((habitWeek) =>
          isProgramHabit(habitWeek),
        );

        if (workoutHabitWeek?.anchored_workout_days) {
          workoutHabitWeek.anchored_workout_days.forEach((day) => {
            if (day) {
              day.forEach((workoutId) => {
                const workout = workouts.find(
                  (workout) => workout.workout_id === workoutId,
                );

                if (workout) {
                  const workoutValidity = workoutLib.workouts.isValidWorkout(
                    workout,
                    isTemplate,
                    client?.equipment_detailed,
                    client?.blacklisted_exercises,
                    client?.preferred_weight_system ?? "imperial",
                  );

                  if (workoutValidity?.status === "error") {
                    workoutHasError = true;
                  }
                }
              });
            }
          });
        }
      }
    }

    if (workoutHasError) {
      errors.push({ name: "Workout has error" });
    }

    return errors;
  },
);

export const selectIsClientOnboarded = (state: RootState) => {
  return Boolean(state.client.client?.account.dashboard.date_onboarded);
};

export const selectIsClientInCPT2 = (state: RootState) => {
  return Boolean(
    state.client.client?.tests?.includes(
      "call_missed_workout_clients_treatment_2",
    ),
  );
};

export const selectPreferredWorkoutDays = createSelector(
  [(state: RootState) => state.client.client],
  (client) =>
    client?.workout_times?.map((time) => Boolean(time.has_time)) ?? [
      false,
      false,
      false,
      false,
      false,
      false,
      false,
    ],
);

export const selectWorkoutUsedCount = createSelector(
  [selectAllWeekPlans, (state: RootState, workoutId: string) => workoutId],
  (weekPlans, workoutId) => countWorkoutsInWeekPlans(workoutId, weekPlans),
);

export const selectClientsWorkoutValidity = createSelector(
  [
    (state: RootState) => state.client.client,
    (state: RootState) => state.workout.isTemplate,
    (state: RootState, workoutId: string) =>
      selectWorkoutById(state, workoutId),
  ],
  (client, isTemplate, workout) => {
    if (!workout) {
      return null;
    }

    return workoutLib.workouts.isValidWorkout(
      workout,
      isTemplate,
      client?.equipment_detailed,
      client?.blacklisted_exercises,
      client?.preferred_weight_system ?? "imperial",
    );
  },
);

export const selectBestSet = createSelector(
  [
    (state: RootState) => state.client.client?.best_sets,
    (state: RootState, userId: string) => userId,
    (state: RootState, userId: string, exerciseMasterId: string) =>
      exerciseMasterId,
  ],
  (bestSets, userId, exerciseMasterId) => {
    if (!bestSets || bestSets[exerciseMasterId]?.user_id !== userId) {
      return undefined;
    }

    return bestSets[exerciseMasterId];
  },
);

export const selectLatestSet = createSelector(
  [
    (state: RootState) => state.client.client?.latest_sets,
    (state: RootState, userId: string) => userId,
    (state: RootState, userId: string, exerciseMasterId: string) =>
      exerciseMasterId,
  ],
  (latestSets, userId, exerciseMasterId) => {
    if (!latestSets || latestSets[exerciseMasterId]?.user_id !== userId) {
      return undefined;
    }

    return latestSets[exerciseMasterId];
  },
);

import type { TextFieldProps } from "@mui/material";
import { TextField } from "@mui/material";
import { useEffect, useRef, useState } from "react";

type Props = {
  onSave: (newValue: string) => void;
  onKeyDownEnter?: () => void;
  characterLimit: number;
  defaultCursorPosition?: "start" | "end";
} & TextFieldProps;

export default function TextFieldWithLimit({
  onSave,
  onKeyDownEnter,
  characterLimit,
  defaultCursorPosition,
  ...textFieldProps
}: Props) {
  const inputRef = useRef<HTMLInputElement>(null);
  const [newValue, setNewValue] = useState(
    (textFieldProps.defaultValue as string) ?? "",
  );
  const newValueRef = useRef(newValue);

  function save() {
    onSave(newValueRef.current);
  }

  // useEffect(() => {
  //   return () => save();
  // }, []);

  useEffect(() => {
    if (textFieldProps.value) {
      setNewValue(
        (textFieldProps.value as string).substring(0, characterLimit),
      );
    }
  }, [textFieldProps.value]);

  useEffect(() => {
    newValueRef.current = newValue;
  }, [newValue]);

  return (
    <TextField
      {...textFieldProps}
      inputRef={inputRef}
      defaultValue={undefined}
      value={newValue}
      onChange={(event) => {
        setNewValue(event.target.value.substring(0, characterLimit));
      }}
      onBlur={(e) => {
        save();
      }}
      onKeyDown={(event) => {
        if (event.key === "Enter") {
          event.preventDefault();
          (event.target as HTMLInputElement).blur();
          onKeyDownEnter?.();
        }
      }}
      helperText={`${newValue.length} / ${characterLimit}${
        textFieldProps.helperText ? `. ${textFieldProps.helperText}` : ""
      }`}
      onFocus={() => {
        if (defaultCursorPosition === "end") {
          setTimeout(() => {
            if (inputRef.current) {
              inputRef.current.setSelectionRange(
                newValue.length,
                newValue.length,
              );
            }
          }, 5);
        }
      }}
    />
  );
}

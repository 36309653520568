import {
  attachClosestEdge,
  extractClosestEdge,
  type Edge,
} from "@atlaskit/pragmatic-drag-and-drop-hitbox/closest-edge";
import { combine } from "@atlaskit/pragmatic-drag-and-drop/combine";
import {
  draggable,
  dropTargetForElements,
} from "@atlaskit/pragmatic-drag-and-drop/element/adapter";
import { pointerOutsideOfPreview } from "@atlaskit/pragmatic-drag-and-drop/element/pointer-outside-of-preview";
import { setCustomNativeDragPreview } from "@atlaskit/pragmatic-drag-and-drop/element/set-custom-native-drag-preview";
import AddRoundedIcon from "@mui/icons-material/AddRounded";
import DragIndicatorRoundedIcon from "@mui/icons-material/DragIndicatorRounded";
import RemoveCircleRoundedIcon from "@mui/icons-material/RemoveCircleRounded";
import {
  Box,
  Card,
  IconButton,
  Tooltip,
  Typography,
  alpha,
} from "@mui/material";
import { useEffect, useRef, useState } from "react";
import { createPortal } from "react-dom";
import { DragPreview } from "src/components/common/DragPreview";
import { DropIndicator } from "src/components/common/DropIndicator";
import type { DayDraggable } from "src/slices/phaseTemplatesSlice";
import { WorkoutTemplateDraggable } from "./WorkoutTemplateDraggable";

type Props = {
  dayIndex: number;
  day: DayDraggable;
  newDay?: boolean;
  onDeleteWorkoutTemplate: (workoutTemplateId: string) => void;
  onCopyWorkoutTemplate: (workoutTemplateId: string) => void;
  onDelete: () => void;
  disabled?: boolean;
};

export default function PhaseDayDraggable({
  dayIndex,
  day,
  onCopyWorkoutTemplate,
  onDeleteWorkoutTemplate,
  disabled,
  onDelete,
}: Props) {
  const ref = useRef(null);
  const [dragging, setDragging] = useState<boolean>(false);
  const [isDraggedOver, setIsDraggedOver] = useState(false);
  const [closestEdge, setClosestEdge] = useState<Edge | null>(null);
  const [previewContainer, setPreviewContainer] = useState<HTMLElement | null>(
    null,
  );
  const dragHandleRef = useRef<HTMLButtonElement>(null);

  const dayLabel = `${
    ["A", "B", "C", "D", "E", "F", "G", "H", "I", "J", "K", "L", "M", "N"][
      dayIndex
    ]
  } day`;

  useEffect(() => {
    const element = ref.current;
    const dragHandle = dragHandleRef.current;

    if (!element || !dragHandle) {
      return;
    }

    const data = {
      type: "phase_template_day",
      index: dayIndex,
    };

    return combine(
      draggable({
        element: dragHandle,
        getInitialData: () => data,
        onDragStart: () => setDragging(true),
        onDrop: () => setDragging(false),
        onGenerateDragPreview({ nativeSetDragImage }) {
          setCustomNativeDragPreview({
            nativeSetDragImage,
            getOffset: pointerOutsideOfPreview({
              x: "16px",
              y: "8px",
            }),
            render({ container }) {
              setPreviewContainer(container);
            },
          });
        },
      }),
      dropTargetForElements({
        element,
        onDragEnter: ({ source }) => {
          if (source.data.type !== "phase_template_day") {
            setIsDraggedOver(true);
          }
        },
        canDrop({ source }) {
          return source.data.type === "phase_template_day";
        },
        getData({ input }) {
          return attachClosestEdge(data, {
            element,
            input,
            allowedEdges: ["top", "bottom"],
          });
        },
        onDrag({ self, source }) {
          const isSource =
            source.element === element || source.element === dragHandle;
          if (isSource) {
            setClosestEdge(null);
            return;
          }

          const closestEdge = extractClosestEdge(self.data);

          const sourceIndex = source.data.index;
          if (typeof sourceIndex !== "number") {
            return;
          }

          const isItemBeforeSource = dayIndex === sourceIndex - 1;
          const isItemAfterSource = dayIndex === sourceIndex + 1;

          const isDropIndicatorHidden =
            (isItemBeforeSource && closestEdge === "bottom") ||
            (isItemAfterSource && closestEdge === "top");

          if (isDropIndicatorHidden) {
            setClosestEdge(null);
            return;
          }

          setClosestEdge(closestEdge);
        },
        onDragLeave() {
          setClosestEdge(null);
          setIsDraggedOver(false);
        },
        onDrop() {
          setClosestEdge(null);
          setIsDraggedOver(false);
        },
      }),
    );
  }, [dayIndex]);

  return (
    <>
      <div
        style={{
          position: "relative",
        }}
      >
        <div
          ref={ref}
          style={{
            opacity: dragging ? 0.5 : 1,
          }}
        >
          <PhaseDay
            dayIndex={dayIndex}
            day={day}
            onDeleteWorkoutTemplate={onDeleteWorkoutTemplate}
            onCopyWorkoutTemplate={onCopyWorkoutTemplate}
            onDelete={onDelete}
            disabled={disabled}
            isOver={isDraggedOver}
            dragHandleRef={dragHandleRef}
          />
        </div>
        {closestEdge && <DropIndicator edge={closestEdge} gap="0px" />}
      </div>
      {previewContainer
        ? createPortal(<DragPreview text={dayLabel} />, previewContainer)
        : null}
    </>
  );
}

function PhaseDay({
  dayIndex,
  day,
  onDeleteWorkoutTemplate,
  onCopyWorkoutTemplate,
  onDelete,
  disabled,
  dragHandleRef,
  isOver,
}: Props & {
  isOver: boolean;
  dragHandleRef: React.RefObject<HTMLButtonElement | null>;
}) {
  return (
    <Box sx={{ py: 1 }}>
      <Box
        sx={{
          display: "flex",
          alignItems: "flex-end",
          justifyContent: "space-between",
          mb: 0.5,
        }}
      >
        <Box sx={{ display: "flex", alignItems: "center" }}>
          <IconButton
            size="small"
            ref={dragHandleRef}
            color="default"
            sx={{
              cursor: "grab",
            }}
          >
            <DragIndicatorRoundedIcon fontSize="inherit" />
          </IconButton>
          <Typography variant="body2">
            {
              [
                "A",
                "B",
                "C",
                "D",
                "E",
                "F",
                "G",
                "H",
                "I",
                "J",
                "K",
                "L",
                "M",
                "N",
              ][dayIndex]
            }{" "}
            day
          </Typography>
        </Box>
        {!disabled && (
          <Tooltip title={day.templates.length > 0 ? "Day must be empty" : ""}>
            <span>
              <IconButton
                size="small"
                onClick={() => {
                  onDelete();
                }}
                color="error"
                disabled={day.templates.length > 0}
              >
                <RemoveCircleRoundedIcon fontSize="inherit" />
              </IconButton>
            </span>
          </Tooltip>
        )}
      </Box>
      {!day.templates.length ? (
        <EmptyPhaseDay dayIndex={dayIndex} />
      ) : (
        <Card
          variant="outlined"
          sx={{
            flex: 1,
            borderStyle: day.templates.length > 0 ? "solid" : "dashed",
            borderWidth: day.templates.length > 0 ? "1px" : "2px",
            // borderRadius: 1,
            borderColor: (theme) =>
              isOver ? theme.palette.primary.main : undefined,
            backgroundColor: (theme) =>
              isOver
                ? alpha(theme.palette.primary.main, 0.2)
                : theme.palette.background.paper,
            transition: "background-color 0.2s ease, border-color 0.2s ease",
            width: "100%",
            overflow: "visible",
          }}
        >
          {day.templates.map((template, workoutTemplateIndex) => (
            <WorkoutTemplateDraggable
              key={template.draggable_id}
              workoutTemplateId={template.template_id}
              isLast={workoutTemplateIndex === day.templates.length - 1}
              isFirst={workoutTemplateIndex === 0}
              onDelete={() => {
                onDeleteWorkoutTemplate(template.template_id);
              }}
              onCopy={() => {
                onCopyWorkoutTemplate(template.template_id);
              }}
              disabled={disabled}
              dayIndex={dayIndex}
              index={workoutTemplateIndex}
            />
          ))}
        </Card>
      )}
    </Box>
  );
}

type EmptyPhaseDayProps = {
  dayIndex: number;
};

function EmptyPhaseDay({ dayIndex }: EmptyPhaseDayProps) {
  const ref = useRef(null);
  const [isDraggedOver, setIsDraggedOver] = useState(false);

  useEffect(() => {
    const el = ref.current;
    if (!el) {
      return;
    }

    return dropTargetForElements({
      element: el,
      getData: () => ({ type: "empty_phase_template_day", dayIndex }),
      onDragEnter: () => setIsDraggedOver(true),
      onDragLeave: () => setIsDraggedOver(false),
      onDrop: () => setIsDraggedOver(false),
      canDrop({ source }) {
        return (
          source.data.type === "workout_template" ||
          source.data.type === "phase_template_workout_template"
        );
      },
    });
  }, [dayIndex]);

  return (
    <div ref={ref}>
      <Box
        sx={{
          p: 1,
          display: "flex",
          borderStyle: "dashed",
          borderWidth: "2px",
          borderRadius: 1,
          borderColor: (theme) =>
            isDraggedOver ? theme.palette.primary.main : theme.palette.divider,
          backgroundColor: (theme) =>
            isDraggedOver
              ? alpha(theme.palette.primary.main, 0.2)
              : theme.palette.background.default,
          justifyContent: "center",
          flexDirection: "column",
          alignItems: "center",
          transitionProperty: "border-color, background-color",
          transitionTimingFunction: "cubic-bezier(0.15, 1.0, 0.3, 1.0)",
          transitionDuration: "350ms",
        }}
      >
        <AddRoundedIcon
          sx={{
            color: (theme) =>
              isDraggedOver
                ? theme.palette.primary.main
                : theme.palette.text.secondary,
            transitionProperty: "color",
            transitionTimingFunction: "cubic-bezier(0.15, 1.0, 0.3, 1.0)",
            transitionDuration: "350ms",
          }}
        />
        <Typography
          sx={{
            color: (theme) =>
              isDraggedOver
                ? theme.palette.primary.main
                : theme.palette.text.secondary,
            transitionProperty: "color",
            transitionTimingFunction: "cubic-bezier(0.15, 1.0, 0.3, 1.0)",
            transitionDuration: "350ms",
          }}
        >
          Drop templates here
        </Typography>
      </Box>
    </div>
  );
}

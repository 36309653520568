import type { PayloadAction } from "@reduxjs/toolkit";
import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import type { Client } from "@trainwell/features/legacy";
import set from "lodash-es/set";
import { api } from "src/lib/trainwellApi";

export const fetchClientEdit = createAsyncThunk(
  "clientEdit/fetchClientEdit",
  async (userId: string) => {
    const client = await api.clients.getOne(userId, true);

    return client;
  },
);

export const revokeFree = createAsyncThunk(
  "clientEdit/revokeFree",
  async (userId: string) => {
    const client = await api.clients.revokeFree(userId);

    return client;
  },
);

export const scheduleToDelete = createAsyncThunk(
  "clientEdit/scheduleToDelete",
  async (userId: string) => {
    const res = await api.clients.scheduleToDelete(userId);

    return res;
  },
);

// Define a type for the slice state
interface ClientEditState {
  client: Client | undefined;
  status: "idle" | "loading" | "succeeded" | "failed";
  error: string | undefined;
}

// Define the initial state using that type
const initialState: ClientEditState = {
  client: undefined,
  status: "idle",
  error: undefined,
};

export const clientEditSlice = createSlice({
  name: "clientEdit",
  initialState,
  reducers: {
    resetClientEdit: (state) => initialState,
    setClientEdit: (state, action: PayloadAction<Client>) => {
      const client = action.payload;

      state.client = client;
    },
    updateClientEditLocal: (state, action: PayloadAction<Partial<Client>>) => {
      const update = action.payload;

      if (state.client && state.client.user_id === update.user_id) {
        for (const [key, value] of Object.entries(update)) {
          set(state.client, key, value);
        }
      }
    },
  },
  extraReducers: (builder) => {
    builder.addCase(fetchClientEdit.pending, (state) => {
      state.status = "loading";

      Object.assign(state, initialState);
    });
    builder.addCase(fetchClientEdit.fulfilled, (state, action) => {
      console.log("Redux: Got client to edit");
      state.status = "succeeded";

      const client = action.payload;

      state.client = client;
    });
    builder.addCase(fetchClientEdit.rejected, (state, action) => {
      state.status = "failed";
      state.error = action.error.message;
    });
    builder.addCase(revokeFree.fulfilled, (state, action) => {
      console.log("Redux: revoke free");

      const client = action.payload;

      state.client = client;
    });
    builder.addCase(scheduleToDelete.fulfilled, (state, action) => {
      console.log("Redux: schedule to delete");

      state.client!.account.membership.date_requested_deletion =
        new Date().toISOString();
    });
  },
});

// Action creators are generated for each case reducer function
export const { resetClientEdit, setClientEdit, updateClientEditLocal } =
  clientEditSlice.actions;

export default clientEditSlice.reducer;

import { Box, Tooltip, Typography } from "@mui/material";
import type { NotificationMessage as NotificationMessageType } from "@trainwell/features/legacy";
import { format } from "date-fns";
import { getNotificationTitle } from "src/lib/message";

type Props = {
  message: NotificationMessageType;
};

export default function NotificationMessage({ message }: Props) {
  const dateString = format(
    new Date(message.send_date),
    "E, MMM d yyyy, h:mm a",
  );

  let readDateString = "Unread";
  if (message.read_date) {
    readDateString = format(
      new Date(message.read_date),
      "E, MMM d yyyy, h:mm a",
    );
  }

  if (
    message.notification_type === "progress_metric_logged" ||
    message.notification_type === "progress_metric_logged_many"
  ) {
    return null;
  }

  return (
    <Box
      sx={{
        display: "flex",
        alignItems: "center",
        flexDirection: "column",
        py: 1,
        width: "100%",
      }}
    >
      <Tooltip
        title={
          <>
            {dateString}
            <Typography sx={{ fontSize: 10 }}>
              Read: {readDateString}
            </Typography>
          </>
        }
        disableInteractive
        placement={"left"}
      >
        <Box>
          <Typography
            variant="h3"
            sx={{
              whiteSpace: "pre-line",
              wordWrap: "break-word",
              textAlign: "center",
              color: (theme) => theme.palette.text.primary,
            }}
          >
            {message.notification_title ||
              getNotificationTitle(message.notification_type)}
          </Typography>
          {message.text && (
            <Typography
              sx={{
                whiteSpace: "pre-line",
                wordWrap: "break-word",
                fontSize: 14,
                textAlign: "center",
                color: (theme) => theme.palette.text.primary,
              }}
            >
              {message.text}
            </Typography>
          )}
        </Box>
      </Tooltip>
    </Box>
  );
}

import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";
import type { SetHistory } from "@trainwell/features/legacy";
import { getAuth } from "firebase/auth";
import { TRAINWELL_API_DOMAIN, TRAINWELL_API_KEY } from "src/config/config";

export const setHistoryApi = createApi({
  reducerPath: "setHistoryApi",
  baseQuery: fetchBaseQuery({
    baseUrl: TRAINWELL_API_DOMAIN,
    prepareHeaders: async (headers) => {
      const auth = getAuth();

      const tokenString = await auth.currentUser?.getIdToken();

      if (tokenString) {
        headers.set("authorization", `Bearer ${tokenString}`);
      }

      headers.set("api-key", TRAINWELL_API_KEY);

      return headers;
    },
  }),
  endpoints: (build) => ({
    getSetHistory: build.query<
      { date: string; sets: SetHistory[] }[],
      { userId: string; exerciseId: string }
    >({
      query: (options) =>
        `/setHistories?exercise_master_id=${encodeURIComponent(
          options.exerciseId,
        )}&user_id=${encodeURIComponent(options.userId)}&max_workouts=3`,
    }),
  }),
});

export const { useGetSetHistoryQuery } = setHistoryApi;

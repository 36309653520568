import ArrowDropDownRoundedIcon from "@mui/icons-material/ArrowDropDownRounded";
import ArrowDropUpRoundedIcon from "@mui/icons-material/ArrowDropUpRounded";
import ShowChartRoundedIcon from "@mui/icons-material/ShowChartRounded";
import {
  Box,
  Card,
  Chip,
  Popover,
  ToggleButton,
  ToggleButtonGroup,
  Typography,
  useTheme,
} from "@mui/material";
import { LineChart } from "@mui/x-charts";
import type { CoachMetricTemplateSubmodule } from "@trainwell/features/legacy";
import { addDays, format, subDays } from "date-fns";
import React, { useState } from "react";
import type Metric from "src/interfaces/Metric";
import { getDateFromDatabase } from "src/lib/date";
import {
  calculateNetChange,
  formatMetrics,
  frames,
  queryMetric,
} from "src/lib/metrics";
import { round } from "src/lib/misc";
import { ClientBucketsGraph } from "../ClientBucketsGraph";

type Props = {
  allMetrics: Metric[];
  allCompanyMetrics: Metric[];
  submodule: CoachMetricTemplateSubmodule;
  window: number;
  trainerName: string;
};

export function LineChartMetric({
  allMetrics,
  allCompanyMetrics,
  submodule,
  window,
  trainerName,
}: Props) {
  const theme = useTheme();
  const [anchorEl, setAnchorEl] = useState<HTMLDivElement | null>(null);
  const [frame, setFrame] = useState(frames[0]);
  const [focusedMetric, setFocusedMetric] = useState<Metric | null>(null);

  const open = Boolean(anchorEl);

  const trainerMetrics = allMetrics.map((obj: any) => {
    let metric = queryMetric(obj, submodule.metric, true);
    metric = submodule.per_day ? metric / window : metric;
    metric = round(metric, submodule.precision);
    return {
      date: obj.date,
      metric: metric,
      sample: submodule.sample_metric
        ? queryMetric(obj, submodule.sample_metric)
        : null,
    };
  });

  const companyTrendMetrics = allCompanyMetrics.map((obj: any) => {
    let metric = queryMetric(obj, submodule.metric, true);
    metric = submodule.per_day ? metric / window : metric;
    metric = round(metric, submodule.precision);
    return {
      date: obj.date,
      metric: metric,
      sample: submodule.sample_metric
        ? queryMetric(obj, submodule.sample_metric)
        : null,
    };
  });

  const filteredTrainerMetrics = formatMetrics(trainerMetrics, frame);
  const filteredCompanyMetrics = formatMetrics(companyTrendMetrics, frame);

  // Create maps of dates to metrics
  const trainerMetricsMap = new Map(
    filteredTrainerMetrics.map((metric) => [metric.date, metric]),
  );
  const companyMetricsMap = new Map(
    filteredCompanyMetrics.map((metric) => [metric.date, metric]),
  );

  // Create a set of all unique dates
  const allDates = new Set([
    ...filteredTrainerMetrics.map((metric) => metric.date),
    ...filteredCompanyMetrics.map((metric) => metric.date),
  ]);

  // Combine the two arrays into a single array of metrics
  const chartData: {
    date: Date;
    trainerMetric: number | null;
    trainerSample: number | null;
    companyMetric: number | null;
    companySample: number | null;
  }[] = Array.from(allDates)
    .map((date) => ({
      date: addDays(date, 6),
      trainerMetric: trainerMetricsMap.get(date)?.metric || null,
      trainerSample: trainerMetricsMap.get(date)?.sample || null,
      companyMetric: companyMetricsMap.get(date)?.metric || null,
      companySample: companyMetricsMap.get(date)?.sample || null,
    }))
    .sort((a, b) => a.date.getTime() - b.date.getTime());

  const trainerMetricsMin = Math.min(
    ...filteredTrainerMetrics.map((m) => m.metric),
  );
  const companyMetricsMin = Math.min(
    ...filteredCompanyMetrics.map((m) => m.metric),
  );

  const { difference, differencePercent } = calculateNetChange(
    filteredTrainerMetrics,
  );

  const displayMetric = (metrics: Metric[]) => {
    let metric;
    if (metrics.length === 0) {
      metric = 0;
    } else {
      metric = round(metrics[metrics.length - 1].metric, 1);
    }

    return (
      <React.Fragment>
        {submodule.is_percent ? (
          <Typography sx={{ fontWeight: "bold", fontSize: 18 }}>
            {metric}%
          </Typography>
        ) : (
          <Typography sx={{ fontWeight: "bold", fontSize: 18 }}>
            {metric}
          </Typography>
        )}
      </React.Fragment>
    );
  };

  let trend: "neutral" | "bad" | "good" = "neutral";
  let chartColor = theme.palette.text.secondary;

  if (submodule.rank_low_to_high === null || difference === 0) {
    chartColor = theme.palette.text.secondary;
    trend = "neutral";
  } else if (
    (difference > 0 && submodule.rank_low_to_high) ||
    (difference < 0 && !submodule.rank_low_to_high)
  ) {
    chartColor = theme.palette.success.main;
    trend = "good";
  } else if (
    (difference < 0 && submodule.rank_low_to_high) ||
    (difference > 0 && !submodule.rank_low_to_high)
  ) {
    chartColor = theme.palette.error.main;
    trend = "bad";
  }

  return (
    <Box
      sx={{
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        flexGrow: 1,
        margin: "0 2px 0 2px",
        borderRadius: "4px",
        color: (theme) =>
          trend === "bad"
            ? theme.palette.error.contrastText
            : trend === "good"
              ? theme.palette.success.contrastText
              : undefined,
        backgroundColor: (theme) =>
          trend === "bad"
            ? theme.palette.error.light
            : trend === "good"
              ? theme.palette.success.light
              : theme.palette.backgroundSecondary.main,
      }}
      aria-haspopup="true"
      onMouseEnter={(event) => {
        setFrame("Past Month");
        setAnchorEl(event.currentTarget);
      }}
      onMouseLeave={() => {
        setAnchorEl(null);
      }}
    >
      <ShowChartRoundedIcon style={{ fontSize: "18px" }} />
      <Popover
        open={open}
        anchorEl={anchorEl}
        sx={{
          pointerEvents: "none",
        }}
        onClose={() => {
          setAnchorEl(null);
        }}
        disableRestoreFocus
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "left",
        }}
        transformOrigin={{
          vertical: "top",
          horizontal: "left",
        }}
        slotProps={{
          paper: {
            sx: {
              pointerEvents: "auto",
              p: 2,
            },
          },
        }}
      >
        <Box
          sx={{
            display: "flex",
            alignItems: "flex-start",
            justifyContent: "space-between",
          }}
        >
          <Box>
            <Typography
              variant="h3"
              sx={{
                mb: 1,
              }}
            >
              {trainerName}: {submodule.metric_title}
            </Typography>
            <Box
              sx={{
                display: "flex",
                alignItems: "center",
              }}
            >
              {displayMetric(filteredTrainerMetrics)}
              <Chip
                size="small"
                color={
                  trend === "bad"
                    ? "errorSurface"
                    : trend === "good"
                      ? "successSurface"
                      : undefined
                }
                label={
                  submodule.is_percent
                    ? Math.abs(difference) + "%"
                    : Math.abs(difference) + " (" + differencePercent + "%)"
                }
                icon={
                  difference > 0 ? (
                    <ArrowDropUpRoundedIcon />
                  ) : difference < 0 ? (
                    <ArrowDropDownRoundedIcon />
                  ) : undefined
                }
                sx={{ ml: 1 }}
              />
            </Box>
          </Box>
          <ToggleButtonGroup
            color="primary"
            value={frame}
            exclusive
            onChange={(event, value) => {
              setFrame(value);
            }}
            size="small"
            sx={{ ml: 2 }}
          >
            <ToggleButton sx={{ py: 0.25 }} value="Past Week">
              Past week
            </ToggleButton>
            <ToggleButton sx={{ py: 0.25 }} value="Past Month">
              Past month
            </ToggleButton>
            <ToggleButton sx={{ py: 0.25 }} value="All Time">
              All time
            </ToggleButton>
          </ToggleButtonGroup>
        </Box>
        <LineChart
          colors={[theme.palette.text.disabled, chartColor]}
          onAxisClick={(event, params) => {
            if (!submodule.client_buckets) {
              return;
            }

            const dataIndex = params?.dataIndex as number;

            const metricDate = subDays(
              chartData[dataIndex].date,
              6,
            ).toISOString();

            const metric = allMetrics.find((m) => m.date === metricDate);

            if (metric) {
              setFocusedMetric(metric);
            }
          }}
          xAxis={[
            {
              scaleType: "utc",
              dataKey: "date",
              // @ts-expect-error
              valueFormatter: (value, { location }) =>
                location === "tooltip"
                  ? format(getDateFromDatabase(value), "MMMM do, yyyy")
                  : undefined,
            },
          ]}
          yAxis={[
            {
              min:
                trainerMetricsMin > companyMetricsMin
                  ? companyMetricsMin
                  : trainerMetricsMin,
              valueFormatter: (value) => {
                if (submodule.is_percent) {
                  return value + "%";
                } else {
                  return value.toString();
                }
              },
            },
          ]}
          series={[
            {
              dataKey: "companyMetric",
              showMark: false,
              label: "trainwell",
              id: "company",
              curve: "linear",
              area: true,
              valueFormatter: (value, { dataIndex }) => {
                const data = chartData[dataIndex];

                let valueString = (value ?? "").toString();

                if (submodule.is_percent) {
                  valueString = valueString + "%";
                }

                if (data.trainerSample !== null) {
                  valueString += ` (sample: ${data.companySample})`;
                }

                return valueString;
              },
            },
            {
              dataKey: "trainerMetric",
              label: trainerName === "tw" ? "trainwell" : trainerName,
              showMark: false,
              curve: "linear",
              id: "trainer",
              area: true,
              valueFormatter: (value, { dataIndex }) => {
                const data = chartData[dataIndex];

                let valueString = (value ?? "").toString();

                if (submodule.is_percent) {
                  valueString = valueString + "%";
                }

                if (data.trainerSample !== null) {
                  valueString += ` (sample: ${data.trainerSample})`;
                }

                return valueString;
              },
            },
          ]}
          dataset={chartData}
          grid={{ horizontal: true }}
          height={300}
          width={500}
          slotProps={{
            legend: {
              hidden: true,
            },
          }}
          skipAnimation
          sx={{
            "& .MuiAreaElement-series-company": {
              fill: "url('#company')",
            },
            "& .MuiAreaElement-series-trainer": {
              fill: "url('#trainer')",
            },
          }}
        >
          <AreaGradient color={theme.palette.text.disabled} id="company" />
          <AreaGradient color={chartColor} id="trainer" />
        </LineChart>
        {focusedMetric && (
          <Card variant="outlined" sx={{ p: 1 }}>
            <Typography sx={{ fontWeight: "bold" }}>
              {format(
                getDateFromDatabase(addDays(focusedMetric.date, 6)),
                "MMMM do, yyyy",
              )}
            </Typography>
            <ClientBucketsGraph
              submodule={submodule}
              metricSnapshot={focusedMetric}
              height={200}
            />
          </Card>
        )}
      </Popover>
    </Box>
  );
}

function AreaGradient({ color, id }: { color: string; id: string }) {
  return (
    <defs>
      <linearGradient id={id} x1="50%" y1="0%" x2="50%" y2="100%">
        <stop offset="0%" stopColor={color} stopOpacity={0.5} />
        <stop offset="100%" stopColor={color} stopOpacity={0} />
      </linearGradient>
    </defs>
  );
}
